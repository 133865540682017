export default {
  publicHealthcareCardNumber: "Public healthcare card number",
  publicHealthcareCardValidBy: "Public health care card valid date",
  euSocialSecurityNumber: "EU social security number",
  name: "Name",
  fullName: "Full name",
  ssn: "Social security number",
  email: "E-mail",
  emailAddress: "E-mail address",
  password: "Password",
  passwordAgain: "Password confirmation",
  registrationCode: "Registration code",
};
