import BaseClinSys from "./BaseClinSys";

import Form from "../Modules/Form";

export default {
  install(Vue) {
    //Module use
    Vue.use(Form);

    //Base use
    Vue.use(BaseClinSys);
  },
};
