<template>
  <div>
    <h2>Felhasználók listája</h2>
    <router-link to="NewUser">
      <b-button>Új felhasználó létrehozás</b-button>
    </router-link>
    <b-row>
      <fp-input
        style="margin: 0px 5px"
        label="Szűrő"
        @change="filterUserList"
        v-model="filter"
      />
      <fp-select
        style="margin: 0px 5px"
        label="Prioritás"
        :items="Object.values(roleList)"
        valueKey="Value"
        @change="filterUserList"
        v-model="roleFilter"
      >
        <template #element="element">
          {{ $t(element.Text) }}
        </template>
      </fp-select>
    </b-row>
    <fp-table-list
      :hasPagination="true"
      :items="filteredUserList"
      :fields="userListFields"
      sortBy="UserId"
    >
      <template #ListFilterElement="element">
        {{ $t(element.Text) }}
      </template>
      <template #Roles="row">
        {{ convertRole(row.item.Roles) }}
      </template>
      <template #Buttons="row">
        <b-icon
          class="h3"
          style="cursor: pointer"
          icon="trash"
          @click="deleteUser(row.item.UserId)"
        ></b-icon>
      </template>
      <template #RegistrationDate="row">
        {{
          $convertDateToString(row.item.RegistrationDate, "YYYY-MM-DD HH:mm")
        }}
      </template>
    </fp-table-list>
  </div>
</template>
<script>
import { UserLogic } from "@/logic/backend/user";
export default {
  name: "UserManager",
  data() {
    return {
      filteredUserList: [],
      userList: [],
      userListFields: [
        { key: "UserId", label: "Felhasználó azonosító" },
        { key: "Username", label: "Felhasználónév" },
        { key: "Roles", label: "Szerepkör" },
        { key: "RegistrationDate", label: "Regisztráció időpontja" },
        { key: "Buttons", label: "" },
      ],
      roleList: [],
      filter: null,
      roleFilter: null,
    };
  },
  methods: {
    filterUserList() {
      this.filteredUserList = this.userList;
      if (this.filter) {
        this.filteredUserList = this.$filterList(this.filter, this.userList, [
          "Username",
          "UserId",
        ]);
      }
      if (this.roleFilter) {
        this.filteredUserList = this.filteredUserList.filter((role) => {
          return role.Roles.includes(this.roleFilter);
        });
      }
    },
    async getUserList() {
      const getUserListResponse = await UserLogic.getUser();
      if (!getUserListResponse.Code) {
        this.userList = this.filteredUserList = getUserListResponse;
      }
    },
    async deleteUser(userId) {
      const deleteUserResponse = await UserLogic.deleteUser(userId);
      if (!deleteUserResponse.Code || deleteUserResponse.Code == 0) {
        //siker
        alert("Siker");
        await this.getUserList();
      }
    },
    convertRole(roles) {
      const tempRoles = [];
      roles.forEach((role) => {
        const temp = this.roleList[role];
        if (temp) {
          tempRoles.push(this.$t(temp.Text));
        } else {
          tempRoles.push(temp);
        }
      });
      return tempRoles.toString();
    },
  },
  beforeMount() {
    this.roleList = this.$enums.UserRole;
  },
  async mounted() {
    this.$store.dispatch("setPageTitle", "Felhasználó kezelés");
    await this.getUserList();
  },
};
</script>