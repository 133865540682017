<!-- Version: 1.2.0 -->
<template>
  <div class="eh-header">
    <!-- Header Line -->
    <div>
      <fp-header-title-line :hasLangSwitch="hasLangSwitch" />
    </div>
    <!-- Header Content -->
    <div>
      <!-- Header Logo - Profile -->
      <b-container fluid class="eh-web-app-header-bottom">
        <b-row align-v="center">
          <!-- LOGO -->
          <b-col cols="12" lg="5">
            <div class="eh-web-app-header-logo" />
          </b-col>
          <!-- Bejelentkezett adatai -->
          <b-col cols="12" lg="7">
            <fp-header-logged-user
              :signedName="signedName"
              :signedProfilePicture="signedProfilePicture"
              :changedProfileFillingRate="changedProfileFillingRate"
              @forceUpdate="$emit('forceUpdate')"
            />
          </b-col>
        </b-row>
      </b-container>
      <!-- Header Menu -->
      <fp-header-menu
        :menuElements="menuElements"
        :projectEnabledUiElements="projectEnabledUiElements"
        :enabledMenuElements="enabledMenuElements"
      />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    signedName: String,
    signedProfilePicture: [Object, String],
    siteTitle: String,
    siteColor: String,
    changedProfileFillingRate: Object,
    menuElements: Array,
    projectEnabledUiElements: Object,
    hasLangSwitch: Boolean,
    enabledMenuElements: Array,
  },
};
</script>
