<template>
  <div>
    <div>
      <h2>Admin funkciók</h2>
    </div>
    <!-- További almenü gombok -->
    <b-row style="text-align: center">
      <div>
        <table class="programs-box">
          <!-- Jelvények almenüpont -->
          <router-link to="UserManager">
            <td class="program-box">
              <b-button class="eh-action-button">
                Felhasználó kezelés
              </b-button>
            </td>
          </router-link>
        </table>
      </div>
    </b-row>
  </div>
</template>
<script>
export default {
  data() {
    return {
      siteTitle: "Személyes főoldal",
    };
  },
  mounted() {
    this.$store.dispatch("setPageTitle", this.siteTitle);
  },
};
</script>
