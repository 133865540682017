export const HuHealthProfessional = {
  healthProfessional: {
    home: {
      dailyTask: "Napi teendők",
      progress: "Haladás",
      projectProgress: "Projekt haladás",
      taskDescription: "Leírás",
      taskPriority: "Prioritás",
      taskStatus: "Státusz",
      taskTitle: "Teendő",
      taskType: "Típus",
      timeProgress: "Projekt időbeli előrehaladása",
      doctorProgress: "Résztvevő előrehaladása a projektben",
    },
    menu: {
      appointments: "Időpontok",
      calendar: "Naptár",
      documents: "Dokumentumtár",
      home: "Személyes főoldal",
      messages: "Üzenetek",
      patientList: "Beteglista",
      patientInvitation: "Beteg meghívás",
      project: "Projekt",
      questionnaires: "Kérdőívtár",
      tasks: "Teendők",
      workflow: "Protokolltár",
      templates: "Sablontár",
      profile: "Profilom",
    },
    patientList: {
      communicationTab: {
        call: "Hívás",
        chat: "Chat",
        email: "Email",
        emailNew: "Új email",
        emailSubject: "Tárgy",
        emailBody: "Email törzse",
        emailPriority: "Prioritás",
        emailAttachment: "Csatolmány",
        smsNew: "Új SMS",
        smsText: "SMS szövege",
        pushNew: "Új Push",
        pushTitle: "Értesítés címe",
        pushText: "Értesítés szövege",
        pushPhoto: "Értesítés képe",
        push: "Push",
        sms: "SMS",
        communication: "Kommunikáció",
        openButton: "Megjelenít",
        operations: "Műveletek",
        type: "Típus",
        sendTime: "Küldés időpontja",
        communicationTypes: "Kommunikációs csatornák",
        sendMessages: "Üzenet küldés",
        templateName: "Megnevezés",
        templateSupportedLanguages: "Elérhető nyelvek",
        templateMessageTypes: "Elérhető sablonok",
        send: "Elküldés",
      },
      diagramsTab: {
        selectChart: "Válasszon diagram típust",
      },
      formsTab: {
        assignment: "Hozzárendelés",
        description: "Leírás",
        endDate: "Kitöltés időpontja",
        language: "Nyelv",
        newForm: "Új űrlap felvétele",
        operations: "Műveletek",
        state: "Űrlap állapota",
        title: "Űrlap címe",
        errorFormName: "űrlap",
        errorAssignName: "kiosztható űrlapok",
        filledDate: "Kitöltés dátuma",
      },
      newPatientForm: {
        patient: "Beteg",
        note: "Megjegyzések",
        projectRole: "Projekt szerepkör",
        tags: "Címkék",
        errorName: "felhasználó",
      },
      patientList: {
        addNewPatientToProject: "Beteg hozzáadása a projekthez",
        notBirthday: "Születési dátum nincs megadva",
        notFoundPatient: "Nincs megjelenítendő beteg",
        tagFilter: "Címke szűrő",
        errorTagsName: "címkék",
      },
      patientTabs: {
        charts: "Diagrammok",
        communication: "Kommunikáció",
        documents: "Dokumentumok",
        forms: "Orvosi űrlapok",
        medicalData: "Medikai profil",
        personalData: "Személyes profil",
        questionnaires: "Beteg kérdőívei",
        workflow: "Protokollok",
      },
      personalDataTab: {
        deleteFromProject: "Projektből törlés",
        editTag: "Címke szerkesztés",
        editProfile: "Profil szerkesztés",
        errorName: "személyes profil",
      },
      medicalDataTab: {
        editProfile: "Profil szerkesztés",
        errorName: "medikai profil",
      },
      questionnairesTab: {
        cancelButton: "Visszavonás",
        deadline: "Határidő",
        evaluatingButton: "Kiértékelés",
        fromDate: "Kiadás dátuma",
        giveAgain: "Ismételt kiadás",
        operations: "Műveletek",
        progress: "Kitöltöttség",
        questionnaire: "Kérdőív",
        state: "Kérdőív állapota",
        warningButton: "Figyelmeztetés",
        errorName: "kérdőív",
      },
      tag: {
        edit: "Módosítás",
        editTag: "Címkék módosítása",
        newTag: "Új címke létrehozás",
        noTags: "Nincs megjeleníthető címke",
        tagColor: "Címke színe",
        tagInfo: "Dupla kattintással szerkeszthető a meglévő címke",
        tagManager: "Címke kezelő",
        tagName: "Címke neve",
        tags: "Címkék",
        language: "Nyelv",
        project: "Projekt",
        own: "Saját",
      },
      workflowTab: {
        workflow: "Protokoll",
        progress: "Haladás",
        currentSteps: "Aktuálisan futó folyamat lépés(ek)",
        currentInProgress: "Lépés elvégzése folyamatban...",
        stepWaiting: "Folyamat várakozik",
        errorStartedName: "elindított folyamat",
      },
      userSetting: {
        relationshipType: "Kapcsolat típusa",
        relationshipRemark: "Kapcsolat megjegyzése",
        projectRemark: "Projekt megjegyzés",
      },
      documentsTab: {
        documentType: "Dokumentum típusa",
      },
    },
    patientManager: {
      patientSignUp: {
        accountData: "Fiók adatok",
        birthday: "Születési dátum",
        birthplace: "Születési hely",
        email: "Email cím",
        name: "Név",
        password: "Jelszó",
        passwordAgain: "Jelszó ismétlése",
        phone: "Telefonszám",
        role: "Szerepkör",
        sex: "Nem",
        userBaseData: "Felhasználó alap adatai",
        username: "Felhasználónév",
        flags: "Fiók tulajdonság",
        newPatientDoctorRelationship:
          "Új felhasználó beteg-orvos kapcsolat létrehozása",
        relationshipType: "Kapcsolat típusa",
        tags: "Címkék",
        relationshipRemark: "Kapcsolat megjegyzése",
        newPatientToProject: "Új felhasználó felvétele a projektbe",
        projectRole: "Projekt szerepkör",
        tagsInProject: "Projekten belüli címkék",
        projectRemark: "Projekt megjegyzése",
      },
      manager: {
        newPatientSignUp: "Új beteg beléptetése",
        userInvitation: "Felhasználó meghívása",
        patientInvitations: "Beteg meghívók",
        status: "Állapot",
        targetName: "Meghívott neve",
        parameters: "Paraméterek",
        timestamp: "Meghívó elküldése",
      },
      userInvitation: {
        registedUserInvitation: "Regisztrált felhasználó meghívása betegként",
        user: "Felhasználó",
        errorName: "felhasználó",
      },
    },
    profile: {
      antszCode: "ÁNTSZ kód",
      chat: "Chat",
      communication: "Kommunikáció",
      email: "E-mail",
      emailAddress: "E-mail cím",
      institutionName: "Intézmény név",
      name: "Név",
      phone: "Telefonszám",
      position: "Beosztás",
      push: "Push",
      registrationNumber: "Pecsétszám",
      sms: "SMS",
      username: "Felhasználónév",
      wardName: "Osztály név",
      profileDelete: "Felhasználói fiók törlése véglegesen",
      errorName: "profil",
    },
    projects: {
      blind: "Vizsgálat jellege",
      controllGroup: "Kontroll csoport",
      controllPatientCount: "Ebből kontrolbeteg",
      dataSheet: "Adatlap",
      description: "Leírás",
      dontProject: "Nincs projekthez csatlakozva",
      doubleBlind: "Duplavak teszt",
      noneBlind: "Nyilvános",
      patientIn: "Bevont betegek száma",
      patientName: "Beteg neve",
      patientSsn: "Beteg TAJ száma",
      patientBirth: "Beteg születési dátum",
      patients: "Betegek",
      progress: "Haladás",
      projectGoal: "Projektcél",
      projectName: "Projektnév",
      projectTitle: "Projektcím",
      selectionCriteria: "Beválasztási kritériumok",
      simpleBlind: "Egyszeres vak teszt",
      state: "Állapot",
      tasksProgress: "Feladat haladás",
      testGroup: "Tesztcsoport",
      testPatientCount: "Ebből tesztbeteg",
      timestamp: "Időtartam",
      projectTimeProgress: "Projekt időbeli haladása",
      doctorTimeProgress: "Orvos előre haladása a projektben",
      currentProject: "aktuális projekt",
      person: "fő",
    },
    questionnaires: {
      own: "Saját kérdőívek",
      ownTab: {
        title: "Kérdőív címe",
        description: "Leírása",
        percentage: "Kitöltöttség",
        addDate: "Hozzáadás dátuma",
        completedDate: "Kitöltés dátuma",
        status: "Állapota",
        errorFillableName: "kitölthető kérdőív",
      },
      assignable: "Kiosztható kérdőívek",
      assignableTab: {
        newdeadline: "Határidő",
        giveQuestionnaries:
          "{count} kiválasztott betegnek a kiválasztott kérdőív kiosztása",
        patientBirthday: "Beteg születési dátuma",
        patientName: "Beteg neve",
        noPatient: "Nincs megjeleníthető beteg",
        patientNote: "Megjegyzés",
        patientSsn: "Beteg TAJ száma",
        formCreatedDate: "Létrehozva",
        formDescription: "Leírás",
        formTitle: "Címe",
        newSendDate: "Kiküldés dátuma",
        newFormByTask: "Kérdőív kiosztása feladatként",
        newFormHardDeadline:
          "Határidő lejáratával lezárja a feladat teljesítést",
        noTag: "Címkézetlen",
        createDate: "Létrehozás dátuma",
        fillTime: "Kitöltésre szánt idő",
        newdeadlineUnit: "NAP.HH:MM:SS",
        supportedLanguages: "Elérhető nyelvek",
        errorName: "kioasztható kérdőív",
      },
      waitingForEvaluting: "Kiértékelésre váró kérdőívek",
      waitingTab: {
        deadline: "Határidő",
        description: "Leírás",
        evaluating: "Kiértékelés",
        filledDate: "Kitöltés dátuma",
        givenDate: "Kiosztás dátuma",
        patient: "Kitöltő beteg",
        patientBirth: "Beteg születési dátuma",
        patientSsn: "Beteg TAJ száma",
        progress: "Kitöltöttség",
        title: "Kérdőív címe",
        evaulate: "Értékelés",
        errorWaitingName: "kiértékelésre váró kérdőív",
        evaulating: {
          longDescription: "Értékelés hosszan",
          note: "Megjegyzés",
          point: " pont",
          shortDescription: "Értékelés röviden",
          summary: "Összpontszám: ",
          title: "Kérdőív kiértékelés",
        },
      },
      fillableForm: "Beteghez rendelt űrlapok",
      fillableFormTab: {
        patient: "Beteg adatok",
        title: "Cím",
        description: "Leírás",
        percentage: "Kitöltöttség",
        startDateTime: "Hozzáadás dátuma",
        stopDateTime: "Kitöltés dátuma",
        status: "Állapot",
        errorName: "kitölthető kérdőív",
      },
      questionnaireFill: "Kérdőív kitöltés",
      formFill: "Űrlap kitöltés",
      userData: "Hozzárendelt felhasználó",
      name: "Név",
      ssn: "TAJ szám",
      birthday: "Születési dátum",
      address: "Lakcím",
      errorPatientName: "beteg",
    },
    tasks: {
      deadline: "Határidő",
      description: "Leírás",
      priority: "Prioritás",
      startDate: "Kezdési dátum",
      status: "Státusz",
      task: "Teendő",
      type: "Típus",
      errorUserName: "felhasználó",
      errorStartableName: "elindítható folyamat",
    },
    workflow: {
      startWorkflow: "Folyamat indítás",
    },
    appointment: {
      filterDescription: "Keresés címben, leírásban, max. férőhelyek számában.",
      date: "Dátum",
      inProgress: "Folyamatban",
      person: "{count} fő",
      addParticipant: "Résztvevő hozzáadása",
      participants: "Résztvevők",
      clear: "Időpont szabaddá tétele",
      view: "Megjelenés",
      participantsData: "Rétvevő adatai",
      removeParticipant: "Rétvevő lejelentkeztetése",
      saveView: "Megjelenés mentése",
      newParticipnat: "Új résztvevő hozzáadása",
      users: "Felhasználók",
      title: "Cím",
      description: "Leírás",
      startTime: "Kezdési időpont",
      endTime: "Záró időpont",
      visitorsCount: "Férőhelyek száma",
      location: "Helyszín",
      errorName: "időpont",
      client: "ügyfél",
    },
    documents: {
      documentType: "Dokumentum típusa",
      errorName: "dokumentum",
    },
    templates: {
      errorName: "sablon",
      newTemplate: "Új sablon felvétele",
      noTemplate: "Nincs megjeleníthető sablon",
      lang: "Sablon nyelve",
      name: "Sablon megnevezése",
      addEmail: "Email sablon hozzáadása",
      addSMS: "SMS sablon hozzáadása",
      addPush: "PUSH sablon hozzáadása",
      email: "Email sablon",
      sms: "SMS sablon",
      push: "Push sablon",
      emailSubject: "Tárgy",
      removeEmail: "Email sablon eltávolítása",
      smsMessage: "Üzenet",
      removeSMS: "SMS sablon eltávolítása",
      pushTitle: "Cím",
      pushMessage: "Üzenet",
      pushImg: "Iconkép URL",
      removePush: "Push sablon eltávolítása",
      addToProject:'Sablon hozzáadva a projekthez',
    },
  },
};
