export const DeAdmin = {
  admin: {
    userManager: {
      accountData: "Kontoinformationen",
      username: "Nutzername",
      userRole: "Rolle",
      password: "Passwort",
      passwordAgain: "Passwort wiederholen",
      personName: "Name",
      email: "E-Mail-Addresse",
      flags: "Kontoeigenschaft",
      createNewUser: "Erstellen Sie einen neuen Benutzer",
    },
  },
};
