export const HuComponents = {
  components: {
    labor: {
      loinc: "LOINC kód",
      refMax: "Ref. tartomány. max.",
      refMin: "Ref. tartomány. min.",
    },
    selectOrWrite: "Válassza ki a listából vagy szabadkézzel írja be.",
    table: {
      closeAllGroup: "Összes csoport bezárása",
      eesztSync: "EESZT szinkronizáció",
      format: "Formátum",
      name: "Dokumentum neve",
      openAllGroup: "Öszes csoport kinyitása",
      selectAll: "Összes kijelölése",
      type: "Típus",
      unselectAll: "Kijelölés megszüntetése",
      uploadDate: "Feltöltés dátuma",
      uploadDoc: "Dokumentum feltöltés",
      selectFile: "Fájl kiválasztás",
      fileType: "Fájl típusa",
    },
    form: {
      oneDocumentUploaded: "{count} feltöltött dokumentum",
      noSelectedDocument: "Nincs kiválasztott dokumentum",
    },
    formEvaulating: {
      manualEvaluation: "Szöveges értékelés",
      short: "Rövid szöveges értékelés",
      long: "Hosszú szöveges értékelés",
      note: "Megjegyzés",
      autoResult: "Automatikus kiértékelés eredménye",
    },
    taskTable: {
      priority: "Prioritás",
      status: "Státusz",
      type: "Típus",
      startDate: "Kezdési dátum",
      deadline: "Határidő",
      task: "Feladat: {description}",
      taskCompleted: "Feladat teljesítve",
      taskUncompleted: "Feladat végrehajtásának visszavonása",
    },
    changePassword: {
      title: "Jelszó csere",
      old: "Régi jelszó",
      new: "Új jelszó",
      again: "Jelszó ismétlése",
    },
    header: {
      errorAccountName: "fiók",
    },
    dictate: {
      addNewDoc: "Új dokumentum hozzáadása",
      textGenerate: "Szöveg generálása",
      inProgress: "Folyamatban...",
      notSupported: "Ez a böngésző nem támogatja a hanglejátszást. :(",
      writedText: "Írott szöveg",
      writedTextDescription: "A szöveg szabadon szerkeszthető!",
      docName:
        "A létrehozandó szöveges dokumentum neve (kiterjesztés megadása nem szükséges)",
      fileName: "Fájlnév",
      saveFile: "Mentés fájlba",
      uploadPHR: "Feltöltés PHR dokumentumként",
      uploadOther: "Feltöltés egyéb dokumentumként",
      startRecord: "Felvétel indítása",
      stopRecord: "Felvétel leállítása",
    },
    templates: {
      errorName: "sablon",
      newTemplate: "Új sablon felvétele",
      noTemplate: "Nincs megjeleníthető sablon",
      lang: "Sablon nyelve",
      name: "Sablon megnevezése",
      addEmail: "Email sablon hozzáadása",
      addSMS: "SMS sablon hozzáadása",
      addPush: "PUSH sablon hozzáadása",
      email: "Email sablon",
      sms: "SMS sablon",
      push: "Push sablon",
      emailSubject: "Tárgy",
      removeEmail: "Email sablon eltávolítása",
      smsMessage: "Üzenet",
      removeSMS: "SMS sablon eltávolítása",
      pushTitle: "Cím",
      pushMessage: "Üzenet",
      pushImg: "Iconkép URL",
      removePush: "Push sablon eltávolítása",
      addToProject: "Sablon hozzáadva a projekthez",
    },
  },
};
