export const EnComponents = {
  components: {
    labor: {
      loinc: "LOINC code",
      refMax: "Ref. Range. max.",
      refMin: "Ref. Range. min.",
    },
    selectOrWrite: "Select from the list or enter freehand.",
    table: {
      closeAllGroup: "Close all groups",
      eesztSync: "EESZT synchronization",
      format: "Format",
      name: "Document name",
      openAllGroup: "Open all groups",
      selectAll: "Select all",
      type: "Type",
      unselectAll: "Deselect",
      uploadDate: "Upload date",
      uploadDoc: "Upload a document",
      selectFile: "File selection",
      fileType: "File type",
    },
    form: {
      oneDocumentUploaded: "{count} uploaded documents",
      noSelectedDocument: "No document selected",
    },
    formEvaulating: {
      manualEvaluation: "Textual evaluation",
      short: "Short text evaluation",
      long: "Long text evaluation",
      note: "Comment",
      autoResult: "Result of automatic evaluation",
    },
    taskTable: {
      priority: "Priority",
      status: "Status",
      type: "Type",
      startDate: "Beginning date",
      deadline: "Deadline",
      task: "Task: {description}",
      taskCompleted: "Task completed",
      taskUncompleted: "Undo a task",
    },
    changePassword: {
      title: "Password change",
      old: "Old Password",
      new: "New password",
      again: "Repeat password",
    },
    header: {
      errorAccountName: "account",
    },
    dictate: {
      addNewDoc: "Add a new document",
      textGenerate: "Generate text",
      inProgress: "In progress...",
      notSupported: "This browser does not support audio playback. :(",
      writedText: "Written text",
      writedTextDescription: "The text is free to edit!",
      docName:
        "Name of the text document to be created (no extension required)",
      fileName: "Filename",
      saveFile: "Save to file",
      uploadPHR: "Upload as PHR document",
      uploadOther: "Upload as other document",
      startRecord: "Start recording",
      stopRecord: "Stop recording",
    },
    templates: {
      errorName: "template",
      newTemplate: "Add a new template",
      noTemplate: "No template to display",
      lang: "Template language",
      name: "Name of the template",
      addEmail: "Add E-mail template",
      addSMS: "Add SMS template",
      addPush: "Add PUSH template",
      email: "Email template",
      sms: "SMS template",
      push: "Push template",
      emailSubject: "Subject",
      removeEmail: "Remove email template",
      smsMessage: "Message",
      removeSMS: "Remove SMS template",
      pushTitle: "Title",
      pushMessage: "Message",
      pushImg: "Icon image URL",
      removePush: "Remove Push template",
      addToProject: "Template added to project",
    },
  },
};
