<template>
  <div>
    <div
      @click="isOpen = !isOpen"
      class="eh-tag-manager-header"
      :class="isOpen ? 'eh-tag-manager-open' : ''"
    >
      {{ $t("partner.participantList.tag.tagManager")
      }}<b-icon
        class="eh-tag-manager-header-icon"
        :icon="isOpen ? 'chevron-down' : 'chevron-up'"
      ></b-icon>
    </div>
    <div v-if="isOpen" class="eh-tag-manager-content">
      <b-icon
        @click="showNewTagForm = true"
        scale="2"
        icon="plus"
        class="eh-tag-manager-content-plus"
      />
      <div>
        {{ $t("partner.participantList.tag.tags") }}:<b-icon
          v-b-tooltip.hover="$t('partner.participantList.tag.tagInfo')"
          scale="0.8"
          style="vertical-align: top"
          icon="info-circle"
        />
      </div>
      <div
        style="width: 100%"
        v-if="tagList && tagList.length && tagList.length > 0"
      >
        <div style="display: flex; flex-wrap: wrap">
          <div v-for="tag in tagList" :key="tag.TagId">
            <tag :tag="tag" @getList="$emit('getTagList')" :editable="true" />
          </div>
        </div>
      </div>
      <div v-else>{{ $t("partner.participantList.tag.noTags") }}</div>
    </div>
    <new-tag-form
      @getList="$emit('getTagList')"
      v-model="showNewTagForm"
      :projectId="projectId"
    />
  </div>
</template>
<script>
import NewTagForm from "./NewTagForm.vue";
import Tag from "./Tag.vue";
export default {
  components: { Tag, NewTagForm },
  name: "TagManager",
  data() {
    return {
      isOpen: false,
      showNewTagForm: false,
    };
  },
  props: {
    tagList: Array,
    projectId: Number,
  },
};
</script>
