export const HuFooter = {
  footer: {
    contact: "KAPCSOLAT",
    data: "ADATVÉDELEM",
    faq: "GYIK",
    impress: "IMPRESSZUM",
    legal: "JOGI NYILATKOZAT",
    createdBy: "készítette",
    poweredBy: "powered by",
  },
};
