<template>
  <div v-if="component">
    <component
      :is="component.Type"
      :hasTab="hasTab"
      :params="component"
      v-model="tempValue"
      :evaluateObjectModel="evaluateObjectModel"
      @evaluating="$emit('evaluating', $event)"
      :disabled="disabled"
      :scoreable="scoreable"
      :language="language"
    />
  </div>
</template>
<script>
import CheckBoxComponent from "./Components/CheckBoxComponent.vue";
import ImageComponent from "./Components/ImageComponent.vue";
import InputComponent from "./Components/InputComponent.vue";
import ListBoxComponent from "./Components/ListBoxComponent.vue";
import MultiplePanelComponent from "./Components/MultiplePanelComponent.vue";
import SimplePanelComponent from "./Components/SimplePanelComponent.vue";
import RadioButtonComponent from "./Components/RadioButtonComponent.vue";
import SliderComponent from "./Components/SliderComponent.vue";
import SwitchComponent from "./Components/SwitchComponent.vue";
import TableComponent from "./Components/TableComponent.vue";
import DateTimeComponent from "./Components/DateTimeComponent.vue";
import SectionComponent from "./Components/SectionComponent.vue";
import RootComponent from "./Components/RootComponent.vue";
import FileUploadComponent from "./Components/FileUploadComponent.vue";
import LabelComponent from "./Components/LabelComponent.vue";
import LaboratoryComponent from "./Components/LaboratoryComponent.vue";

export default {
  components: {
    InputComponent,
    ListBoxComponent,
    ImageComponent,
    RadioButtonComponent,
    CheckBoxComponent,
    SwitchComponent,
    SliderComponent,
    TableComponent,
    MultiplePanelComponent,
    SimplePanelComponent,
    DateTimeComponent,
    SectionComponent,
    RootComponent,
    FileUploadComponent,
    LabelComponent,
    LaboratoryComponent,
  },
  name: "FormSchemaInputs",
  model: {
    prop: "value",
    event: "change",
  },
  data() {
    return {
      tempValue: this.value,
      nullableValueComponents: [
        "InputComponent",
        "ListBoxComponent",
        "ImageComponent",
        "RadioButtonComponent",
        "CheckBoxComponent",
        "SwitchComponent",
        "SliderComponent",
        "TableComponent",
        "DateTimeComponent",
        "FileUploadComponent",
      ],
    };
  },
  props: {
    component: Object,
    value: { type: [Object, String, Number, Boolean, Array], default: null },
    disabled: Boolean,
    scoreable: Boolean,
    evaluateObjectModel: {
      type: [Object, String, Number, Boolean, Array],
      default: null,
    },
    hasTab: Boolean,
    language: String,
    /* fileUploads: Array */
  },
  watch: {
    tempValue(input) {
      this.$emit("change", input);
    },
  },
  methods: {
    setItemsSequence() {
      var temp = this.component.Items;
      if (
        this.component.DisplaySequence ==
        this.$enums.ListBoxComponentDisplaySequence.Random
      ) {
        temp = null;
        this.component.Items.forEach((x) => {
          x.OrderNumber = x.ItemId + Math.random() * 1000;
        });
        temp = this.component.Items.sort((a, b) =>
          a.OrderNumber > b.OrderNumber ? -1 : 1
        );
      }
      this.$set(this.component, "Items", temp);
    },
  },
  mounted() {
    if (
      this.component &&
      this.component.DisplaySequence &&
      this.component.DisplaySequence ==
        this.$enums.ListBoxComponentDisplaySequence.Random
    ) {
      this.setItemsSequence();
    }

    if (
      this.component &&
      this.nullableValueComponents.includes(this.component.Type)
    ) {
      if (this.tempValue) {
        this.$emit("change", this.tempValue);
      } else {
        this.$emit("change", null);
      }
    }
  },
};
</script>