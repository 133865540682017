<template>
  <fp-form-modal v-model="visible" title="Projekt kapcsolat felvétele">
    <template #content>
      <fp-select :items="userList" v-model="projectUser">
        <template #element="e">
          <div>Név: {{ e.Name ? e.Name : "Nincs jogosulsága lekérdezni" }}</div>
          <div>Felhasználó név: {{ e.Username }}</div>
        </template>
      </fp-select>
      <b-button @click="createRelation">Kapcsolat létrehozása</b-button>
    </template>
  </fp-form-modal>
</template>
<script>
import { ProjectLogic } from "../../Logic/Backend/project";
import { UserLogic } from "../../Logic/Backend/user";
export default {
  name: "CreateProjectRelation",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: Boolean,
    baseRole: String,
    project: Object,
  },
  data() {
    return {
      userList: null,
      visible: this.value,
      projectUser: null,
    };
  },
  watch: {
    async value(input) {
      this.visible = input;
      if (input) {
        await this.getUserList();
      }
    },
    visible(input) {
      this.$emit("change", input);
    },
  },
  methods: {
    getProjectRoles() {
      if (this.baseRole) {
        return this.project.Roles.filter((e) =>
          e.BaseRoles.includes(this.baseRole)
        );
      } else {
        return null;
      }
    },
    async getUserList() {
      const roles = this.getProjectRoles();
      var responses = null;
      if (roles) {
        console.log("roles", roles);
        responses = await Promise.all(
          roles.map(async (r) => {
            const getResponse = await UserLogic.getUserData({
              projectId: this.project.ProjectId,
              projectRole: r.RoleName,
            });
            if (!getResponse.Code) {
              return getResponse;
            } else {
              return null;
            }
          })
        );
        this.userList = responses.flat(2);
      } else {
        const getUserListResponse = await UserLogic.getUserData({
          projectId: this.project.ProjectId,
        });
        if (!getUserListResponse.Code) {
          this.userList = getUserListResponse;
        } else {
          this.$bvToast.toast(getUserListResponse.Message, {
            title: "Hiba történt a felhasználó lista betöltése során!",
            variant: "danger",
            solid: true,
            AutoHideDelay: 10000,
          });
        }
      }
      console.log(this.userList);
    },
    async createRelation() {
      const body = [
        {
          SuperiorId: this.$loggedUser.UserId,
          SubordinateId: this.projectUser.UserId,
        },
      ];
      const createResponse = await ProjectLogic.createRelationship(
        body,
        this.project.ProjectId
      );
      if (!createResponse.Code) {
        this.$emit("selectParticipant", this.projectUser);
        this.visible = false;
        this.projectUser = null;
        this.$bvToast.toast(
          "Sikeresen felvette a projekten belüli kapcsolatot a felhasználóval!",
          {
            title: "Sikeres kapcsolat felvétel",
            variant: "success",
            solid: true,
          }
        );
      } else {
        this.$bvToast.toast(createResponse.Message, {
          title: "Sikertelen kapcsolat felvétel",
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
  },
  async mounted() {
    if (this.visible) {
      await this.getUserList();
    }
  },
};
</script>
