<template>
  <div class="eh-menu-content">
    <div>
      <b-row cols="1" cols-md="2">
        <div class="col-xl-3 col-md-4" style="margin: 12px 0px 25px">
          <button
            style="margin: 0px 0px 10px"
            class="eh-action-button"
            @click="showRegistration = true"
          >
            {{
              $t("healthProfessional.patientManager.manager.newPatientSignUp")
            }}
          </button>
          <registration-patient
            v-model="showRegistration"
            @refreshList="selectOtherPatient"
            :project="project"
          />
          <fp-input
            v-model="filterInput"
            @change="searchPatient"
            :label="$t('base.basic.filter')"
          />
          <fp-participant-list
            :participantList="filteredPatientList"
            @selectedParticipant="selectPatient"
            :shownData="showPatientData"
          />
        </div>
        <!-- Kiválasztott beteg adatai -->
        <div class="col-xl-9 col-md-8" v-if="selectedPatient">
          <fp-tabs :tabs="patientCardComponents">
            <template #title="Component">{{ Component.Title }}</template>
            <template #content="Component">
              <component
                @delete-relationship="deletePatientFromProject"
                @editPatientTags="showPatientTagsEditor = true"
                :is="Component.ComponentName"
                :participant="selectedPatient"
                :tagList="tagList"
                :project="project"
              />
            </template>
          </fp-tabs>
        </div>
      </b-row>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import { UserLogic } from "@/logic/backend/user";
import { ProjectLogic } from "@/logic/backend/project";
import { PatientConnectionLogic } from "@/logic/backend/patient-connection";
/* components */
import RegistrationPatient from "../patient-manager/RegistrationPatient.vue";

export default {
  components: {
    RegistrationPatient,
  },
  props: {
    project: Object,
  },
  data() {
    return {
      patientList: [],
      filteredPatientList: [],
      selectedPatient: null,
      showNewPatientRelationship: false,
      tagList: null,
      patientCardComponents: [
        {
          ComponentName: "fp-participant-personal-profile",
          Title: this.$t(
            "healthProfessional.patientList.patientTabs.personalData"
          ),
        },
        /* {
          ComponentName: "fp-participant-questionnaire",
          Title: this.$t(
            "healthProfessional.patientList.patientTabs.questionnaires"
          ),
        }, */
        {
          ComponentName: "fp-participant-form",
          Title: this.$t("healthProfessional.patientList.patientTabs.forms"),
        },
      ],
      showPatientTagsEditor: false,
      projectRoles: null,
      filterInput: null,
      filterTagList: null,
      projects: null,
      doctorId: null,
      userList: null,
      showRegistration: false,
      showPatientData: ["Username", "Name", "Ssn"],
    };
  },
  watch: {
    async project() {
      await this.getPatientList({ "-orderby": "Username", "-limit": 100 });
      this.getLoggedUserParticipantId();
    },
  },
  methods: {
    getTextByLanguage(textObject) {
      const languages = Object.keys(textObject);
      if (languages.includes(this.$i18n.locale)) {
        return textObject[this.$i18n.locale];
      } else if (languages.includes("en")) {
        return textObject.en;
      } else {
        return textObject[languages[0]];
      }
    },
    //orvos praxisába tartozó betegek listájának kérése
    //TODO: minden beteg betöltése?
    async getPatientList(params) {
      //projekt szerepkörök tárolása
      this.projectRoles = this.project.Roles.filter((role) =>
        role.BaseRoles.includes("Patient")
      );
      if (this.project.Participants && this.project.Participants.length) {
        //kérés indítás
        var tempPatientList = await Promise.all(
          this.projectRoles.map(async (r) => {
            const patientListResponse = await UserLogic.getUserData({
              projectId: this.project.ProjectId,
              projectRole: r.RoleName,
              ...params,
            });
            //sikeres kérés?
            if (!patientListResponse.Code) {
              //van projekt adat?
              //this.patientList = this.filteredPatientList = patientListResponse;
              const ownParticipant = this.project.Participants.find(
                (p) => p.UserId == this.$loggedUser.UserId
              );
              if (ownParticipant) {
                return patientListResponse.filter((u) =>
                  ownParticipant.SubordinateIds.includes(u.UserId)
                );
              } else {
                return patientListResponse;
              }
            } else {
              this.$bvToast.toast(patientListResponse.Message, {
                title: this.$t(
                  "requestResponse.project.errorGetProjectSubordinates"
                ),
                variant: "danger",
                solid: true,
                AutoHideDelay: 10000,
              });
            }
          })
        );
        console.log(tempPatientList);
        this.patientList = this.filteredPatientList = tempPatientList.flat();
      }
    },
    //beteg kiválasztás
    selectPatient(patient) {
      //volt előzőleg kiválasztott beteg?
      if (this.selectedPatient) {
        //előzőleg kiválasztott beteg Active jelzőjét false-ra állítjuk
        this.$set(this.selectedPatient, "Active", false);
      }
      //az újonnan kiválasztott beteg tárolása
      this.selectedPatient = patient;
      //újonnan kiválasztott beteg Active jelzőjét true-ra állítjuk
      this.$set(this.selectedPatient, "Active", true);
    },
    async selectOtherPatient(newPatient) {
      //TODO: newPatient select
      await this.getPatientList({ "-orderby": "Username", "-limit": 100 });
      var tempSelectedPatient = null;
      if (newPatient && this.patientList) {
        tempSelectedPatient = this.patientList.find(
          (p) => p.UserId == newPatient.UserId
        );
      } else if (this.selectedPatient && this.patientList) {
        tempSelectedPatient = this.patientList.find(
          (p) => p.UserId == this.selectedPatient.UserId
        );
      }
      if (tempSelectedPatient) {
        this.selectPatient(tempSelectedPatient);
      }
    },
    //szöveg dátummá konvertálás
    convertDate(date) {
      //ha a szöveg valid dátum?
      return moment(date).isValid()
        ? //igen, dátummá alakítjuk
          moment(date).format("YYYY-MM-DD")
        : //nem, helyettesítő szöveg küldés
          "(" +
            this.$t("healthProfessional.patientList.patientList.notBirthday") +
            ")";
    },
    //beteg lista szűrés
    async searchPatient(input /* , hasTagFilter */) {
      if (input.length > 2) {
        await this.getPatientList({ multiValue: input });
      } else if (input) {
        this.filteredPatientList = null;
      } else {
        await this.getPatientList({ "-orderby": "Username", "-limit": 100 });
      }
    },
    searchPatientByTag(input) {
      this.filteredPatientList = this.patientList;
      if (this.filterInput) {
        this.searchPatient(this.filterInput, true);
      }
      if (input) {
        this.filteredPatientList = this.filteredPatientList.filter((patient) =>
          patient.Tags.some((tag) => tag.TagId == input)
        );
      }
    },
    clearFilters() {
      this.filterTagList = null;
      this.filterInput = null;
    },
    //beteg törlése a projektből
    async deletePatientFromProject() {
      if (confirm("Biztosan törölni szeretné a beteget a projektről?")) {
        const deleteFromProjectResponse =
          await ProjectLogic.deleteParticipantFromProject(
            this.$loggedUser.SelectedProject.ProjectId,
            this.selectedPatient.UserId
          );
        //TODO: kapcsolat törlés ha subnak van másik superior dokija,
        //projektből törlés ha nincs (successDeletePatientFromSubordinates)
        if (!deleteFromProjectResponse.Code) {
          this.$bvToast.toast(
            this.$t("requestResponse.project.successDeletePatientFrom"),
            {
              title: this.$t("base.basic.delete"),
              variant: "info",
              solid: true,
            }
          );
          this.selectedPatient = null;
          await this.selectOtherPatient();
        } else {
          this.$bvToast.toast(deleteFromProjectResponse.Message, {
            title: this.$t("requestResponse.project.errorDeletePatientFrom"),
            variant: "danger",
            solid: true,
            AutoHideDelay: 10000,
          });
        }
      }
    },
    getLoggedUserParticipantId() {
      if (this.project) {
        const participant = this.project.Participants.find(
          (participant) => participant.UserId == this.$loggedUser.UserId
        );
        //console.log(participant);
        if (participant) {
          this.doctorId = participant.UserId;
        }
      }
    },
    //felhasználó lista szűrése felhasználónév szerint
    async searchUserByName(e) {
      //a kereső input több mint 2 karakterből áll?
      if (e) {
        //igen, felhasználó lista kérés input alapján
        if (e.length > 2) {
          await this.getUserList({
            "PatientName-contains": e,
          });
        } else {
          this.userList = [];
        }
      } else {
        //nem, első 10 felhasználó lista kérés
        await this.getUserList({
          "-orderby": "RelationshipId",
          "-limit": 10,
        });
      }
    },
    //felhasználó lista kérés
    async getUserList(params) {
      //kérés indítás megadott params alapján
      const userListResponse = await PatientConnectionLogic.getPatientList(
        params
      );
      //sikeres kérés?
      if (!userListResponse.Code) {
        //igen, kapott lista tárolása
        this.userList = userListResponse.map((user) => {
          return {
            UserId: user.PatientId,
            Name: user.PatientName,
            Ssn: user.PatientSsn,
            Birth: user.PatientDateOfBirth,
          };
        });
      } else {
        this.$bvToast.toast(userListResponse.Message, {
          title: this.$t("requestResponse.basic.errorGetNamedList", {
            name: this.$t("healthProfessional.patientList.errorName"),
          }),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
  },
  //első betöltés kör fut le
  async mounted() {
    await this.getUserList({ "-orderby": "RelationshipId", "-limit": 10 });
    //beteg lista kérés
    if (this.project) {
      await this.getPatientList({ "-orderby": "Username", "-limit": 100 });
    }
    this.getLoggedUserParticipantId();
    //oldal címsorában megjelenő szöveg beállítás
    this.$store.dispatch(
      "setPageTitle",
      this.$t("healthProfessional.menu.patientList")
    );
  },
};
</script>
<style>
#new-patient-modal .modal-content {
  border-radius: 10px;
  margin: 100px 0px;
}
div#new-patient-modal div.modal-content header.modal-header,
div#new-patient-modal div.modal-content > header.modal-header {
  padding: 0px;
}
#patient-list .list-group-item.active {
  background: darkorange;
  border-color: darkorange;
  font-weight: 700;
}
</style>
