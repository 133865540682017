import TaskTable from "./TaskTable.vue";

export default {
  install(Vue) {
    if (!Vue.prototype.$activeModules) {
      Vue.prototype.$activeModules = [];
    }
    Vue.prototype.$activeModules.push("Task");
    Vue.component("fp-task-table", TaskTable);
  },
};
