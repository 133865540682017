import DocumentOperationButtons from "./DocumentOperationButtons.vue";
import DocumentsTable from "./DocumentsTable.vue";
import DictateTextInput from "./DictateTextInput.vue";

export default {
  install(Vue) {
    //Modul aktivitás beállítása
    if (!Vue.prototype.$activeModules) {
      Vue.prototype.$activeModules = [];
    }
    Vue.prototype.$activeModules.push("Document");

    //Modul komponensek regisztrálása
    Vue.component("fp-document-operation-buttons", DocumentOperationButtons);
    Vue.component("fp-document-table", DocumentsTable);
    Vue.component("fp-dictate-text-input", DictateTextInput);
  },
};
