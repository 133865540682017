<template>
  <div v-if="!params.Hidden">
    <span style="margin-left: 5px">
      <div v-if="params.Title" v-html="params.Title[language]"></div>
    </span>
    <span
      v-if="params.Required && !params.Label"
      style="color: red; font-size: 14px"
    >
      * {{ $t("Base.required") }}
    </span>
    <div v-if="params.Format == $enums.PickerComponentFormat.Date">
      <fp-date-picker
        :description="params.Description ? params.Description[language] : null"
        :required="params.Required"
        :disabled="!params.Enabled != false || disabled"
        :defaultValue="params.DefaultValue"
        :value="tempValue"
        @change="changeValue"
      />
    </div>
    <div v-else-if="params.Format == $enums.PickerComponentFormat.Time">
      <fp-time-picker
        :description="params.Description ? params.Description[language] : null"
        :required="params.Required"
        :disabled="!params.Enabled != false || disabled"
        :defaultValue="params.DefaultValue"
        :value="tempValue"
        @change="changeValue"
      />
    </div>
    <div v-else-if="params.Format == $enums.PickerComponentFormat.DateTime">
      <fp-date-time-picker
        :description="params.Description ? params.Description[language] : null"
        :required="params.Required"
        :disabled="!params.Enabled != false || disabled"
        :defaultValue="params.DefaultValue"
        :value="tempValue"
        @change="changeValue"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: "DateTimeComponent",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    params: Object,
    value: [Object, String],
    disabled: Boolean,
    language: String,
  },
  data() {
    return {
      tempValue: this.value ? this.value : this.params.DefaultValue,
    };
  },
  methods: {
    changeValue(input) {
      this.$emit("change", input);
    },
  },
};
</script>
<style scoped>
.input-text-description {
  font-size: 14px;
  margin-left: 10px;
  color: #00000099;
}
</style>
