export const DeValidate = {
  validate: {
    checkMinMax: "Minimum: {min}, Maximum: {max} kann markiert werden.",
    dateFormat: "Das Datum muss im Format JJJJ-MM-TT angegeben werden.",
    durationFormat: "Dauer muss als hh:mm angegeben werden.",
    endDateNotBeforeEqualStart:
      "Das Enddatum darf nicht mit dem Startdatum übereinstimmen oder vor diesem liegen.",
    endDateNotBeforeStart: "Das Enddatum darf nicht vor dem Startdatum liegen.",
    greaterThanZero: "Sie können keine negative Zahl eingeben.",
    heightFormat: "Die Größe muss im Format 0,00 m eingegeben werden.",
    notMatchPassword: "Die zwei Passwörter stimmen nicht überein.",
    numberMinMax: "Minimum: {min}, Maximum: {max} kann angegeben werden.",
    requiredDate: "Das Datumsfeld ist im entsprechenden Format erforderlich.",
    requiredDateTime:
      "Die Datums- und Uhrzeitfelder sind ebenfalls im entsprechenden Format erforderlich.",
    requiredField: "Pflichtfeld",
    targetDateFormat:
      "Das Zieldatum muss im JJJJ-MM-TT-Format liegen, wobei es nicht vor dem Startdatum liegen darf.",
  },
};
