<template>
  <fp-form-modal v-model="visible" :title="$t('components.changePassword.title')">
    <template #content>
      <!-- TODO: validation -->
      <fp-input
        v-model="body.OldPassword"
        :label="$t('components.changePassword.old')"
        type="password"
        :state="!validation.hasError('body.OldPassword')"
        :error="validation.firstError('body.OldPassword')"
      />
      <fp-input
        v-model="body.NewPassword"
        :label="$t('components.changePassword.new')"
        type="password"
        :state="!validation.hasError('body.NewPassword')"
        :error="validation.firstError('body.NewPassword')"
      />
      <fp-input
        v-model="body.PasswordAgain"
        :label="$t('components.changePassword.again')"
        type="password"
        :state="!validation.hasError('body.PasswordAgain')"
        :error="validation.firstError('body.PasswordAgain')"
      />
      <b-button @click="changePassword">{{ $t("base.basic.save") }}</b-button>
    </template>
  </fp-form-modal>
</template>
<script>
import { UserLogic } from "@/logic/backend/user";
export default {
  name: "ChangeUserPassword",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: Boolean,
  },
  data() {
    return {
      visible: this.value,
      body: {
        Username: null,
        OldPassword: null,
        NewPassword: null,
        PasswordAgain: null,
      },
    };
  },
  validators: {
    "body.OldPassword": function (value) {
      return this.$validator.value(value).required();
    },
    "body.NewPassword": function (value) {
      return this.$validator.value(value).required();
    },
    "body.PasswordAgain,body.NewPassword": function (passAgain, newPass) {
      if (
        this.validation.isTouched("body.NewPassword") ||
        this.validation.isTouched("body.PasswordAgain")
      ) {
        return this.$validator
          .value(passAgain)
          .required()
          .match(newPass, this.$t("validate.notMatchPassword"));
      }
    },
  },
  watch: {
    value(input) {
      this.visible = input;
      this.validation.reset();
    },
    visible(input) {
      this.$emit("change", input);
    },
  },
  methods: {
    async changePassword() {
      const valid = await this.$validate();
      if (valid) {
        this.body.Username = this.$loggedUser.Username;
        const changePasswordResponse = await UserLogic.changePassword(
          this.body
        );
        if (!changePasswordResponse.Code) {
          //siker
          this.$bvToast.toast(
            this.$t("requestResponse.basic.successModifySave"),
            {
              title: this.$t("base.basic.save"),
              variant: "success",
              solid: true,
            }
          );
          this.visible = false;
        } else {
          //hiba
          this.$bvToast.toast(changePasswordResponse.Message, {
            title: this.$t("requestResponse.basic.errorModifySave"),
            variant: "danger",
            solid: true,
            AutoHideDelay: 10000,
          });
        }
      }
    },
  },
  mounted() {
    this.body.Username = this.userName;
  },
};
</script>