import CreateProjectParticipantVue from "./CreateProjectParticipant.vue";
import CreateProjectRelationVue from "./CreateProjectRelation.vue";
import NewParticipantToListVue from "./NewParticipantToList.vue";
import ParticipantList from "./ParticipantList.vue";
import SelectedParticipantTabs from "./SelectedParticipantTabs.vue";
import ParticipantTabs from "./Tabs";

export default {
  install(Vue) {
    //TODO: tabsba importált komponensek plugin szerűen legyenek használnva
    //így csak akkor jelenik meg az elem, ha be van kapcsolva az adott modul?

    //Modul aktivitás beállítása
    if (!Vue.prototype.$activeModules) {
      Vue.prototype.$activeModules = [];
    }
    Vue.prototype.$activeModules.push("ParticipantList");

    //Modul komponensek regisztrálása
    Vue.component("fp-participant-list", ParticipantList);
    Vue.component("fp-create-project-relation", CreateProjectRelationVue);
    Vue.component("fp-selected-participant-tabs", SelectedParticipantTabs);
    Vue.component("fp-create-project-participant", CreateProjectParticipantVue);
    Vue.component("fp-new-participant-manager", NewParticipantToListVue);

    //Modul komponens csomag regisztrálása
    Vue.use(ParticipantTabs);
  },
};
