<template>
  <div v-if="!params.Hidden">
    <!-- <span>{{ params.Title }}</span>
    <span v-if="params.Required" class="text-danger">*</span>
    <div>
      <span class="input-slider-description">{{ params.Description }}</span>
    </div> -->
    <fp-slider
      :title="params.Title ? params.Title[language] : null"
      :required="params.Required"
      :description="params.Description ? params.Description[language] : null"
      :state="false"
      :disabled="!params.Enabled != false || disabled"
      :min="params.Minimum"
      :max="params.Maximum"
      :step="params.Step"
      :value="tempValue"
      @change="changeValue"
      :displayType="params.DisplayType"
      :defaultValue="params.DefaultValue"
    />
  </div>
</template>
<script>
export default {
  name: "SliderComponent",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    params: Object,
    value: [Number, String],
    disabled: Boolean,
    language: String,
  },
  data() {
    return {
      tempValue: Number.parseInt(this.value),
    };
  },
  methods: {
    changeValue(input) {
      this.$emit("change", Number.parseInt(input));
    },
  },
};
</script>