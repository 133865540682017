export const EnModule = {
  module: {
    charts: {
      default: "Not Specified",
      BloodGlucose: "Blood sugar level",
      BloodPressure: "Blood pressure",
      BMI: "Daily BMI",
      BodyWeight: "Body weight",
      CalorieIntake: "Calories intake",
      collectedMedicines: "Collected medicines",
      diastolicMax: "Highest diastolic",
      diastolicMin: "Lowest diastolic",
      distance: "Distance [m]",
      ExerciseCalories: "Exercise calories burned",
      ExerciseDistance: "Distance traveled",
      ExerciseDuration: "Time spent on exercise",
      ExerciseFloors: "Number of climbed floors",
      ExerciseSteps: "Steps taken",
      floor: " floor",
      floorsCount: "Number of floors",
      Insulin: "Insulin given",
      kcal: "Calories [kcal]",
      Medicine: "Medication diary",
      MedicineDaily: "Daily medication collection",
      MedicineMonthly: "Monthly medication collection",
      MedicineWeekly: "Weekly medication collection",
      missedMedicines: "Missed medicines",
      Mood: "Mood",
      moodValue: "Mood value",
      OxygenSaturation: "Blood oxygen saturation",
      Pulse: "Pulse",
      Respiration: "Respiration",
      Sleep: "Sleep",
      sleepQuality: "Sleep volume",
      step: " step",
      steptsCount: "Number of steps",
      systolicMax: "Highest systolic",
      systolicMin: "Lowest systolic",
      weight: "Body weight [kg]",
      weightFat: "Fat",
      weightNormal: "Normal",
      weightOverweight: "Overweight",
      weightThin: "Thin",
      weightVeryFat: "Very fat",
      bloodGlucoseMax: "Highest blood sugar",
      bloodGlucoseMin: "Lowest blood sugar",
      givenInsulin: "Injected insulin unit",
      unit: "unit",
    },
    diaries: {
      addTimestamp: "Date measured",
      bloodGlucose: {
        addNewPost: "Add a new blood glucose entry",
        bloodGlucoseValue: "Blood sugar level",
        mealQuantity: "Meal quantity",
        mealRelation: "Meal relation",
        post: "Blood glucose entries",
        title: "Blood Sugar Diary - Diabetology",
        value: "Quantity",
        valueDescription: "Enter the value in [mmol / l].",
      },
      bloodPressure: {
        addNewPost: "Add a new blood pressure entry",
        category: "Category",
        diastole: "Diastole",
        diastoleMeasure: "Measured diastole",
        diastolePlaceholder: "Diastole [hgmm]",
        post: "Blood pressure entries",
        pulse: "Measured heart rate",
        pulsePlaceholder: "Heart rate [beats per minute]",
        systole: "Systole",
        systoleMeasure: "Measured systole",
        systolePlaceholder: "Systole [hgmm]",
        title: "Blood Pressure Diary - Cardiology",
      },
      bodyWeight: {
        addNewPost: "Add a new weight entry",
        bmiCategory: "BMI category",
        bmiScroe: "BMI value",
        fat: "Fat percentage",
        fatPercentage: "Body fat percentage",
        height: "Height",
        heightPlaceholder: "Meter [m]",
        muscle: "Muscle percentage",
        musclePercentage: "Body muscle percentage",
        percentage: "Percent [%]",
        post: "Weight entries",
        title: "Weight diary - Body weight",
        water: "Water percentage",
        waterPercentage: "Body water percentage",
        weight: "Body weight",
        weightPlaceholder: "Kilogram [kg]",
      },
      ekg: {
        title: "ECG Diary - Cardiology",
      },
      exercise: {
        addNewPost: "Add a new excercise diary entry",
        burnedCalories: "Calories burned",
        burnedCaloriesDescription:
          "If not specified, it is calculated automatically",
        burnedCaloriesPlaceholder: "Calorie value [kcal]",
        distance: "Distance",
        distanceDescription: "Distance (if relevant)",
        distancePlaceholder: "Distance [m]",
        duration: "Duration of excercise (hours: minutes)",
        exerciseType: "Type of excercise",
        floorCount: "Floors taken",
        floors: "Number of climbed floors",
        floorsDescription:
          "Number of floors climbed during movement (if relevant). A floor means a vertical distance of approximately three meters or 16 steps",
        floorsPlaceholder: "Number of floors",
        intensity: "Intensity of excercise",
        post: "Excercise diary entries",
        startDate: "The start time of the excercise",
        stepCount: "Steps taken",
        steps: "Number of steps taken",
        stepsDescription:
          "Number of steps taken during the movement (if relevant)",
        stepsPlaceholder: "Number of steps",
        title: "Exercise Diary - Exercise",
        typeDefault: "Please select a movement type!",
      },
      insulin: {
        addNewPost: "Add a new insulin entry",
        date: "Date of submission",
        insulinValue: "Insulin given",
        mealRelation: "Meal relation",
        post: "Insulin entries",
        title: "Insulin diary - Diabetology",
      },
      measureDate: "Date of measurement",
      medicine: {
        addNewPost: "Add new medicine",
        logging: "Collection logging",
        medicine: "Medicine",
        medicineName: "Name of medicine",
        post: "Medication entries",
        takenCount: "Beszedett mennyiség",
        takenDate: "Date taken",
        title: "Medicine Diary - Medicine",
      },
      mood: {
        addNewPost: "Add a new mood entry",
        balanceLevel: "Balance",
        balanceLevelInput: "How balanced do you feel?",
        balanceLevelInputDescription:
          "Rate on a scale of 1 (not at all) to 5 (fully)",
        energyLevel: "Energy level",
        energyLevelInput: "What is your energy level?",
        energyLevelInputDescription: "Rate on a scale of 1 (Low) to 5 (High)!",
        moodValue: "Mood value",
        moodValueInput: "How are you feeling?",
        moodValueInputDescription:
          "Rate it on a scale of 1 (Bad) to 5 (Excellent)!",
        post: "Mood entries",
        stressLevel: "Stress",
        stressLevelInput: "How much can you cope with stress?",
        stressLevelInputDescription:
          "Rate on a scale of 1 (not at all) to 5 (fully)",
        title: "Mood Diary -Depression",
      },
      newPost: "New post",
      nutrition: {
        addNewPost: "Add a new nutrition entry",
        itemAmount: "Quantity",
        itemCalories: "Calories (optional)",
        itemName: "Name of food",
        items: "Foods",
        itemUnit: "Unit of measure",
        mealName: "Part of the day",
        post: "Nutrition entries",
        remark: "Comment",
        title: "Nutrition Diary - Nutrition",
      },
      oxygenSaturation: {
        addNewPost: "Add a new blood oxygen level entry",
        circumstances: "Measurement conditions",
        oxygenSaturationType: "Measurement condition",
        post: "Blood oxygen level entries",
        pulseValue: "Measured heart rate",
        pulseValuePlaceholder: "Heart rate [beats per minute]",
        title: "Blood Oxygen Level Log - Pulmonology",
        value: "Measured blood oxygen level",
        valuePlaceholder: "Blood oxygen level [%]",
      },
      pulse: {
        addNewPost: "Add a new heart rate entry",
        circumstances: "Intensity of movement",
        post: "Heart rate entries",
        pulseType: "Measurement condition",
        pulseValue: "Pulse",
        title: "Pulse Diary - Cardiology",
        value: "Measured heart rate",
        valuePlaceholder: "Heart rate [beats per minute]",
      },
      sleep: {
        addNewPost: "Add a new sleep entry",
        post: "Sleep entries",
        sleepDuration: "Amount of sleep",
        sleepDurationInput: "How many hours did you sleep?",
        sleepQuality: "Quality of sleep",
        sleepQualityInput: "How are you feeling?",
        sleepQualityInputDescription:
          "Rate on a scale of 1 (Not at all) to 5 (Completely)",
        title: "Sleep Diary - Depression",
      },
      respiration: {
        name: "Name",
        duration: "Duration",
        minimum: "Minimum",
        maximum: "Maximum",
        total: "Total",
        average: "Average",
        details: "Details",
        data: "Measured value",
        seconds: "Second",
        export: "Export",
        delete: "Delete",
        cancel: "Cancel",
        confirm: "Are you sure you want to delete the selected measurement?",
        measurement: "Measurement",
        unit: "pcs",
        perMin: "{m}/min",
      },
    },
    moduleDiaries: {
      BloodGlucose: "Blood sugar diary",
      BloodPressure: "Blood pressure diary",
      BodyWeight: "Weight diary",
      Ekg: "ECG diary",
      Exercise: "Exercise diary",
      Insulin: "Insulin diary",
      Medicine: "Medication diary",
      Mood: "Mood diary",
      Nutrition: "Nutrition diary",
      OxygenSaturation: "Blood oxygen level diary",
      Pulse: "Pulse diary",
      Sleep: "Sleep diary",
    },
    modules: {
      BodyWeight: "Body weight",
      bodyWeightModule: "Body weight module",
      Cardiology: "Cardiology",
      cardiologyModule: "Cardiology module",
      Depression: "Depression",
      depressionModule: "Depression module",
      Diabetology: "Diabetology",
      diabetologyModule: "Diabetology module",
      Exercise: "Exercise",
      exerciseModule: "Exercise module",
      Medicine: "Medicine",
      medicineModule: "Medicine module",
      module: "Module",
      noModule: "Unable to display module",
      Nutrition: "Nutrition",
      nutritionModule: "Nutrition module",
      Oncology: "Oncology",
      oncologyModule: "Oncology module",
      Pulmonology: "Pulmonology",
      pulmonologyModule: "Pulmonology module",
    },
    plans: {
      daily: {
        DailyMood: "Daily minimum mood value",
        DailySleepHours: "Minimum daily sleep time [hours]",
        DailyStress: "Daily maximum worst stress value",
        ExerciseCalories: "Calories burned with minimum daily exercise [kcal]",
        ExerciseDistance: "Daily minimum distance traveled [m]",
        ExerciseFloors: "Number of floors climbed with daily minimum exercise",
        ExerciseHours: "Number of hours spent with minimum daily exercise",
        ExerciseSteps: "Daily minimum number of steps",
        MaxNutrition: "Maximum daily calorie intake",
        MinNutrition: "Minimum daily calorie intake",
        PulmonologyDaily:
          "Normal blood oxygen level - Not yet connected function",
      },
      interval: {
        BloodPressure: "Blood pressure in the normal range",
        DailyExerciseCalories:
          "Calories burned with minimum daily exercise [kcal]",
        DailyExerciseDistance: "Daily minimum distance traveled [m]",
        DailyExerciseFloors:
          "Number of floors climbed with daily minimum exercise",
        DailyExerciseHours: "Number of hours spent with minimum daily exercise",
        DailyExerciseSteps: "Daily minimum number of steps",
        DailyMaxNutrition: "Maximum daily calorie intake",
        DailyMinNutrition: "Minimum daily calorie intake",
        IntervalDailyMood: "Daily minimum mood value",
        IntervalDailySleepHours: "Minimum daily sleep time [hours]",
        IntervalDailyStress: "Daily maximum worst stress value",
        PulmonologyInterval:
          "Normal blood oxygen level - Not yet connected function",
      },
      medicine: {
        addNewPlan: "New drug collection plan",
        daysOfWeek: "Days of the week",
        endDate: "End of picking",
        evening: "Evening",
        medicineName: "Name of medicine",
        morning: "Morning",
        namePlaceholder:
          "Enter the name of the medicine and search for it in the list",
        newPlan: "New medication plan",
        night: "Night",
        noon: "Noon",
        notification: "Alarm",
        plans: "Medication plans",
        quantity: "Taking amounts",
        questityNotification: "At least one intake must be set!",
        startDate: "Start of picking",
        timing: "Frequency of taking",
        timingType: "Regularity",
        title: "Medication - Taking plan",
      },
      property: {
        Bmi: "BMI [kg / m2]",
        BodyFatPercent: "Body fat percentage",
        BodyMusclePercent: "Percentage of body muscle",
        BodyWaterPercent: "Percentage of body water",
        BodyWeight: "Body weight [kg]",
        ExerciseCalories: "Total calories burned by exercise [kcal]",
        ExerciseDistance: "Total distance traveled [m]",
        ExerciseFloors: "Number of floors climbed with total motion",
        ExerciseHours: "Number of hours spent on total exercise",
        ExerciseSteps: "Total number of steps",
      },
      types: {
        daily: "Daily goal",
        interval: "Interim goal",
        property: "Reaching the target value",
      },
    },
  },
};
