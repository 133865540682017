import Questionnaire from "./Questionnaire";
import Form from "./Form";
import FormSchema from "./Schema";
import FormView from "./FormView.vue";

export default {
  install(Vue) {
    Vue.use(Questionnaire);
    Vue.use(Form);
    Vue.use(FormSchema);

    Vue.component("fp-form-view", FormView);
  },
};
