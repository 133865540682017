import moment from "moment";
import { UserManager } from "./user-manager";

export default class AuthUiLogic {
  // Indítható kérés? A token lejárt?
  static canStartRequest() {
    // Token lekérdezése a storeból
    var userToken = UserManager.getUserToken();

    // Token létezik és valid?
    if (
      userToken &&
      this.isTokenTimestampValid(userToken.ValidBy, userToken.IssuedAt)
    ) {
      // Igen, visszaadjuk a tokent
      return userToken;
    }
    //Nem, nullal térünk vissza
    return null;
  }
  //Indítható kérés az EESZT felé? A token lejárt?
  static canStartEesztRequest() {
    // local EESZT token idiglenes tárolása
    const localEesztToken = UserManager.getEesztUserTokenFromLocal();
    // aktuálisan használatos EESZT token ideiglenes tároló
    var userToken;
    // van local EESZT token?
    if (localEesztToken) {
      //Igen, localból átmozgatjuk a session-be a token-t és töröljük a localból
      userToken = JSON.parse(localEesztToken);
      UserManager.saveEesztUserTokenToSession(userToken);
      UserManager.deleteEesztUserTokenFromLocal();
    } else {
      //Nem, kivesszük a sessionből az EESZT token-t
      userToken = UserManager.getEesztUserTokenFromSession();
    }

    // a token létezik és valid?
    if (
      userToken &&
      this.isTokenTimestampValid(userToken.ValidUntil, userToken.IssuedAt)
    ) {
      //Igen, visszatérünk a tokennel
      return userToken;
    }
    //Nem, nullal térünk vissza
    return null;
  }

  //Token időpont alapú validitás ellenőrző
  static isTokenTimestampValid(validBy, issuedAt) {
    // Megvizsgáljuk hogy valamely érték hiányzik e?
    if (!validBy || !issuedAt) {
      //Igen, akkor nem valid a token, visszatérünk false-al
      return false;
    }
    // Ideiglenes tárolóba moment objektumot készítünk az időpontokból
    let tokenStart = moment(issuedAt);
    let tokenExpiry = moment(validBy);

    // Ideiglenes tárolóba elmentjük az aktuális időpontot
    let now = moment();

    //Vissza térünk a validitás true vagy false értékkel
    //Valid a token ha a tokenStart az korábban van mint az aktuális időpont plusz 10 mp
    // és ha a tokenExpiry későbbi időpont az aktuális időpnttól 10 mp-el későbbi időpontnál
    return (
      tokenStart.isBefore(now.add(10, "seconds")) &&
      tokenExpiry.isAfter(now.add(10, "seconds"))
    );
  }

  static clearSessionStorage() {
    UserManager.deleteEesztUserTokenFromLocal();
    UserManager.deleteEesztUserTokenFromSession();
    UserManager.deleteLoggedUser();
    UserManager.deleteUserToken();
  }
}
