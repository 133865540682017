import { EhssClient, RequestMethod } from '@/backend/ehss-client'
import { RequestResponseLogic } from '@/logic/ui/request-response'

const USER_PROFILE = '/user/profile'
const OTHER_PROFILE = '/user/profiles'
const PERSONAL = 'PersonalProfile'
// const MEDICAL = 'MedicalProfile'
const HEALTH_PROFESSIONAL = 'HealthProfessionalProfile'
const PARTNER = 'PartnerProfile'

export const UserProfileLogic = {
    //user profil adatok lekérése
    async getUserProfile(params) {
        let result = await EhssClient.sendRequest(
            RequestMethod.GET,
            USER_PROFILE,
            params
        )
        return await RequestResponseLogic.checkRequestResponse(result)
    },
    //User profil Személyes adatok lekérdezése
    async getUserPersonal(params) {
        // starting request
        let result = await EhssClient.sendRequest(
            RequestMethod.GET,
            USER_PROFILE + '/' + PERSONAL,
            params
        )
        return await RequestResponseLogic.checkRequestResponse(result)
    },
    //beteg profil adatok kérés
    async getPatientProfile(patientId, patientProfileType, params) {
        let result = await EhssClient.sendRequest(
            RequestMethod.GET,
            OTHER_PROFILE + '/' + patientId + '/' + patientProfileType,
            params
        )
        // checking result
        return await RequestResponseLogic.checkRequestResponse(result)
    },
    //személyes profil adatok kérés
    async getPersonalProfile(patientId, params) {
        let result = await EhssClient.sendRequest(
            RequestMethod.GET,
            OTHER_PROFILE + '/' + patientId + '/' + PERSONAL,
            params
        )
        // checking result
        return await RequestResponseLogic.checkRequestResponse(result)
    },
    async getHealthProfessionalProfile(patientId, params) {
        let result = await EhssClient.sendRequest(
            RequestMethod.GET,
            OTHER_PROFILE + '/' + patientId + '/' + HEALTH_PROFESSIONAL,
            params
        )
        // checking result
        return await RequestResponseLogic.checkRequestResponse(result)
    },
    async getPartnerProfile(patientId, params) {
        let result = await EhssClient.sendRequest(
            RequestMethod.GET,
            OTHER_PROFILE + '/' + patientId + '/' + PARTNER,
            params
        )
        // checking result
        return await RequestResponseLogic.checkRequestResponse(result)
    },
    async getOtherProfile(patientId, params) {
        let result = await EhssClient.sendRequest(
            RequestMethod.GET,
            OTHER_PROFILE + '/' + patientId,
            params
        )
        // checking result
        return await RequestResponseLogic.checkRequestResponse(result)
    },
    //felhasználó profil adatainak mentése
    async setUserProfile(body, profileId, params) {
        let result = await EhssClient.sendRequestWithBody(
            RequestMethod.PATCH,
            USER_PROFILE + '/' + profileId,
            params, body)
        return await RequestResponseLogic.checkRequestResponse(result)
    },

}