export default {
  state: {
    toastError: [],
  },
  mutations: {
    setToastError(state, payload) {
      state.toastError.push(payload);
    },
    removeToastError(state, payload) {
      const index = state.toastError.findIndex((t) => t.Id == payload);
      state.toastError.splice(index, 1);
    },
  },
  actions: {
    setToastError: ({ commit }, payload) => {
      commit("setToastError", payload);
    },
    removeToastError: ({ commit }, payload) => {
      commit("removeToastError", payload);
    },
  },
};
