export const HuEeszt = {
  EesztValidateError: {
    NotEmptyValidator:
      "{field} kitöltése kötelező az EESZT-be való feltöltéshez!",
    InclusiveBetweenValidator:
      "{field}ben az értéket {from} és {to} között lehet megadni, aktuális értéke: {current}",
    DispenseDataDispenseTimestamp: "Kiadás alap adatai > Kiadás időpontja mezp",
    PrescriptionDataOutpatientLogNumber:
      "Recept alap adatai > Forgalmi / ambuláns napló száma mező",
    PrescriptionDataPrescribingDoctor: "Felíró orvoshoz tartozó mezők",
    PrescriptionDataPatientGender: "Páciens adatai > Páciens neme mező",
    PrescriptionDataPatientAddressCity:
      "Páciens adatai > Páciens címe > Város mező",
    PrescriptionDataPatientAddressStreetAddress:
      "Páciens adatai > Páciens címe > Utca, házszám mező",
    PrescriptionDataNeakId: "Recept alap adatai > NEAK azonosító mező",
    PrescriptionDataPrescribingDoctorEnkkId:
      "Felíró orvos > ENKK azonosító mező",
    PrescriptionDataCounterSign: "Ellenjegyzéshez tartozó mezők",
    PrescriptionDataStatementPatientStatementAvailable:
      "Állapot > Beteg által tett nyilatkozat rendelkezésre áll? mező",
    PrescriptionDataPrescribingDoctorStampNumber:
      "Felíró orvos > Felíró orvos pecsétszáma mező",
    PrescriptionDataSpecialistRecommendationLogNumber:
      "Szakorvosi javaslat > Szakorvosi javaslat naplószáma mező",
    PrescriptionDataSpecialistRecommendationEnkkId:
      "Szakorvosi javaslat > Szakorvos pecsétszáma mező",
    PrescriptionDataSpecialistRecommendationSpecialistName:
      "Szakorvosi javaslat > Szakorvosi javaslatot kiállító orvos neve mező",
    DispenseDataDispenseTimestampValue: "Kiadás időpontja mező",
  },
};
