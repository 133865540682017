export default {
  superior: "Superior",
  subordinate: "Subordinate",
  superiors: "Superiors",
  subordinates: "Subordinates",
  editRelation: "Edit participants' relations",
  editParticipant: "Edit participant",
  removeProjectRelation: "Remove relation to current participant",
  removeFromProject: "Delete participant from the project",
  deleteParticipant: "Remove participant account",
};
