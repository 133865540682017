import LoggedUser from "./loggedUser";
import PageTitle from "./pageTitle";
import Toast from "./toast";

export default {
  modules: {
    loggedUser: LoggedUser,
    headerPageTitle: PageTitle,
    toast: Toast,
  },
};
