export const HuRequestResponse = {
  requestResponse: {
    appointment: {
      errorApply: "Hiba történt az időpontra jelentkezés során!",
      errorCancellation: "Hiba történt az időpontról lejelentkezés során!",
      errorClear: "Hiba történt az időpont felszabadítása során!",
      errorVisitorDelete: "Hiba történt az időpontról lejelentkeztetés során!",
      succeccApply: "Sikeresen jelentkezett a kiválasztott időpontra!",
      successApplyMessage: "Sikeres jelentkezés!",
      successCancellation:
        "Sikeresen lejelentkezett a kiválasztott időpontról!",
      successCancellationMessage: "Sikeres lejelentkezés!",
      successClear: "Sikeresen felszabadította az időpontot!",
      successVisitorDelete:
        "Sikeresen lejelentkeztette a részvevőt a kiválasztott időpontról!",
      successVisitorDeleteMessage: "Sikeres lejelentkeztetés!",
    },
    attachment: {
      errorDownloadDocument: "Hiba történt a dokumentum letöltése során!",
      errorDownloadProfilePhoto: "Hiba történt a profil kép letöltése során!",
      errorUploadDocument: "Hiba történt a dokumentum feltöltése során!",
      errorUploadProfilePhoto: "Hiba történt a profil kép feltöltése során!",
    },
    basic: {
      deleteElementConfirmMessage:
        "Törölni fogja az egyik bejegyést! Folytatja a törlést?",
      deleteProfileConfirmMessage:
        "A felhasználói fiók törlése végleges, nem visszaállítható, minden adat el fog veszni! Biztosan törli a fiókot?",
      errorDelete: "Hiba történt a törlés során!",
      errorDeleteElement: "Hiba történt az elem törlése során!",
      errorGetDiseaseList: "Hiba történt a betegség lista betöltése során!",
      errorGetExerciseTypeList:
        "Hiba történt a mozgás típusok betöltése során!",
      errorGetHospitalList: "Hiba történt a kórház lista betöltése során!",
      errorGetHospitalWardList:
        "Hiba történt a kórházi osztály lista betöltése során!",
      errorGetList: "Hiba történt a lista betöltése során!",
      errorGetMedicineList: "Hiba történt a gyógyszer lista betöltése során!",
      errorGetNamedData: "Hiba történt a/az {name} adatainak betöltése során!",
      errorGetNamedList: "Hiba történt a/az {name} lista betöltése során!",
      errorGetPharmacyList:
        "Hiba történt a gyógyszertár lista betöltése során!",
      errorModifySave: "Hiba történt a módosítások mentése során!",
      errorRichTextToolbar:
        "Az eszköztár, csak a szerkesztői felületen érhető el!",
      errorRichTextToolbarTitle: "Sikertelen művelet",
      errorSave: "Hiba történt a mentés során!",
      successAssign: "Sikeres hozzárendelés!",
      successDelete: "Sikeres törlés!",
      successDeleteElement: "Sikeresen törölte az elemet!",
      successModifySave: "Sikeresen mentette a módosításokat!",
      successRemove: "Sikeres eltávolítás!",
      successSave: "Sikeres mentés!",
      successSend: "Sikeres küldés!",
    },
    communication: {
      errorGetTemplate: "Hiba történt az üzenet sablonok betöltése során!",
      errorMessagesList:
        "Hiba történt a korábban küldött üzenet lista betöltése során!",
      errorSendMessage: "Hiba történt az üzenetek elküldése során!",
      successSendMessage: "Sikeresen elküldte az üzeneteket!",
    },
    dictate: {
      errorCreateDoc: "Hiba történt a dokumentum létrehozása során.",
      errorFormat: "Hibás válasz formátum",
      errorInCommunication:
        "Hiba a kommunikáció során. HTTP státusz kód: {response}",
      errorProcess: "Hiba a feldolgozás során. Státusz: {response}",
      errorUploadDoc: "Hiba történt a dokumentum feltöltés során.",
      pleaseFile: "Kérem adjon meg egy fájlnevet!",
      successUpload: "Sikeres feltöltés",
    },
    document: {
      errorDelete: "Hiba történt a dokumentumok törlése során!",
      errorDeleteByName: "Hiba történt a {name} nevű dokumentum törlése során!",
      errorDownload: "Hiba történt a dokumentumok letöltése során!",
      errorDownloadByName:
        "Hiba történt a {name} nevű dokumentum letöltése során!",
      errorEESZTsync: "Hiba az EESZT szinkronizáció során!",
      errorUpload: "Hiba történt a dokumentumok feltöltése során!",
      errorUploadByName:
        "Hiba történt a {name} nevű dokumentum feltöltése során!",
      successDelete: "Sikeresen törölte a dokumentumokat!",
      successEESZTsync: "Sikeresen szinkronizálva {count} db dokumentum!",
      successUpload: "Sikeresen feltöltötte a dokumentumokat!",
    },
    form: {
      errorCreateInstance: "Hiba történt az űrlap hozzárendelése során!",
      errorSave: "Hiba történt az űrlap mentése során!",
      errorSend: "Hiba történt az űrlap beküldése során!",
      successCreateInstance: "Sikeres űrlap hozzárendelés!",
      successSave: "Sikeresen mentette az űrlapot!",
      successSend: "Sikeresen beküldte az űrlapot!",
    },
    invitation: {
      errorGet: "Hiba történt a meghívások betöltése során!",
      errorInvitationDelete: "Hiba történt a meghívás visszavonása során!",
      errorSend: "Hiba történt a meghívó kiküldése során!",
      successInvitationDelete: "Sikeresen visszavonta a meghívót!",
      successInvitationDeleteMessage: "Sikeres visszavonés!",
      successSend: "Sikeresen kiküldte a meghívót!",
      successSendTitle: "Meghívó küldés",
    },
    location: {
      errorCannotGetLocation: "Nem sikerült lekérdezni a helyzetét.",
      errorGetLocation: "Hiba történt a helyzetének lekérdezése során!",
      errorLocationByBrowse:
        "A böngésző nem támogatja a helyzetének lekérdezését.",
    },
    medicinePlan: {
      errorDelete: "Hiba történt a gyógyszerszedési terv törlése során!",
      errorGetList: "Hiba történt a gyógyszerszedési tervek betöltése során!",
      errorSave: "Hiba történt a gyógyszerszedési terv felvitele során!",
      successDelete: "Sikeresen törölte a gyógyszerszedési tervet!",
      successSave: "Sikeresen mentetted a gyógyszerszedési tervet!",
    },
    moduleDiary: {
      errorDeleteElement: "Hiba történt a napló bejegyzés törlése során!",
      errorGetList: "Hiba történt a napló betöltése során!",
      errorSave: "Hiba történt a bejegyzés felvitele során!",
      successDeleteElement: "Sikeresen törölte a bejegyzést!",
      successSave: "Sikeresen mentette a bejegyzést!",
    },
    moduleHealthGoal: {
      errorDelete: "Hiba történt az egészségcél törlése során!",
      errorGet: "Hiba történt a/az {name} egészségcél betöltése során!",
      errorSave: "Hiba történt az egészségcél felvétele során!",
      successDelete: "Sikeresen törölte az egészségcélt!",
      successSave: "Sikeresen mentette a kitűzött egészségcélját!",
    },
    notification: {
      errorAccept: "Hiba történt a meghívás elfogadása során!",
      errorReject: "Hiba történt a meghívás elutasítása során!",
      successAccept: "Sikeresen elfogadta a meghívást!",
      successAcceptMessage: "Sikeres elfogadás!",
      successReject: "Sikeresen elutasította a meghívást!",
      successRejectMessage: "Sikeres elutasítás!",
    },
    project: {
      errorDeletePatientFrom:
        "Hiba történt a páciens projektből való törlése során!",
      errorDeletePatientFromSubordinates:
        "Hiba történt a projekten belül a páciens kapcsolat törlése során!",
      errorGet: "Hiba történt a projekt adatainak betöltése során!",
      errorGetProgress: "Hiba történt a projekt haladásának betöltése során!",
      errorGetProjectSubordinates:
        "Hiba történt a beteg lista betöltése során!",
      errorSavePatientTo: "Hiba történt az új beteget mentése során!",
      successDeletePatientFrom: "Sikeresen törölte a pácienst a projektből!",
      successDeletePatientFromSubordinates:
        "Sikeresen törölte a páciens kapcsolatot a projekten belül!",
      successSavePatientTo: "Sikeresen mentette az új beteget a projektbe!",
    },
    questionnaire: {
      errorAssignedMessage:
        "Hiba történt a kiválasztott kérdőív kiválasztott betegekhez való hozzárendelése során!",
      errorAssignedTitle: "Hiba történt a kérdőív kiosztása során!",
      errorCancel: "Hiba történt a kérdőív visszavonása során!",
      errorGet: "Hiba történt a kérdőív betöltése során!",
      errorReassigned: "Hiba történt a kérdőív újra kiadása során!",
      errorSave: "Hiba történt a kérdőív mentése során!",
      errorSaveEvaluate: "Hiba történt a kérdőív értékelésének mentése során!",
      errorSend: "Hiba történt a kérdőív beküldése során!",
      errorSendEvaluate:
        "Hiba történt a kérdőív értékelésének beküldése során!",
      successAssignedMessage:
        "Sikeresen hozzárendelte a kiválasztott kérdőívet a kiválasztott betegekhez!",
      successAssignedTitle: "Sikeres kérdőív kiosztás!",
      successCancel: "Sikeres kérdőív visszavonás!",
      successReassigned: "Sikeres kérdőív újra kiadás!",
      successSave: "Sikeresen mentette a kérdőívet!",
      successSaveEvaluate: "Sikeresen mentette a kérdőív értékelését!",
      successSend: "Sikeresen beküldte a kérdőívet!",
      successSendEvaluate: "Sikeresen beküldte a kérdőív értékelését!",
    },
    task: {
      errorTaskComplete: "Hiba történt a feladat teljesítés során!",
      errorTaskUncomplete:
        "Hiba történt a feladat teljesítésének visszavonása során!",
      successTaskComplete: "Sikeresen teljesítette a feladatot!",
      successTaskUncomplete: "Sikeresen visszavonta a feladat teljesítését!",
    },
    template: {
      errorAddToProject: "Hiba történt a sablon projekthez rendelése során!",
      errorRemoveFromProject:
        "Hiba történt a sablon projektből való eltávolítása során!",
      successAddToProject: "Sikeresen hozzárendelte a sablont a projekthez!",
      successRemoveFromProject: "Sikeresen törölte a sablont a projektből!",
    },
    userSetting: {
      errorProjectRemark:
        "Hiba történt a projekt megjegyzés lekérdezése során!",
      errorProjectRemarkModify:
        "Hiba történt a projekt megjegyzés módosítása során!",
      errorRelationshipTypeRemark:
        "Hiba történt a kapcsolat típusának, és megjegyzés lekérdezése során!",
      errorRelationshipTypeRemarkModify:
        "Hiba történt a kapcsolat típusának, és megjegyzés módosítása során!",
    },
    workflow: {
      errorAbortMessage: "Hiba történt a folyamat megszakítása során!",
      errorCreateInsatnce: "Hiba történt a folyamat példány létrehozása során!",
      errorStart: "Hiba történt a folyamat elindítása során!",
      successAbortMessage: "Sikeres folyamat megszakítás!",
      successAbortTitle: "Folyamat megszakítás",
      successStart: "Sikeresen elindította a folyamatot!",
      successStartTitle: "Folyamat indítás",
    },
  },
};
