export default {
  appointments: "Időpontok",
  startTime: "Kezdési időpont",
  endTime: "Záró időpont",
  visitorsCount: "Férőhelyek száma",
  clearAppointmentConfirm:
    "Biztosan törölni szeretné az összes résztvevőt az időpontról?",
  deleteVisitorConfirm:
    "Biztosan törölni szeretné {visitorName} nevű résztvevőt az időpontról?",
};
