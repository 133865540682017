const USER_TOKEN_STORAGE_KEY = process.env.VUE_APP_USER_TOKEN_STORAGE_KEY;
const EESZT_USER_TOKEN_STORAGE_KEY = process.env.VUE_APP_EESZT_USER_TOKEN_STORAGE_KEY;
const LOGGED_USER_STORAGE_KEY = process.env.VUE_APP_LOGGED_USER_STORAGE_KEY;

export const UserManager = {
  saveUserToken(token) {
    sessionStorage.setItem(USER_TOKEN_STORAGE_KEY, JSON.stringify(token));
  },
  getUserToken() {
    const token = sessionStorage.getItem(USER_TOKEN_STORAGE_KEY);
    return JSON.parse(token);
  },
  deleteUserToken() {
    sessionStorage.removeItem(USER_TOKEN_STORAGE_KEY);
  },
  saveEesztUserTokenToSession(token) {
    sessionStorage.setItem(EESZT_USER_TOKEN_STORAGE_KEY, JSON.stringify(token));
  },
  getEesztUserTokenFromSession() {
    const token = sessionStorage.getItem(EESZT_USER_TOKEN_STORAGE_KEY);
    return JSON.parse(token);
  },
  deleteEesztUserTokenFromSession() {
    sessionStorage.removeItem(EESZT_USER_TOKEN_STORAGE_KEY);
  },
  saveEesztUserTokenToLocal(token) {
    localStorage.setItem(EESZT_USER_TOKEN_STORAGE_KEY, JSON.stringify(token));
  },
  getEesztUserTokenFromLocal() {
    const token = localStorage.getItem(EESZT_USER_TOKEN_STORAGE_KEY);
    return JSON.parse(token);
  },
  deleteEesztUserTokenFromLocal() {
    localStorage.removeItem(EESZT_USER_TOKEN_STORAGE_KEY);
  },
  saveLoggedUser(user) {
    sessionStorage.setItem(LOGGED_USER_STORAGE_KEY, JSON.stringify(user));
    //Vue.prototype.$loggedUser = user;
  },
  getLoggedUser() {
    const user = JSON.parse(sessionStorage.getItem(LOGGED_USER_STORAGE_KEY));
    //Vue.prototype.$loggedUser = user;
    return user;
  },
  deleteLoggedUser() {
    sessionStorage.removeItem(LOGGED_USER_STORAGE_KEY);
    //Vue.prototype.$loggedUser = null;
  },
};
