export const ModulesTechnicalData = {
  emptyModules: {
    Exercise: { Charts: { exercise_calories: {}, exercise_duration: {}, exercise_distance: {}, exercise_steps: {}, exercise_floors: {} } },
    Cardiology: { Charts: { blood_pressure: {}, pulse: {} } },
    Nutrition: { Charts: { calorie_intake: {} } },
    Depression: { Charts: { mood: {}, sleep: {} } },
    BodyWeight: { Charts: { body_weight: {}, bmi: {} } },
    /* Oncology: {}, */
    Pulmonology: { Charts: { oxygen_saturation: {}, respiration: {} } },
    Medicine: { Charts: { medicine: {}, daily_medicine: {}, weekly_medicine: {}, monthly_medicine: {} } },
    Diabetology: { Charts: { blood_glucose: {}, insulin: {} } },
  },
  modulesTechnicalData: {
    //BodyWeight
    BodyWeight: {
      Name: 'BodyWeight',
      Color: '#63B7E4',
      Route: 'ClientBodyWeightModule',
      ActiveURL: 'active/icon-bmi-active.png',
      IconURL: 'default-icons/icon-bmi.png',
      Buttons: [
        {
          Name: 'BodyWeight',
          RouteName: 'BodyWeightDiary',
          DiaryName: 'body_weight'
        },
      ],
      HealthPlans: {
        property: [
          { Value: "BodyWeight", Name: 'BodyWeight' },
          { Value: "Bmi", Name: 'Bmi' },
          { Value: "BodyFatPercent", Name: 'BodyFatPercent' },
          { Value: "BodyWaterPercent", Name: 'BodyWaterPercent' },
          { Value: "BodyMusclePercent", Name: 'BodyMusclePercent' },
        ]
      }
    },
    // Nutrition
    Nutrition: {
      Name: 'Nutrition',
      Color: '#F1B143',
      IconURL: 'default-icons/icon-nutrition.png',
      ActiveURL: 'active/icon-nutrition-active.png',
      InActiveURL: 'inactive/icon-nutrition-inactive.png',
      Route: 'ClientNutritionModule',
      Buttons: [
        {
          Name: 'Nutrition',
          RouteName: 'NutritionDiary',
          DiaryName: 'nutrition',
        },
      ],
      HealthPlans: {
        daily: [
          { Value: "MinNutrition", Name: "MinNutrition" },
          { Value: "MaxNutrition", Name: "MaxNutrition" },
        ],
        interval: [
          { Value: "DailyMinNutrition", Name: "DailyMinNutrition" },
          { Value: "DailyMaxNutrition", Name: "DailyMaxNutrition" },
        ]
      }
    },
    // Exercise
    Exercise: {
      Name: 'Exercise',
      Route: 'ClientExerciseModule',
      IconURL: 'default-icons/icon-exercise.png',
      InActiveURL: 'inactive/icon-exercise-inactive.png',
      ActiveURL: 'active/icon-exercise-active.png',
      Color: '#F28C3C',
      Buttons: [
        {
          Name: 'Exercise',
          RouteName: 'ExerciseDiary',
          DiaryName: 'exercise',
        },
      ],
      HealthPlans: {
        property: [
          { Value: "ExerciseHours", Name: 'ExerciseHours' },
          { Value: "ExerciseCalories", Name: 'ExerciseCalories' },
          { Value: "ExerciseDistance", Name: 'ExerciseDistance' },
          { Value: "ExerciseSteps", Name: 'ExerciseSteps' },
          { Value: "ExerciseFloors", Name: 'ExerciseFloors' },
        ],
        daily: [
          { Value: "ExerciseHours", Name: 'ExerciseHours' },
          { Value: "ExerciseCalories", Name: 'ExerciseCalories' },
          { Value: "ExerciseDistance", Name: 'ExerciseDistance' },
          { Value: "ExerciseSteps", Name: 'ExerciseSteps' },
          { Value: "ExerciseFloors", Name: 'ExerciseFloors' },
        ],
        interval: [
          { Value: "DailyExerciseHours", Name: 'DailyExerciseHours' },
          { Value: "DailyExerciseCalories", Name: 'DailyExerciseCalories' },
          { Value: "DailyExerciseDistance", Name: 'DailyExerciseDistance' },
          { Value: "DailyExerciseSteps", Name: 'DailyExerciseSteps' },
          { Value: "DailyExerciseFloors", Name: 'DailyExerciseFloors' },
        ]
      }
    },
    // Medicine
    Medicine: {
      Name: 'Medicine',
      Color: '#63A871',
      Route: 'ClientMedicineModule',
      IconURL: 'default-icons/icon-pills.png',
      ActiveURL: 'active/icon-pills-active.png',
      Buttons: [
        {
          Name: 'Medicine',
          RouteName: 'MedicineDiary',
          DiaryName: 'medicine',

        },
      ]
    },
    // Pulmonology
    Pulmonology: {
      Name: 'Pulmonology',
      Color: '#F3ADE6',
      Route: 'ClientPulmonologyModule',
      IconURL: 'default-icons/icon-pulmonology.png',
      ActiveURL: 'active/icon-pulmonology-active.png',
      Buttons: [
        {
          Name: 'OxygenSaturation',
          RouteName: 'OxygenSaturationDiary',
          DiaryName: 'oxygen_saturation',
        },
      ],
      HealthPlans: {
        daily: [
          { Value: "ExerciseHours", Name: 'PulmonologyDaily', Normal: true },
        ],
        interval: [
          { Value: "DailyExerciseHours", Name: 'PulmonologyInterval', Normal: true },
        ]
      }
    },
    // Cardiology
    Cardiology: {
      Name: 'Cardiology',
      Route: 'ClientCardiologyModule',
      IconURL: 'default-icons/icon-hearth.png',
      InActiveURL: 'inactive/icon-hearth-inactive.png',
      ActiveURL: 'active/icon-hearth-active.png',
      Color: '#EC4A50',
      Buttons: [
        {
          Name: 'BloodPressure',
          RouteName: 'BloodPressureDiary',
          DiaryName: 'blood_pressure',
        },
        {
          Name: 'Pulse',
          RouteName: 'PulseDiary',
          DiaryName: 'pulse',
        },
      ],
      HealthPlans: {
        interval: [
          { Value: "BloodPressure", Name: 'BloodPressure', Normal: true },
        ],
      }
    },
    // Diabetology
    Diabetology: {
      Name: 'Diabetology',
      IconURL: 'default-icons/icon-diabetology.png',
      InActiveURL: 'inactive/icon-diabetology-inactive.png',
      ActiveURL: 'active/icon-diabetology-active.png',
      Route: 'ClientDiabetologyModule',
      Color: '#2A5DD3',
      Buttons: [
        {
          Name: 'BloodGlucose',
          RouteName: 'BloodGlucoseDiary',
          DiaryName: 'blood_glucose',
        },
        {
          Name: 'Insulin',
          RouteName: 'InsulinDiary',
          DiaryName: 'insulin',
        },
      ]
    },
    // Depression
    Depression: {
      Name: 'Depression',
      IconURL: 'default-icons/icon-mood.png',
      InActiveURL: 'inactive/icon-mood-inactive.png',
      ActiveURL: 'active/icon-mood-active.png',
      Color: '#9E6BB8',
      Route: 'ClientDepressionModule',
      Buttons: [
        {
          Name: 'Sleep',
          RouteName: 'SleepDiary',
          DiaryName: 'sleep',
        },
        {
          Name: 'Mood',
          RouteName: 'MoodDiary',
          DiaryName: 'mood',
        },
      ],
      HealthPlans: {
        daily: [
          { Value: "Mood", Name: "DailyMood" },
          { Value: "Stress", Name: "DailyStress" },
          { Value: 'SleepHours', Name: 'DailySleepHours' }
        ],
        interval: [
          { Value: "DailyMood", Name: "IntervalDailyMood" },
          { Value: "DailyStress", Name: "IntervalDailyStress" },
          { Value: 'DailySleepHours', Name: 'IntervalDailySleepHours' }
        ]
      }
    },
    // Oncology
    Oncology: {
      Name: 'Oncology',
      IconURL: 'default-icons/icon-oncology.png',
      InActiveURL: 'inactive/icon-oncology-inactive.png',
      ActiveURL: 'active/icon-oncology-active.png',
      Route: 'ClientOtherModule',
      Color: '#45a6b3',
    },
  },
  chartsTechnicalData: {
    // BodyWeight
    body_weight: {
      Name: 'BodyWeight',
      ComponentName: 'fp-bodyweight-bodyweight',
      ChartName: 'body_weight',
      DatabaseName: 'body_weight',
      DataProperties: {
        chart: { y: 'BodyWeight', x: 'Date' },
        diary: { y: 'BodyWeight', x: 'MeasuredAt' }
      },
      DisplayPlan: {
        planType: 'property',
        planModuleType: 'BodyWeight'
      },
      DisplayUnit: 'kg',
    },
    bmi: {
      Name: 'BMI',
      ComponentName: 'fp-bodyweight-bmi',
      ChartName: 'bmi',
      DatabaseName: 'body_weight',
      DataProperty: 'Bmi',
      isDisableInterval: true,
    },
    //Cardiology
    blood_pressure: {
      Name: 'BloodPressure',
      ChartName: 'blood_pressure',
      DatabaseName: 'blood_pressure',
      DataProperties: {
        chart: { low: 'DiastolicPressure', high: 'SystolicPressure', x: 'Date' },
        diary: { low: 'DiastolicPressure', high: 'SystolicPressure', x: 'MeasuredAt' }
      },
      ComponentName: 'fp-cardiology-bloodpressure',
      DisplayPlan: {
        planType: 'interval',
        planModuleType: 'BloodPressure'
      },
      DisplayUnit: 'hgmm',
    },
    pulse: {
      Name: 'Pulse',
      ChartName: 'pulse',
      DatabaseName: 'pulse',
      DataProperties: {
        chart: { high: 'PulseMaxMeasurement', low: 'PulseMinMeasurement', x: 'Date' },
        diary: { x: 'MeasuredAt', y: 'PulseValue' }
      },
      ComponentName: 'fp-cardiology-pulse',
      DisplayUnit: '1/min',
    },
    //Pulmonology
    oxygen_saturation: {
      Name: 'OxygenSaturation',
      ChartName: 'oxygen_saturation',
      DatabaseName: 'oxygen_saturation',
      ComponentName: 'fp-pulmonology-oxygensaturation',
      DataProperties: {
        chart: { high: 'OxygenSaturationMaxMeasurement', low: 'OxygenSaturationMinMeasurement', x: 'Date' },
        diary: { y: 'OxygenSaturationValue', x: 'MeasuredAt' }
      },
      DisplayUnit: '%',
    },
    /* respiration: { 
      Name: 'Respiration', // szótárnál használja
      ComponentName: 'PulmonologyRespiration', // chart component neve
      ChartName: 'respiration',
      DatabaseName: 'respiration',
      DataProperties: { // ezeket am semmire sem használom jelenleg
        chart: { x: 'Date', y: 'SumDistance' },
        diary: {
          x: 'MeasuredAt',
          min: 'MinInhalationsPerMinute',
          max: 'MaxInhalationsPerMinute',
          sum: 'TotalInhalationsCount',
          avg: 'AvgInhalationsCount'
        }
      },
      DisplayUnit: 'module.diaries.respiration.unit'
    }, */
    respiration: {
      Name: 'Respiration', // szótárnál használja
      ComponentName: 'fp-pulmonology-respirationmeasurement', // chart component neve
      ChartName: 'respiration',
      DatabaseName: 'respiration',
      DataProperties: { // ezeket am semmire sem használom jelenleg
        chart: { x: 'Date', y: 'SumDistance' },
        diary: {
          x: 'MeasuredAt',
          min: 'MinInhalationsPerMinute',
          max: 'MaxInhalationsPerMinute',
          sum: 'TotalInhalationsCount',
          avg: 'AvgInhalationsCount'
        }
      },
      DisplayUnit: 'module.diaries.respiration.unit'
    },
    //Diabetology
    blood_glucose: {
      Name: 'BloodGlucose',
      ComponentName: 'fp-diabetology-bloodglucose',
      ChartName: 'blood_glucose',
      DatabaseName: 'blood_glucose',
      DataProperties: {
        chart: {
          low: 'BloodGlucoseMinMeasurement',
          high: 'BloodGlucoseMaxMeasurement',
          x: 'Date'
        },
        diary: { y: 'BloodGlucoseValue', x: 'MeasuredAt' }
      },
      DisplayUnit: 'mmol/L',
    },
    insulin: {
      Name: 'Insulin',
      ComponentName: 'fp-diabetology-insulin',
      ChartName: 'insulin',
      DatabaseName: 'insulin',
      DataProperties: {
        chart: { x: 'Date', y: 'InsulinSum' },
        diary: { x: 'MeasuredAt', y: 'InsulinValue' }
      }
    },
    //Sleep
    sleep: {
      Name: 'Sleep',
      ComponentName: 'fp-depression-sleep',
      ChartName: 'sleep',
      DatabaseName: 'sleep',
      DataProperties: {
        chart: { x: 'Date', y: 'SleepSum' },
        diary: { x: 'MeasuredAt', y: 'SleepDuration' }
      }
    },
    //Mood
    mood: {
      Name: 'Mood',
      ComponentName: 'fp-depression-mood',
      ChartName: 'mood',
      DatabaseName: 'mood',
      DataProperties: {
        chart: { x: 'Date', y: 'BestMood' },
        diary: { x: 'MeasuredAt', y: 'MoodValue' }
      }
    },
    //Medicine
    medicine: {
      Name: 'Medicine',
      DatabaseName: 'medicine'
    },
    daily_medicine: {
      Name: 'MedicineDaily',
      ChartName: 'daily_medicine',
      DatabaseName: 'medicine_plan',
      ComponentName: 'fp-medicine-daily',
      DataProperties: {
        taken: 'MedicineTaken',
        missed: { sum: 'SumMedicine', taken: 'MedicineTaken' },
        takenPercentage: 'MedicineTakenPercentage'
      },
      isDisableInterval: true,
    },
    weekly_medicine: {
      Name: 'MedicineWeekly',
      ChartName: 'weekly_medicine',
      DatabaseName: 'medicine_plan',
      ComponentName: 'fp-medicine-weekly',
      DataProperties: {
        date: 'Date',
        taken: 'MedicineTaken',
        sum: 'SumMedicine'
      },
      isDisableInterval: true,
    },
    monthly_medicine: {
      Name: 'MedicineMonthly',
      ChartName: 'monthly_medicine',
      DatabaseName: 'medicine_plan',
      ComponentName: 'fp-medicine-monthly',
      DataProperties: {
        date: 'Date',
        taken: 'MedicineTaken',
        sum: 'SumMedicine'
      },
      isDisableInterval: true,
    },
    //Exercise
    exercise_calories: {
      Name: 'ExerciseCalories',
      ComponentName: 'fp-exercise-calories',
      ChartName: 'exercise_calories',
      DatabaseName: 'exercise',
      DataProperties: {
        chart: { x: 'Date', y: 'SumCalories' },
        diary: { x: 'MeasuredAt', y: 'CaloriesBurned' }
      },
      DisplayUnit: 'kcal',
    },
    exercise_duration: {
      Name: 'ExerciseDuration',
      ComponentName: 'fp-exercise-duration',
      ChartName: 'exercise_duration',
      DatabaseName: 'exercise',
      DataProperties: {
        chart: { x: 'Date', y: 'SumDuration' },
        diary: { x: 'MeasuredAt', y: 'Duration' }
      },
      DisplayPlan: {
        planType: 'interval',
        planModuleType: 'DailyExerciseHours'
      },
      DisplayUnit: 'HH:MM'
    },
    exercise_distance: {
      Name: 'ExerciseDistance',
      ComponentName: 'fp-exercise-distance',
      ChartName: 'exercise_distance',
      DatabaseName: 'exercise',
      DataProperties: {
        chart: { x: 'Date', y: 'SumDistance' },
        diary: { x: 'MeasuredAt', y: 'Distance' }
      },
      DisplayPlan: {
        planType: 'interval',
        planModuleType: 'DailyExerciseDistance'
      },
      DisplayUnit: 'm'
    },
    exercise_steps: {
      Name: 'ExerciseSteps',
      ComponentName: 'fp-exercise-steps',
      ChartName: 'exercise_steps',
      DatabaseName: 'exercise',
      DataProperties: {
        chart: { x: 'Date', y: 'SumSteps' },
        diary: { x: 'MeasuredAt', y: 'StepCount' }
      },
      DisplayPlan: {
        planType: 'interval',
        planModuleType: 'DailyExerciseSteps'
      },
    },
    exercise_floors: {
      Name: 'ExerciseFloors',
      ComponentName: 'fp-exercise-floors',
      ChartName: 'exercise_floors',
      DatabaseName: 'exercise',
      DataProperties: {
        chart: { x: 'Date', y: 'SumFloors' },
        diary: { x: 'MeasuredAt', y: 'FloorCount' }
      },
      DisplayPlan: {
        planType: 'interval',
        planModuleType: 'DailyExerciseFloors'
      },
    },
    //Nutrition
    calorie_intake: {
      Name: 'CalorieIntake',
      ComponentName: 'fp-nutrition-calprieintake',
      ChartName: 'calorie_intake',
      DatabaseName: 'nutrition',
      DataProperties: {
        chart: { x: 'Date', y: 'SumCalories' },
        diary: { x: 'MeasuredAt', y: 'SumCalories', arrayKey: 'Items', sumValue: 'Calories' } //TODO: diary??
      },
      DisplayPlan: {
        planType: 'daily',
        planModuleTypes: ['MinNutrition', 'MaxNutrition']
      },
    },
  },
  diaryTechnicalData: {
    //TODO: ??
  }
  /* functionsTechnicalData: {
    BodyWeight: {
      RouteName: 'BodyWeightDiary',
      DiaryName: 'body_weight'
    },
    Exercise: {
      RouteName: 'ExerciseDiary',
      DiaryName: 'exercise',
    },
  } */
}