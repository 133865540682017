import { ClientService, RequestMethod } from '../client-service'
import { RequestResponseLogic } from '../Ui/request-response'

const RELATIONSHIP_TAG = '/tag'

export const TagLogic = {
    async getTags(params) {
        let result = await ClientService.sendRequest(RequestMethod.GET, RELATIONSHIP_TAG, params)
        return await RequestResponseLogic.checkRequestResponse(result)
    },
    async createTag(body, params) {
        let result = await ClientService.sendRequestWithBody(RequestMethod.POST, RELATIONSHIP_TAG, params, body)
        return await RequestResponseLogic.checkRequestResponse(result)
    },
    async setTag(tagId, body, params) {
        let result = await ClientService.sendRequestWithBody(RequestMethod.PATCH, RELATIONSHIP_TAG + '/' + tagId, params, body)
        return await RequestResponseLogic.checkRequestResponse(result)
    },
    async deleteTag(tagId, params) {
        let result = await ClientService.sendRequest(RequestMethod.DELETE, RELATIONSHIP_TAG + '/' + tagId, params)
        return await RequestResponseLogic.checkRequestResponse(result)
    }
}