export const EnClient = {
  "client": {
    "badges": {
      "availableBadges": "Badges available",
      "recievedBadges": "Badges earned"
    },
    "diseaseGuide": {
      "diseaseBNOCode": "ICD code",
      "diseaseBodyPart": "Can be attached to a body part:",
      "diseaseBodyPartList": "Body part",
      "diseaseDescription": "Description:",
      "diseaseDescriptionPart": "Description text",
      "diseaseList": "Disease list",
      "diseaseName": "Name of disease",
      "diseaseSex": "Related to gender:",
      "diseaseSexList": "Sex",
      "filteredFields": "Filterable fields",
    },
    "documents": {
      "ehrDocuments": "EHR DOCUMENTS",
      "otherDocuments": "EHR DOCUMENTS",
      "phrDocuments": "PHR DOCUMENTS",
      errorName: 'Document',
      documentType: 'Document type'
    },
    "functionSettings": {
      "chartOrder": "Sequence of graphs",
      "charts": "Graphs",
      "chartsSettingFields": {
        "displayName": "Name",
        "enabled": "On",
        "interval": "Interval",
        "size": "Size"
      },
      "functionFields": {
        "key": "Name",
        "value": "On"
      },
      "functions": "Features",
      "moduleSettings": "{module} settings"
    },
    "healthGoals": {
      "completed": "Completed",
      "completedNumberOfDays": "Number of days completed: {count}",
      "completedRelatedNumberOfDays": "Completed related days completed: {count}",
      "dailyNumberOfDays": "Within an interval, performance over consecutive days",
      "day": "{count} day",
      "daysCount": "Number of days",
      "emptyMessage": "No goal to display yet",
      "goalDaily": "Daily goal",
      "goalDailyValue": "Daily target",
      "goalDailyValuePlaceholder": "At least daily target",
      "goalDefaultType": "Select the type of new goal",
      "goalInterval": "Interim goal",
      "goalProperty": "Reaching the target value",
      "goalType": "Goal type",
      "goalValue": "Target value",
      "goalValuePlaceholder": "Planned value",
      "healthGoals": "{name} health goals",
      "intervalMinDayCount": "Minimum number of completion days within an interval",
      "moduleDefaultType": "Select the new target module",
      "moduleType": "Module type",
      "newGoal": "Add {name} health goal",
      "numberOfDaysRemaining": "Days left: {count}",
      "startDate": "Beginning date",
      "startDateErrorMessage": "The date must be in YYYY-MM-DD format",
      "status": "Condition",
      "targetDate": "Target date",
      "targetDateErrorMessage": "Target date must be in YYYY-MM-DD format, which cannot be earlier than the start date",
      "timeProgressPercentage": "Progress over time",
      "targetValue": "Target value",
      "timestamp": "Added",
      "title": "Health goal",
      "valueInNormalInterval": "Value in the normal range",
      "valueProgressPercentage": "Goal value progress",
      startValue: 'Initial value',
      startValuePlaceholder: 'Initial value'
    },
    "home": {
      "enabledModules": "Enabled modules",
      "settingModules": "Edit modules"
    },
    "menu": {
      "badges": "Badges",
      "diseaseGuide": "Disease guide",
      "documents": "Documents",
      "homeButton": "Personal home page",
      "notifications": "Notifications",
      "profileFillingRate": "Profile completion",
      "profileMedicalData": "Medical data: ",
      "profilePersonalData": "Personal data: ",
      "programs": "My programs",
      "questionnaires": "Questionnaires",
      "serviceFinder": "Service finder",
      "tasks": "My tasks",
      profile: 'My profile',
      appointments: 'Appointments',
    },
    "moduleSettings": {
      "availableModules": "Available modules",
      "enabledModules": "Enabled modules",
      "title": "Edit modules"
    },
    "profile": {
      "changePassword": "Change password",
      "medicalData": {
        "name": "Name",
        "note": "Note",
        "result": "Result",
        "administrationDate": "Date of submission",
        "agentName": "Name of agent",
        "allergy": "Allergy",
        "appearDate": "Release date",
        "bloodType": "Blood type",
        "bnoCode": "ICD code",
        "cessationDate": "Termination date",
        "chilhoodDisease": "Childhood diseases",
        "chronicDisease": "Chronic diseases",
        "dieseaseName": "Name of disease",
        "disease": "Disease",
        "diseaseBySelf": "Manual entry of disease",
        "diseaseFromList": "Select a disease from a list",
        "diseaseName": "Name of disease",
        "dose": "Dose",
        "drugSensitivity": "Drug sensitivity",
        "endDate": "End date",
        "exceptionDate": "Exception date",
        "familyAnamnesis": "Family anamnesis",
        "implant": "Implant",
        "insertionDate": "Date of insertion",
        "longtermMedication": "Longterm medication",
        "medicine": "Medicine",
        "medicineBySelf": "Manual administration of medication",
        "medicineFromList": "Select a medicine from a list",
        "medicineName": "Name of medicine",
        "newAllergy": "Adding a new allergy",
        "newDisease": "Adding a new disease",
        "newImplant": "Adding a new implant",
        "newMedicine": "Adding a new medicine",
        "newPregnancy": "Adding a new pregnancy",
        "newPreviousProcedure": "Adding a new treatment",
        "newSensitivity": "Addition of a new active substance",
        "newVaccination": "Adding a new vaccine",
        "noData": "No data available",
        "oenoCode": "Treatment code",
        "outcome": "Outcome",
        "package": "Package",
        "perceptionDate": "Date of detection",
        "physicalActivity": "Daily physical activity",
        "pregnancy": "Pregnancy",
        "previousDisease": "Previous illnesses",
        "previousProcedure": "Previous treatments",
        "procedure": "Treatment",
        "procedureBySelf": "Manual administration of  treatment",
        "procedureFromList": "Select a treatment from a list",
        "procedureName": "Name of treatment",
        "relationshipType": "Relationship type",
        "sensitivity": "Active substance",
        "sensitivityBySelf": "Manul administration of active substance",
        "sensitivityFromList": "Selecting an active substance from a list",
        "sensitivityName": "Name of active substance",
        "skinType": "Skin type",
        "startDate": "Start date",
        "stillLasts": "Still lasts",
        "stillTaking": "Still taking",
        "takeEndDate": "Taking end date",
        "takeStartDate": "Taking start date",
        "title": "Health data",
        "vaccination": "Vaccinations"
      },
      "personalData": {
        "address": "Address data",
        "birthday": "Date of birth",
        "birthplace": "Place of birth",
        "chat": "Chat",
        "communication": "Communication",
        "email": "E-mail",
        "emailAddress": "E-mail address",
        "emergencyPerson": "Emergency Person",
        "idCard": "Identity card number",
        "maritalStatus": "Marital status",
        "mother": "Mother's name",
        "name": "Name",
        "nationality": "Nationality",
        "phone": "Phone number",
        "push": "Push",
        "relationshipType": "Relationship",
        "sex": "Sex",
        "sms": "SMS",
        "ssn": "Social security number",
        "title": "Personal data",
        "username": "Username"
      },
      errorName: 'profile'
    },
    "programs": {
      "carePrograms": "Care programs",
      "healthPrograms": "Health programs",
      "period": "Period",
      "projectName": "Project name",
      "projectTitle": "Project title",
      "state": "State",
      "taskList": "List of expectations",
      close: 'Close',
      details: "Details",
      errorCareName: 'Care program',
      projectGoal: 'Project goal',
      projectCriteria: "Selection criteria",
      description: 'Description',
      errorHealthName: 'Health program'
    },
    "questionnaires": {
      "description": "Description",
      "questionnaire": {
        title: 'Questionnaire',
        "date": "Date of evaluation",
        "longDescription": "Long evaluation",
        "note": "Note",
        "score": "Result",
        "shortDescription": "Short evaluation"
      },
      "percentage": "Occupancy",
      "requiredFieldsNotification": "To submit the questionnaire, fill in the required fields marked with an asterisk.",
      "startDateTime": "Date added",
      "status": "Condition",
      "stopDateTime": "Date completed",
      "title": "Title",
      patient: "Patient's data",
      errorName: 'questionnaire'
    },
    "serviceFinder": {
      "city": "City",
      "district": "District",
      "hospital": "Hospital",
      "infoAddress": "Address: ",
      "infoEmail": "E-mail: ",
      "infoName": "Name: ",
      "infoOpeningHours": "Opening hours: ",
      "infoPhone": "Phone: ",
      "information": "Information",
      "infoWard": "Class: ",
      "infoWebsite": "Website: ",
      "pharmacy": "Pharmacy",
      "serviceType": "Type of service",
      "ward": "Class"
    },
    task: {
      errorName: 'To do'
    },
    appointment: {
      title: 'Title',
      description: 'Description',
      startTime: 'Starting time',
      endTime: 'Closing date',
      location: 'Location',
      filterDescription: 'Search in title, description.',
      date: 'Date',
      inProgress: 'In progress',
      cancellation: 'Cancel appointment',
      errorName: 'date',
      own: 'My appointments',
      free: 'Free appointments'
    },
    notification: {
      noti: 'Notifications',
      invitation: 'Invitations',
      requestedBy: 'Sender',
      parameters: 'Parameters',
      status: 'Status',
      errorInvitationName: 'Invitation'
    }
  },
}