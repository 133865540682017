<template>
  <div>
    <!-- TODO: sticky header? -->
    <fp-table-list
      :refId="refId + 'documents'"
      class="documents-table"
      :hasPagination="true"
      :items="documentList"
      :stickyHeader="false"
      :fields="fields"
      selectMode="multi"
      @row-selected="onRowSelected"
      :selectable="true"
      :isSelectAll.sync="isSelectedAll"
      :isSelectClear.sync="isSelectedClear"
    >
      <!-- <template #operations><slot name="operations"></slot></template> -->
      <template #Selected="{ rowSelected }">
        <template v-if="rowSelected">
          <div style="margin: 20px">
            <span aria-hidden="true">&check;</span>
            <span class="sr-only">Selected</span>
          </div>
        </template>
        <template v-else>
          <span aria-hidden="true">&nbsp;</span>
          <span class="sr-only">Not selected</span>
        </template>
      </template>
      <template #FileUploadedAt="row">
        <div>
          <label>{{
            $convertDateToString(row.item.FileUploadedAt, "YYYY-MM-DD HH:mm")
          }}</label>
        </div>
      </template>
      <template #FileType="row">
        <div>
          <label>{{ row.item.FileName.split(".").pop().toUpperCase() }}</label>
        </div>
      </template>
      <template #DocumentName="row">
        <div style="text-align: left">
          <label>{{ row.item.DocumentName }}</label>
          <br />
          <label>{{
            Math.round((row.item.Size / 1000 / 1000) * 100) / 100 + " Mbyte"
          }}</label>
        </div>
      </template>
      <!-- TODO: parent adja meg a listát (pl. ügyfélnél otherDocCat, orvosnál docCat) -->
      <template #Category="row">
        {{ $t(categoryList[row.item.Category].Text) }}
      </template>
    </fp-table-list>
  </div>
</template>
<script>
export default {
  props: {
    items: Array,
    disabledCategory: Boolean,
    isSelectAll: Boolean,
    isSelectClear: Boolean,
    refId: String,
    fields: Array,
    hasCategoryFilter: Boolean,
  },
  data() {
    return {
      documentList: this.items,
      categoryList: this.$enums.DocumentCategory,
      isSelectedAll: this.isSelectAll,
      isSelectedClear: this.isSelectClear,
    };
  },
  watch: {
    items(input) {
      this.documentList = input;
    },
    isSelectAll(input) {
      this.isSelectedAll = input;
    },
    isSelectClear(input) {
      this.isSelectedClear = input;
    },
    isSelectedAll(input) {
      this.$emit("update:isSelectAll", input);
    },
    isSelectedClear(input) {
      this.$emit("update:isSelectClear", input);
    },
  },
  methods: {
    setDocumentCategoryFromEnums(category) {
      //console.log(category);
      const temp = this.$t("enums.DocumentCategory").find(
        (x) => x.Value == category
      );
      if (temp) {
        return temp.Text;
      } else {
        return category;
      }
    },
    onRowSelected(selectedItems) {
      this.$emit("onRowSelected", selectedItems);
    },
  },
};
</script>