export const HuPartner = {
  partner: {
    documents: {
      errorName: "dokumentum",
    },
    home: {
      dailyTask: "Napi teendők",
      partnerProgress: "Résztvevő előrehaladása a projektben",
      progress: "Haladás",
      projectProgress: "Projekt haladás",
      taskDescription: "Leírás",
      taskPriority: "Prioritás",
      taskStatus: "Státusz",
      taskTitle: "Teendő",
      taskType: "Típus",
      timeProgress: "Projekt időbeli előrehaladása",
    },
    logs: {
      table: {
        date: "Dátum",
        parameter: "Paraméter",
        result: "Eredmény",
        type: "Tevékenység",
        user: "Felhasználó",
      },
      title: "Tevékenységnapló",
    },
    menu: {
      documents: "Dokumentumtár",
      home: "Személyes főoldal",
      registrationParticipant: "Új résztvevő",
      templates: "Sablontár",
    },
    newProject: {
      controlPatientCount: "Kontrolbetegek száma",
      dataSheet: {
        title: "Adatlap",
      },
      participants: {
        addParticipant: "Résztvevő hozzáadása",
        deleteParticipants: "Résztvevők törlése",
        table: {
          fields: {
            name: "Résztvevő neve",
            role: "Projekt szerepkör",
            selected: "Kiválaszt",
          },
        },
        title: "Résztvevők",
      },
      roles: {
        addRole: "Szerepkör hozzáadása",
        deleteRoles: "Szerepkörök törlése",
        newRole: {
          baseRoles: {
            Doctor: "Orvos",
            Manager: "Menedzser",
            Patient: "Beteg",
          },
          permissions: {
            BaseData: "Alapadatok",
            Doctor: "Orvos",
            DoctorProgress: "Orvos haladás",
            DoctorRelation: "Orvos kapcsolat",
            Doctors: "Orvosok",
            Item: "Elem",
            Manager: "Menedzser",
            ManagerProgress: "Menedzser haladás",
            ManagerRelation: "Menedzser kapcsolat",
            Managers: "Menedzserek",
            OwnProgress: "Saját haladás",
            Patient: "Beteg",
            PatientProgress: "Beteg haladás",
            PatientRelation: "Beteg kapcsolat",
            Patients: "Betegek",
            Relation: "Kapcsolat",
            Research: "Kutatás",
            Subordinate: "Alárendelt",
            SubordinateDoctor: "Alárendelt orvos",
            SubordinateDoctorRelation: "Alárendelt orvos kapcsolat",
            SubordinateManager: "Alárendelt menedzser",
            SubordinateManagerRelation: "Alárendelt menedzser kapcsolat",
            SubordinatePatient: "Alárendelt beteg",
            SubordinatePatientRelation: "Alárendelt beteg kapcsolat",
            SubordinateProgress: "Alárendelt haladás",
            SubordinateRelation: "Alárendelt kapcsolat",
          },
          permissionTypes: {
            add: "Hozzáadás",
            edit: "Szerkesztés",
            remove: "Törlés",
            see: "Olvasás",
          },
        },
        permissions: "Engedélyek",
        table: {
          fields: {
            baseRoles: "Szerepkörök",
            name: "Név",
            permissions: "Engedélyek",
            selected: "Kiválaszt",
          },
        },
        title: "Szerepkörök",
      },
      testPatientCount: "Tesztbetegek száma",
      title: "Új Projekt",
    },
    participantList: {
      communicationTab: {
        call: "Hívás",
        chat: "Chat",
        communication: "Kommunikáció",
        communicationTypes: "Kommunikációs csatornák",
        email: "Email",
        emailAttachment: "Csatolmány",
        emailBody: "Email törzse",
        emailNew: "Új email",
        emailPriority: "Prioritás",
        emailSubject: "Tárgy",
        openButton: "Megjelenít",
        operations: "Műveletek",
        push: "Push",
        pushNew: "Új Push",
        pushPhoto: "Értesítés képe",
        pushText: "Értesítés szövege",
        pushTitle: "Értesítés címe",
        send: "Elküldés",
        sendMessages: "Üzenet küldés",
        sendTime: "Küldés időpontja",
        sms: "SMS",
        smsNew: "Új SMS",
        smsText: "SMS szövege",
        templateMessageTypes: "Elérhető sablonok",
        templateName: "Megnevezés",
        templateSupportedLanguages: "Elérhető nyelvek",
        type: "Típus",
      },
      documentsTab: {
        documentType: "Dokumentum típusa",
      },
      formsTab: {
        assignment: "Hozzárendelés",
        description: "Leírás",
        endDate: "Kitöltés időpontja",
        language: "Nyelv",
        newForm: "Új űrlap felvétele",
        operations: "Műveletek",
        state: "Űrlap állapota",
        title: "Űrlap címe",
      },
      newParticipantForm: {
        note: "Megjegyzések",
        projectRole: "Projekt szerepkör",
        requestResponseMessage: {
          successSave: "Sikeresen mentette az új résztvevőt a projektbe",
        },
        tags: "Címkék",
        username: "Résztvevő felhasználóneve",
      },
      participantList: {
        addNewParticipantToProject: "Résztvevő hozzáadása a projekthez",
        errorTagsName: "címkék",
        notBirthday: "Születési dátum nincs megadva",
        notFoundParticipant: "Nincs megjelenítendő résztvevő",
        tagFilter: "Címke szűrő",
      },
      participantTabs: {
        communication: "Kommunikáció",
        documents: "Dokumentumok",
        forms: "Résztvevői űrlapok",
        personalData: "Orvosi profil",
        projects: "Projektek",
        questionnaires: "Résztvevő kérdőívei",
        workflow: "Protokollok",
      },
      personalDataTab: {
        deleteFromProject: "Projektből törlés",
        editProfile: "Profil szerkesztés",
        editTag: "Címke szerkesztés",
        errorName: "orvosi profil",
      },
      questionnairesTab: {
        cancelButton: "Visszavonás",
        deadline: "Határidő",
        evaluatingButton: "Kiértékelés",
        fromDate: "Kiadás dátuma",
        giveAgain: "Ismételt kiadás",
        operations: "Műveletek",
        progress: "Kitöltöttség",
        questionnaire: "Kérdőív",
        requestResponseMessage: {
          successCancel: "Sikeres kérdőív visszavonás",
          successReassigned: "Sikeres kérdőív újra kiadás",
          successSave: "Sikeres mentés",
        },
        state: "Kérdőív állapota",
        warningButton: "Figyelmeztetés",
      },
      tag: {
        edit: "Módosítás",
        editTag: "Címkék módosítása",
        language: "Nyelv",
        newTag: "Új címke létrehozás",
        noTags: "Nincs megjeleníthető címke",
        own: "Saját",
        project: "Projekt",
        tagColor: "Címke színe",
        tagInfo: "Dupla kattintással szerkeszthető a meglévő címke",
        tagManager: "Címke kezelő",
        tagName: "Címke neve",
        tags: "Címkék",
      },
      title: "Résztvevők",
      workflowTab: {
        currentInProgress: "Lépés elvégzése folyamatban...",
        currentSteps: "Aktuálisan futó folyamat lépés(ek)",
        errorStartedName: "elindított folyamat",
        progress: "Haladás",
        stepWaiting: "Folyamat várakozik",
        workflow: "Protokoll",
      },
    },
    profile: {
      address: {
        building: "Házszám",
        city: "Város",
        latitude: "Szélesség",
        longitude: "Hosszúság",
        street: "Közterület neve és jellege",
        subBuilding: "További cím kiegészítés",
        zip: "Irányítószám",
      },
      chat: "Chat",
      communication: "Kommunikáció",
      companyName: "Intézmény név",
      departmentName: "Osztály név",
      email: "E-mail",
      emailAddress: "E-mail cím",
      name: "Név",
      phone: "Telefonszám",
      position: "Beosztás",
      push: "Push",
      sms: "SMS",
      title: "Profilom",
      username: "Felhasználónév",
    },
    projects: {
      controllGroup: "Kontroll csoport",
      controlPatientCount: "Ebből kontrolbeteg",
      dataSheet: "Adatlap",
      description: "Leírás",
      dontProject: "Nincs projekthez csatlakozva",
      filter: "Szűrő",
      institution: "Intézet",
      none: "Nincs",
      participants: "Résztvevők",
      patientIn: "Bevont betegek száma",
      privacy: "Titkosság",
      progress: "Haladás",
      projectGoal: "Projektcél",
      projectName: "Projektnév",
      projectTitle: "Projektcím",
      roles: "Szerepkörök",
      selectionCriteria: "Beválasztási kritériumok",
      specialistCount: "Bevont orvosok száma",
      specialistName: "Orvos neve",
      specialists: "Orvosok",
      state: "Állapot",
      status: "Állapot",
      tasksProgress: "Feladat haladás",
      testGroup: "Tesztcsoport",
      testPatientCount: "Ebből tesztbeteg",
      timestamp: "Időtartam",
      title: "Projekt",
    },
    questionnaires: {
      assignable: "Kiosztható kérdőívek",
      assignableTab: {
        createDate: "Létrehozás dátuma",
        errorName: "kioasztható kérdőív",
        fillTime: "Kitöltésre szánt idő",
        formCreatedDate: "Létrehozva",
        formDescription: "Leírás",
        formLang: "Nyelvek",
        formTitle: "Címe",
        giveQuestionnaries:
          "{count} kiválasztott résztvevőnek a kiválasztott kérdőív kiosztása",
        newdeadline: "Határidő",
        newdeadlineUnit: "NAP.HH:MM:SS",
        newFormByTask: "Kérdőív kiosztása feladatként",
        newFormHardDeadline:
          "Határidő lejáratával lezárja a feladat teljesítést",
        newSendDate: "Kiküldés dátuma",
        noParticipant: "Nincs megjeleníthető résztvevő",
        noTag: "Címkézetlen",
        participantName: "Résztvevő neve",
        participantNote: "Megjegyzés",
        supportedLanguages: "Elérhető nyelvek",
      },
      waitingForEvaluting: "Kiértékelésre váró kérdőívek",
      waitingTab: {
        deadline: "Határidő",
        filledDate: "Kitöltés dátuma",
        givenDate: "Kiosztás dátuma",
        participant: "Kitöltő résztvevő",
        progress: "Kitöltöttség",
        title: "Kérdőív címe",
      },
    },
    registrationParticipant: {
      accountData: "Fiók adatok",
      email: "Email cím",
      flags: "Fiók tulajdonság",
      name: "Név",
      password: "Jelszó",
      passwordAgain: "Jelszó ismétlése",
      role: "Szerepkör",
      username: "Felhasználónév",
    },
    research: {
      title: "Virtuális Kutató Tér",
    },
    tasks: {
      deadline: "Határidő",
      description: "Leírás",
      priority: "Prioritás",
      startDate: "Kezdési dátum",
      status: "Státusz",
      task: "Teendő",
      title: "Teendők",
      type: "Típus",
    },
    templates: {
      addEmail: "Email sablon hozzáadása",
      addPush: "PUSH sablon hozzáadása",
      addSMS: "SMS sablon hozzáadása",
      addToProject: "Sablon hozzáadása a projekthez",
      email: "Email sablon",
      emailSubject: "Tárgy",
      errorName: "sablon",
      lang: "Sablon nyelve",
      name: "Sablon megnevezése",
      newTemplate: "Új sablon felvétele",
      noTemplate: "Nincs megjeleníthető sablon",
      push: "Push sablon",
      pushImg: "Iconkép URL",
      pushMessage: "Üzenet",
      pushTitle: "Cím",
      removeEmail: "Email sablon eltávolítása",
      removePush: "Push sablon eltávolítása",
      removeSMS: "SMS sablon eltávolítása",
      sms: "SMS sablon",
      smsMessage: "Üzenet",
    },
    workflows: {
      title: "Protokolltár",
    },
  },
};
