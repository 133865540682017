const MenuLine = () => import("./MenuLine.vue");
const TitleLine = () => import("./TitleLine.vue");
const LangSwitch = () => import("./LangSwitch.vue");
const LoggedUser = () => import("./LoggedUser.vue");
const ProfilePicture = () => import("./ProfilePicture.vue");

export default {
  install(Vue) {
    Vue.component("fp-header-menu", MenuLine);
    Vue.component("fp-header-title-line", TitleLine);
    Vue.component("fp-header-lang-switch", LangSwitch);
    Vue.component("fp-header-logged-user", LoggedUser);
    Vue.component("fp-header-profile-picture-link", ProfilePicture);
  },
};
