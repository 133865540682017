import { ClientService, RequestMethod } from "../client-service";
import { RequestResponseLogic } from "../Ui/request-response";

const LEGAL = "/legal";
const LEGAL_UPDATE = "/legal/update";
const LEGAL_NEW_VERSION = "/legal/newcontentversion";
const LEGAL_STATEMENT = "/legal/statement";
const LEGAL_STATEMENTS = "/legal/statements";
const LEGAL_STATEMENT_ACCEPTED = "/legal/statement/accepted";
const LEGAL_STATEMENT_ACCEPTABLE = "/legal/statement/acceptable";

export const LegalLogic = {
  async createLegal(body, params) {
    let result = await ClientService.sendRequestWithBody(
      RequestMethod.POST,
      LEGAL,
      params,
      body
    );
    return await RequestResponseLogic.checkRequestResponse(result);
  },
  async getLegal(params) {
    let result = await ClientService.sendRequest(RequestMethod.GET, LEGAL, params);
    return await RequestResponseLogic.checkRequestResponse(result);
  },
  async setLegal(body, params, legalId) {
    let result = await ClientService.sendRequestWithBody(
      RequestMethod.PATCH,
      LEGAL,
      params + "/" + legalId,
      body
    );
    return await RequestResponseLogic.checkRequestResponse(result);
  },
  async deleteLegal(params, legalId) {
    let result = await ClientService.sendRequest(
      RequestMethod.DELETE,
      LEGAL,
      params + "/" + legalId
    );
    return await RequestResponseLogic.checkRequestResponse(result);
  },
  async updateLegal(body, params) {
    let result = await ClientService.sendRequestWithBody(
      RequestMethod.PATCH,
      LEGAL_UPDATE,
      params,
      body
    );
    return await RequestResponseLogic.checkRequestResponse(result);
  },
  async newLegalContentVersion(body, params) {
    let result = await ClientService.sendRequestWithBody(
      RequestMethod.POST,
      LEGAL_NEW_VERSION,
      params,
      body
    );
    return await RequestResponseLogic.checkRequestResponse(result);
  },
  async createStatementLegal(body, params) {
    let result = await ClientService.sendRequestWithBody(
      RequestMethod.POST,
      LEGAL_STATEMENT,
      params,
      body
    );
    return await RequestResponseLogic.checkRequestResponse(result);
  },
  async getStatementLegal(params) {
    let result = await ClientService.sendRequest(
      RequestMethod.GET,
      LEGAL_STATEMENTS,
      params
    );
    return await RequestResponseLogic.checkRequestResponse(result);
  },
  async getAcceptedStatementLegalByUser(params, targetId) {
    let result = await ClientService.sendRequest(
      RequestMethod.GET,
      LEGAL_STATEMENT_ACCEPTED + "/" + targetId,
      params
    );
    return await RequestResponseLogic.checkRequestResponse(result);
  },
  async getAcceptedStatementLegal(params) {
    let result = await ClientService.sendRequest(
      RequestMethod.GET,
      LEGAL_STATEMENT_ACCEPTED,
      params
    );
    return await RequestResponseLogic.checkRequestResponse(result);
  },
  async getAcceptableStatementLegalByUser(params, targetId) {
    let result = await ClientService.sendRequest(
      RequestMethod.GET,
      LEGAL_STATEMENT_ACCEPTABLE + "/" + targetId,
      params
    );
    return await RequestResponseLogic.checkRequestResponse(result);
  },
  async getAcceptableStatementLegal(params) {
    let result = await ClientService.sendRequest(
      RequestMethod.GET,
      LEGAL_STATEMENT_ACCEPTABLE,
      params
    );
    return await RequestResponseLogic.checkRequestResponse(result);
  },
  async deleteStatementLegal(params, statementId) {
    let result = await ClientService.sendRequest(
      RequestMethod.POST,
      LEGAL_STATEMENT + "/" + statementId,
      params
    );
    return await RequestResponseLogic.checkRequestResponse(result);
  },
};
