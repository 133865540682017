<template>
  <div class="eh-menu-content">
    <div v-if="project">
      <fp-tabs :lazy="true" :tabs="questionnaireTabs">
        <template #title="Component">{{ Component.Title }}</template>
        <template #content="Component">
          <component
            :is="Component.ComponentName"
            :projectItems="project.Items"
          />
        </template>
      </fp-tabs>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    project: Object,
  },
  data() {
    return {
      questionnaireTabs: [
        {
          ComponentName: 'fp-questionnaire-fillable-list',
          Title: this.$t("healthProfessional.questionnaires.own"),
        },
        {
          ComponentName: 'fp-form-fillable-list',
          Title: this.$t("healthProfessional.questionnaires.fillableForm"),
        },
        {
          ComponentName: 'fp-questionnaire-wait-evaluate-list',
          Title: this.$t(
            "healthProfessional.questionnaires.waitingForEvaluting"
          ),
        },
        {
          ComponentName: 'fp-questionnaire-assignable-list',
          Title: this.$t("healthProfessional.questionnaires.assignable"),
        },
      ],
    };
  },
  mounted() {
    this.$emit(
      "title-changed",
      this.$t("healthProfessional.menu.questionnaires")
    );
  },
};
</script>
