export const EnValidate = {
  validate: {
    checkMinMax: "Minimum: {min}, maximum: {max} can be marked",
    dateFormat: "The date must be in YYYY-MM-DD format",
    durationFormat: "Duration must be in HH: MM format",
    endDateNotBeforeEqualStart:
      "End date cannot be the same as or earlier than the start date.",
    endDateNotBeforeStart: "End date cannot be earlier than start date.",
    greaterThanZero: "You cannot enter a negative number.",
    heightFormat: "The height must be entered in meters and in 0.00 format",
    notMatchPassword: "The two passwords do not match",
    numberMinMax:
      "Minimum: {min}, maximum: {max} number value can be specified",
    requiredDate: "The date field is required in the appropriate format.",
    requiredDateTime:
      "The date and time fields are also required in the appropriate format.",
    requiredField: "Mandatory field.",
    targetDateFormat:
      "Target date must be in YYYY-MM-DD format, which cannot be earlier than the start date",
  },
};
