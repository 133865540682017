<template>
  <div style="display: flex; margin-bottom: 10px">
    <fp-svg-icon
      v-if="$productSettings.specialist.canCreateParticipant"
      v-b-tooltip.hover="'Új beteg regisztrációja és beléptetése a projektbe'"
      @click="showRegistration = true"
      style="cursor: pointer"
      icon="register-user"
    />
    <fp-create-project-participant
      v-model="showRegistration"
      @refreshList="$emi('selectOtherPatient')"
      :project="project"
    />
    <fp-svg-icon
      v-if="$productSettings.specialist.canAddParticipantToProject"
      v-b-tooltip.hover="'Regisztrált beteg beléptetése a projektbe'"
      @click="showAddToProjectModal = true"
      style="cursor: pointer"
      icon="add-user-from-list"
    />
    <!-- TODO: modal! -->
    <fp-svg-icon
      v-if="$productSettings.specialist.canCreateProjectRelation"
      v-b-tooltip.hover="
        'Regisztrált, projekten szereplő beteg kapcsolat felvétele'
      "
      @click="showCreateRelationModal = true"
      style="cursor: pointer"
      icon="add-user-relation"
    />
    <fp-create-project-relation
      v-model="showCreateRelationModal"
      :baseRole="baseRole"
      :project="project"
      @selectParticipant="$emi('selectOtherPatient', $event)"
    />
  </div>
</template>
<script>
export default {
  name: "NewParticipantToList",
  props: {
    baseRole: String,
    project: Object,
  },
  data() {
    return {
      showCreateRelationModal: false,
      showAddToProjectModal: false,
      showRegistration: false,
    };
  },
};
</script>
