/* COMPONENTS */
import HelperMethods from "../Logic/Ui/helperMethods";
import BaseComponents from "../BaseComponents";
import Dashboard from "../Modules/Dashboard";
import GDPR from "../Modules/GDPR";
import ParticipantList from "../Modules/ParticipantList";
import Profile from "../Modules/Profile";
import Header from "../Modules/Header";
import ChangePassword from "../Modules/ChangePassword";

import "../Style";

import Enums from "../Enums";

/* BOOTSTRAP */
import { BootstrapVue, IconsPlugin, BootstrapVueIcons } from "bootstrap-vue";
// css
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "bootstrap-vue/dist/bootstrap-vue-icons.min.css";

/* Syncfusion */
import { enableRipple } from "@syncfusion/ej2-base";
import { RichTextEditorPlugin } from "@syncfusion/ej2-vue-richtexteditor";
import { AccumulationChartPlugin } from "@syncfusion/ej2-vue-charts";
import { MultiSelectPlugin } from "@syncfusion/ej2-vue-dropdowns";

/* Markdown Editor */
import VueMarkdownEditor from "@kangc/v-md-editor";
import VMdPreview from "@kangc/v-md-editor/lib/preview";
import "@kangc/v-md-editor/lib/style/preview.css";
import "@kangc/v-md-editor/lib/style/base-editor.css";
import vuepressTheme from "@kangc/v-md-editor/lib/theme/vuepress.js";
import "@kangc/v-md-editor/lib/theme/style/vuepress.css";
import enUS from "@kangc/v-md-editor/lib/lang/en-US";

/* Clipper */
//<-- profilkép
import VuejsClipper from "vuejs-clipper";

/* Vue validator */
import SimpleVueValidation from "simple-vue-validator";

/* //VUEFORMULATE
import VueFormulate from "@braid/vue-formulate";
import { hu, en, de } from "@braid/vue-formulate-i18n"; */

// css
/* import "@braid/vue-formulate/dist/snow.css";
import "@braid/vue-formulate/dist/snow.min.css"; */

/* ICONS */
// css
/* import "../../Icons/icon-css-import.css"; */

export default {
  install(Vue) {
    /* COMOPNENTS */
    Vue.use(BaseComponents);
    Vue.use(Header);
    Vue.use(Dashboard);
    Vue.use(GDPR);
    Vue.use(Profile);
    Vue.use(HelperMethods);
    Vue.use(ParticipantList);
    Vue.use(ChangePassword);

    /* BOOTSTRAP */

    // Install plugin components
    Vue.use(BootstrapVue);
    // Install plugin icons
    Vue.use(IconsPlugin);
    // Install plugin vue icons
    Vue.use(BootstrapVueIcons);

    /* Syncfusion */
    enableRipple(true);

    Vue.use(RichTextEditorPlugin);
    Vue.use(AccumulationChartPlugin);
    Vue.use(MultiSelectPlugin);

    // Markdown Editor Config
    // Documentation: https://code-farmer-i.github.io/vue-markdown-editor/
    VueMarkdownEditor.use(vuepressTheme);
    VueMarkdownEditor.lang.use("en-US", enUS);
    VMdPreview.use(vuepressTheme);

    Vue.use(VueMarkdownEditor);
    Vue.use(VMdPreview);

    /* VUE CLIPPER */
    Vue.use(VuejsClipper);

    /* VUE VALIDATOR */
    Vue.use(SimpleVueValidation);

    //VUE PROTOTYPES
    Vue.prototype.$validator = SimpleVueValidation.Validator;
    Vue.prototype.$eventBus = new Vue();
    Vue.prototype.$enums = Enums;

    /* VUEFORMULATE */

    // Install plugin components
    /* Vue.use(VueFormulate, {
        plugins: [hu, en, de],
      }); */
  },
};
