export default {
  removeTemplateConfirm: "Biztosan törölni szeretné a sablont?",
  newTemplate: "Új sablon felvétele",
  emailTemplate: "Email sablon",
  smsTemplate: "SMS sablon",
  pushTemplate: "Push sablon",
  lang: "Sablon nyelve",
  title: "Sablon megnevezése",
  emailSubject: "Tárgy",
  message: "Üzenet",
  pushTitle: "Cím",
  pishIcon: "Iconkép URL",
};
