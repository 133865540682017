import { ClientService, RequestMethod } from '../client-service'
import { RequestResponseLogic } from '../Ui/request-response'

const BASE_DATA_API_PATH = '/base/'

//TODO: használni, enumba kiemelni?
export const BaseName = {
  MEDICINE: 'medicine',
  DISEASE: 'disease',
  DISEASEGUIDE: 'disease_guide',
  EXERCISE: 'exercise',
  HOSPITAL: 'hospital',
  HOSPITALWARD: 'hospital_ward',
  PHARMACY: 'pharmacy',
}

export const BaseDataLogic = {
  async getBaseData(baseName, params) {
    let result = await ClientService.sendRequest(RequestMethod.GET, BASE_DATA_API_PATH.concat(baseName), params)
    return await RequestResponseLogic.checkRequestResponse(result)
  },
}
