export const EnHealthProfessional = {
  healthProfessional: {
    home: {
      dailyTask: "Daily tasks",
      progress: "Progress",
      projectProgress: "Project progress",
      taskDescription: "Description",
      taskPriority: "Priority",
      taskStatus: "Status",
      taskTitle: "Task title",
      taskType: "Type",
      timeProgress: "Progress over time",
      doctorProgress: "Doctor's progress in the project",
    },
    menu: {
      appointments: "Appointments",
      calendar: "Calendar",
      documents: "Document library",
      home: "Personal home page",
      messages: "Messages",
      patientList: "Patient list",
      patientInvitation: "Beteg meghívás",
      project: "Project",
      questionnaires: "Questionnaire",
      tasks: "Things to do",
      workflow: "Processes",
      profile: "My profile",
      templates: "Template library",
    },
    patientList: {
      communicationTab: {
        communication: "Communication",
        call: "Call",
        chat: "Chat",
        email: "E-mail",
        emailNew: "New email",
        emailSubject: "Subject",
        emailBody: "Email body",
        emailPriority: "Priority",
        emailAttachment: "Attachment",
        smsNew: "New SMS",
        smsText: "SMS text",
        pushNew: "New push",
        pushTitle: "Notification title",
        pushText: "Notification text",
        pushPhoto: "Notification picture",
        push: "Push",
        sms: "SMS",
        openButton: "View",
        operations: "Operations",
        type: "Type",
        sendTime: "Date of sending",
        communicationTypes: "Communication types",
        sendMessages: "Send a message",
        templateName: "Template name",
        templateSupportedLanguages: "Available languages",
        templateMessageTypes: "Available templates",
        send: "Send",
      },
      diagramsTab: {
        selectChart: "Select a chart type",
      },
      formsTab: {
        assignment: "Assignment",
        description: "Description",
        endDate: "Date of completion",
        language: "Language",
        newForm: "Add a new form",
        operations: "Operations",
        state: "Form status",
        title: "Form title",
        errorFormName: "form",
        errorAssignName: "distributable forms",
        filledDate: "Date completed",
      },
      newPatientForm: {
        patient: "Patient",
        note: "Remarks",
        projectRole: "Project role",
        tags: "Tags",
        errorName: "user",
      },
      patientList: {
        addNewPatientToProject: "Add a patient to the project",
        notBirthday: "Date of birth not specified",
        notFoundPatient: "There are no patients to display",
        tagFilter: "Label filter",
        errorTagsName: "tags",
      },
      patientTabs: {
        charts: "Diagrams",
        communication: "Communication",
        documents: "Documents",
        forms: "Medical forms",
        medicalData: "Medical profile",
        personalData: "Personal profile",
        questionnaires: "Questionnaires",
        workflow: "Processes",
      },
      personalDataTab: {
        deleteFromProject: "Delete from project",
        editTag: "Label editing",
        editProfile: "Edit profile",
        errorName: "personal profile",
      },
      medicalDataTab: {
        editProfile: "Edit profile",
        errorName: "medical profile",
      },
      questionnairesTab: {
        cancelButton: "Withdrawal",
        deadline: "Deadline",
        evaluatingButton: "Evaluation",
        fromDate: "Release Date",
        giveAgain: "Reissue",
        operations: "Operations",
        progress: "Occupancy",
        questionnaire: "Questionnaire",
        state: "Questionnaire status",
        warningButton: "Warning",
        errorName: "questionnaire",
      },
      tag: {
        edit: "Amendment",
        editTag: "Change labels",
        newTag: "Create a new label",
        noTags: "There are no label to display yet",
        tagColor: "Label color",
        tagInfo: "Double-click to edit an existing label",
        tagManager: "Label manager",
        tagName: "Label name",
        tags: "Tags",
        language: "Language",
        project: "Project",
        own: "Own",
      },
      workflowTab: {
        workflow: "Process",
        progress: "Progress",
        currentSteps: "Currently running process step(s)",
        currentInProgress: "Performing step...",
        stepWaiting: "Process pending",
        errorStartedName: "process started",
      },
      userSetting: {
        relationshipType: "Connection type",
        relationshipRemark: "Contact note",
        projectRemark: "Project note",
      },
      documentsTab: {
        documentType: "Document type",
      },
    },
    patientManager: {
      patientSignUp: {
        accountData: "Account information",
        birthday: "Date of birth",
        birthplace: "Place of birth",
        email: "E-mail address",
        name: "Name",
        password: "Password",
        passwordAgain: "Repeat password",
        phone: "Phone number",
        role: "Role",
        sex: "Sex",
        userBaseData: "Basic information",
        username: "Username",
        flags: "Account property",
        newPatientDoctorRelationship:
          "Create a new user patient-physician relationship",
        relationshipType: "Connection type",
        tags: "Tags",
        relationshipRemark: "Remark relationship",
        newPatientToProject: "Add a new user to the project",
        projectRole: "Project role",
        tagsInProject: "Labels within a project",
        projectRemark: "Remark project",
      },
      manager: {
        newPatientSignUp: "Admission of a new patient",
        userInvitation: "Invite a user",
        patientInvitations: "Patient invitations",
        status: "Status",
        targetName: "Invited name",
        parameters: "Parameters",
        timestamp: "Send invitation",
      },
      userInvitation: {
        registedUserInvitation: "Invite a registered user as a patient",
        user: "User",
        errorName: "user",
      },
    },
    profile: {
      antszCode: "Department code",
      chat: "Chat",
      communication: "Communication",
      email: "E-mail",
      emailAddress: "E-mail address",
      institutionName: "Institution name",
      name: "Name",
      phone: "Phone number",
      position: "Position",
      push: "Push",
      registrationNumber: "Stamp number",
      sms: "SMS",
      username: "Username",
      wardName: "Ward name",
      profileDelete: "Delete user account permanently",
      errorName: "Profile",
    },
    projects: {
      blind: "Nature of investigation",
      controllGroup: "Control group",
      controllPatientCount: "Of this, he is a control patient",
      dataSheet: "Datasheet",
      description: "Description",
      dontProject: "Not connected to a project",
      doubleBlind: "Double blind test",
      noneBlind: "Public",
      patientIn: "Number of patients involved",
      patientName: "Patient's name",
      patientSsn: "Patient SSN number",
      patientBirth: "Patient's date of birth",
      patients: "Patients",
      progress: "Progress",
      projectGoal: "Project goal",
      projectName: "Project name",
      projectTitle: "Project title",
      selectionCriteria: "Selection criteria",
      simpleBlind: "Single blind test",
      state: "State",
      tasksProgress: "Task progress",
      testGroup: "Test group",
      testPatientCount: "Of this, he is a test patient",
      timestamp: "Period",
      projectTimeProgress: "Project progress over time",
      doctorTimeProgress: "Doctor progressing on the project",
      currentProject: "current project",
      person: "people",
    },
    questionnaires: {
      own: "Own questionnaires",
      ownTab: {
        title: "Questionnaire",
        description: "Description",
        percentage: "Occupancy",
        addDate: "Date added",
        completedDate: "Date completed",
        status: "Questionnaire status",
        errorFillableName: "questionnaire to be completed",
      },
      assignable: "Assignable questionnaires",
      assignableTab: {
        newdeadline: "Deadline",
        giveQuestionnaries:
          "Distribute the selected questionnaire to the selected {count} patient(s)",
        patientBirthday: "Patient's date of birth",
        patientName: "Patient's name",
        noPatient: "There are no patients to display",
        patientNote: "Note",
        patientSsn: "Patient's SSN",
        formCreatedDate: "Created",
        formDescription: "Description",
        formTitle: "Title",
        newSendDate: "Date of dispatch",
        newFormByTask: "Assign a questionnaire as a task",
        newFormHardDeadline: "Close the task on expiration",
        noTag: "Unlabeled",
        createDate: "Creation date",
        fillTime: "Time to complete",
        newdeadlineUnit: "DAY.HH:MM:SS",
        supportedLanguages: "Available languages",
        errorName: "assignable questionnaire",
      },
      waitingForEvaluting: "Questionnaires awaiting evaluation",
      waitingTab: {
        deadline: "Deadline",
        description: "Description",
        evaluating: "Evaluation",
        filledDate: "Date completed",
        givenDate: "Date of allocation",
        patient: "Filling patient",
        patientBirth: "Patient's date of birth",
        patientSsn: "Patient's SSN",
        progress: "Occupancy",
        title: "Questionnaire title",
        evaulate: "Evaluation",
        errorWaitingName: "questionnaire awaiting evaluation",
        evaulating: {
          longDescription: "Evaluation at length",
          note: "Comment",
          point: " point",
          shortDescription: "Evaluation in brief",
          summary: "Total score: ",
          title: "Questionnaire evaluation",
        },
      },
      fillableForm: "Patient Assigned Forms",
      fillableFormTab: {
        patient: "Patient data",
        title: "Address",
        description: "Description",
        percentage: "Progress",
        startDateTime: "Date added",
        stopDateTime: "Completion Date",
        status: "Status",
        errorName: "Questionnaire to be completed",
      },
      questionnaireFill: "Fill questionnaire",
      formFill: "Fill form",
      userData: "Assigned user",
      name: "Name",
      ssn: "SSN number",
      birthday: "Date of birth",
      address: "Address",
      errorPatientName: "Patient",
    },
    tasks: {
      deadline: "Deadline",
      description: "Description",
      priority: "Priority",
      startDate: "Beginning date",
      status: "Status",
      task: "Task title",
      type: "Type",
      errorUserName: "User",
      errorStartableName: "Startable process",
    },
    workflow: {
      startWorkflow: "Starting a process",
    },
    appointment: {
      filterDescription: "Search in title, description, max. number of places.",
      date: "Date",
      inProgress: "In progress",
      person: "{count} people",
      addParticipant: "Add participant",
      participants: "Participants",
      clear: "Cancel appointment",
      view: "Appearance",
      participantsData: "Participant details",
      removeParticipant: "Remove participant",
      saveView: "Save appearance",
      newParticipnat: "Add new participant",
      users: "Users",
      title: "Title",
      description: "Description",
      startTime: "Starting time",
      endTime: "Ending time",
      visitorsCount: "Number of places",
      location: "Location",
      errorName: "Appointment",
      client: "Client",
    },
    documents: {
      documentType: "Document type",
      errorName: "Document",
    },
    templates: {
      errorName: "template",
      newTemplate: "Add a new template",
      noTemplate: "No template to display",
      lang: "Template language",
      name: "Name of the template",
      addEmail: "Add E-mail template",
      addSMS: "Add SMS template",
      addPush: "Add PUSH template",
      email: "Email template",
      sms: "SMS template",
      push: "Push template",
      emailSubject: "Subject",
      removeEmail: "Remove email template",
      smsMessage: "Message",
      removeSMS: "Remove SMS template",
      pushTitle: "Title",
      pushMessage: "Message",
      pushImg: "Icon image URL",
      removePush: "Remove Push template",
      addToProject: "Template added to project",
    },
  },
};
