import AuthUiLogic from "./Ui/auth.js";

export const RequestMethod = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  PATCH: "PATCH",
  DELETE: "DELETE",
};

export const ClientService = {
  async sendRequestBasicAuth(method, path, params, username, password) {
    // creating URL
    var url = new URL(
      process.env.VUE_APP_EHSS_API_PREFIX.concat(path),
      process.env.VUE_APP_EHSS_API_URL
    );
    // if the params object is not null
    if (params != null) {
      // adding query parameters to the string
      Object.entries(params).forEach(([key, value]) => {
        if (value !== undefined && value != null) {
          url.searchParams.append(key, params[key]);
        }
      });
    }
    try {
      const fetchOptions = {
        method: method,
        headers: {
          Authorization:
            "Basic " +
            Buffer.from(username.trim() + ":" + password).toString("base64"),
        },
      };
      // starting the request
      //console.log("URL", url);
      const response = await fetch(url, fetchOptions);
      const curl = this.convertCurl(url, fetchOptions);
      // returning the promise
      return { Response: response, Curl: curl, Headers: response.headers };
    } catch (e) {
      return { Code: 999, Message: e.toString() };
    }
  },

  async sendRequestWithoutAuth(method, path, params) {
    // creating URL
    var url = new URL(
      process.env.VUE_APP_EHSS_API_PREFIX.concat(path),
      process.env.VUE_APP_EHSS_API_URL
    );
    // if the params object is not null
    if (params != null) {
      // adding query parameters to the string
      Object.entries(params).forEach(([key, value]) => {
        if (value !== undefined && value != null) {
          url.searchParams.append(key, params[key]);
        }
      });
    }
    try {
      const fetchOptions = {
        method: method,
        headers:{}
      };
      // starting the request
      const response = await fetch(url, fetchOptions);

      const curl = this.convertCurl(url, fetchOptions);
      if (response.status == 401) {
        const body = await response.clone().body.getReader();
        const hasBody = await body.read();
        if (!hasBody.value) {
          this.clearPreviuosLoggedUser();
        }
      }
      // returning the promise
      return { Response: response, Curl: curl, Headers: response.headers };
    } catch (e) {
      return { Code: 999, Message: e.toString() };
    }
  },

  async sendRequest(method, path, params) {
    const token = await AuthUiLogic.canStartRequest();
    if (token) {
      // creating URL
      var url = new URL(
        process.env.VUE_APP_EHSS_API_PREFIX.concat(path),
        process.env.VUE_APP_EHSS_API_URL
      );
      // if the params object is not null
      if (params != null) {
        // adding query parameters to the string
        Object.entries(params).forEach(([key, value]) => {
          if (value !== undefined && value != null) {
            url.searchParams.append(key, params[key]);
          }
        });
      }
      try {
        const fetchOptions = {
          method: method,
          headers: {
            Authorization: "Bearer ".concat(token.Value),
            //"Accept-Language": i18n.locale,
          },
        };
        // starting the request
        const response = await fetch(url, fetchOptions);

        const curl = this.convertCurl(url, fetchOptions);
        if (response.status == 401) {
          const body = await response.clone().body.getReader();
          const hasBody = await body.read();
          if (!hasBody.value) {
            this.clearPreviuosLoggedUser();
          }
        }
        // returning the promise
        return { Response: response, Curl: curl, Headers: response.headers };
      } catch (e) {
        return { Code: 999, Message: e.toString() };
      }
    } else {
      this.clearPreviuosLoggedUser();
    }
  },

  async sendRequestWithBody(method, path, params, body) {
    const token = await AuthUiLogic.canStartRequest();
    if (token) {
      // creating URL
      var url = new URL(
        process.env.VUE_APP_EHSS_API_PREFIX.concat(path),
        process.env.VUE_APP_EHSS_API_URL
      );
      // if the params object is not null
      if (params != null) {
        // adding query parameters to the string
        Object.entries(params).forEach(([key, value]) => {
          if (value !== undefined && value != null) {
            url.searchParams.append(key, params[key]);
          }
        });
      }
      //console.log(url.toString())
      // starting the request
      try {
        const fetchOptions = {
          method: method,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer ".concat(token.Value),
            //"Accept-Language": i18n.locale,
          },
          body: JSON.stringify(body, (key, value) => {
            if (typeof value === "string" && value == "") {
              return null;
            } else if (typeof value == "string") {
              return value.trim();
            }
            return value;
          }),
        };

        const response = await fetch(url, fetchOptions);

        const curl = this.convertCurl(url, fetchOptions);

        if (response.status == 401) {
          const body = await response.clone().body.getReader();
          const hasBody = await body.read();
          if (!hasBody.value) {
            this.clearPreviuosLoggedUser();
          }
        }
        // returning the promise
        return { Response: response, Curl: curl, Headers: response.headers };
      } catch (e) {
        return { Code: 999, Message: e.toString() };
      }
    } else {
      this.clearPreviuosLoggedUser();
    }
  },

  async sendRequestWithBodyWithoutAuth(method, path, params, body) {
      // creating URL
      var url = new URL(
        process.env.VUE_APP_EHSS_API_PREFIX.concat(path),
        process.env.VUE_APP_EHSS_API_URL
      );
      // if the params object is not null
      if (params != null) {
        // adding query parameters to the string
        Object.entries(params).forEach(([key, value]) => {
          if (value !== undefined && value != null) {
            url.searchParams.append(key, params[key]);
          }
        });
      }
      //console.log(url.toString())
      // starting the request
      try {
        const fetchOptions = {
          method: method,
          headers: {
            "Content-Type": "application/json",
            //"Accept-Language": i18n.locale,
          },
          body: JSON.stringify(body, (key, value) => {
            if (typeof value === "string" && value == "") {
              return null;
            } else if (typeof value == "string") {
              return value.trim();
            }
            return value;
          }),
        };

        const response = await fetch(url, fetchOptions);

        const curl = this.convertCurl(url, fetchOptions);

        if (response.status == 401) {
          const body = await response.clone().body.getReader();
          const hasBody = await body.read();
          if (!hasBody.value) {
            this.clearPreviuosLoggedUser();
          }
        }
        // returning the promise
        return { Response: response, Curl: curl, Headers: response.headers };
      } catch (e) {
        return { Code: 999, Message: e.toString() };
      }
  },

  async sendRequestWithFile(method, path, params, content) {
    const token = await AuthUiLogic.canStartRequest();
    if (token) {
      // creating URL
      var url = new URL(
        process.env.VUE_APP_EHSS_API_PREFIX.concat(path),
        process.env.VUE_APP_EHSS_API_URL
      );
      // if the params object is not null
      if (params != null) {
        // adding query parameters to the string
        Object.entries(params).forEach(([key, value]) => {
          if (value !== undefined && value != null) {
            url.searchParams.append(key, params[key]);
          }
        });
      }
      try {
        const fetchOptions = {
          method: method,
          headers: {
            "Content-Type": "text/plain",
            Authorization: "Bearer " + token.Value,
            //"Accept-Language": i18n.locale,
          },
          body: content,
        };
        // starting the request
        const response = await fetch(url, fetchOptions);
        /* if (response.status == 401) {
            this.clearPreviuosLoggedUser()
          } */
        // returning the promise
        //console.log("sendRequestWithFile", response);
        const curl = this.convertCurl(url, fetchOptions, false);
        return { Response: response, Curl: curl, Headers: response.headers };
      } catch (e) {
        return { Code: 999, Message: e.toString() };
      }
    } else {
      this.clearPreviuosLoggedUser();
    }
  },

  convertCurl(url, options, isBodyJSON = true) {
    let curl = "curl '" + url + "'\\\n-X '" + options.method + "'\\\n";
    curl += "-H 'Authorization: " + options.headers.Authorization + "'\\\n";
    curl += "-H 'Origin: " + location.origin + "'\\\n";
    curl += "-H 'Referer: " + document.referrer + "'\\\n";
    curl += "-H 'User-Agent: " + navigator.userAgent + "'\\\n";
    curl += "-H 'sec-ch-ua-platform: " + navigator.platform + "'\\\n";
    if (options.headers["Accept-Language"]) {
      curl +=
        "-H 'Accept-Language: " + options.headers["Accept-Language"] + "'\\\n";
    }
    if (options.headers["Content-Type"]) {
      curl += "-H 'Content-Type: " + options.headers["Content-Type"] + "'\\\n";
    }
    if (options.body && isBodyJSON) {
      curl +=
        "--data-raw\n'" + JSON.stringify(JSON.parse(options.body)) + "'\\\n";
    }
    curl += "--compressed \n\n";
    //console.log(curl);
    return curl;
  },

  clearPreviuosLoggedUser() {
    sessionStorage.clear();
    localStorage.clear();
    //router.push({ name: "Login" });
  },
};
