const EesztRssReader = () => import("./EesztRssReader.vue");
const DailyTask = () => import("./DailyTask.vue");
const ProjectProgress = () => import("./ProjectProgress.vue");

export default {
  install(Vue) {
    //Modul komponensek regisztrálása
    Vue.component("fp-eeszt-rss-reader", EesztRssReader);
    Vue.component("fp-daily-task", DailyTask);
    Vue.component("fp-project-progress", ProjectProgress);
  },
};
