<template>
  <div class="registration-login-background">
    <div class="page-background-shape">
      <div id="registration-box">
        <b-row cols="1">
          <div>
            <img
              id="registration-logo-img"
              src="@/assets/logo/fp/fp-logo-v.png"
            />
          </div>
          <!-- <div class="e-input-group e-float-input e-outline">
            <input class="input-area" type="text" required />
            <span class="e-float-line"></span>
            <label class="e-float-text">Név</label>
          </div> -->
          <div style="min-width: 300px; padding: 0px 10px">
            <fp-input textStyle="light" label="Név" />
          </div>
          <!-- <div class="e-input-group e-float-input e-outline">
            <input class="input-area" type="text" required />
            <span class="e-float-line"></span>
            <label class="e-float-text">Email cím</label>
          </div> -->
          <div style="min-width: 300px; padding: 0px 10px">
            <fp-input textStyle="light" label="Email cím" type="email" />
          </div>
          <!-- <div class="e-input-group e-float-input e-outline">
            <input class="input-area" type="password" required />
            <span class="e-float-line"></span>
            <label class="e-float-text">Jelszó</label>
          </div> -->
          <div style="min-width: 300px; padding: 0px 10px">
            <fp-input textStyle="light" label="Jelszó" type="password" />
          </div>
          <!-- <div class="e-input-group e-float-input e-outline">
            <input class="input-area" type="password" required />
            <span class="e-float-line"></span>
            <label class="e-float-text">Jelszó megerősítése</label>
          </div> -->
          <div style="min-width: 300px; padding: 0px 10px">
            <fp-input
              textStyle="light"
              label="Jelszó megerősítése"
              type="password"
            />
          </div>
          <b-row cols="2">
            <!-- <div style="margin: 10px 0px; width: 48%">
              <ejs-dropdownlist
                :dataSource="sexList"
                :fields="localFields"
                cssClass="e-outline"
                placeholder="Nem"
              ></ejs-dropdownlist>
            </div> -->
            <div style="min-width: 300px; padding: 0px 5px">
              <fp-select
                textStyle="light"
                label="Nem"
                :items="sexList"
                textKey="Name"
                valueKey="id"
              />
            </div>
            <!-- <div
              style="margin: 10px 0px 10px 10px; width: 48%"
              class="e-input-group e-float-input e-outline"
            >
              <input
                class="input-area"
                type="text"
                onfocus="(this.type='date')"
                onblur="(this.type='text')"
                required
              />
              <span class="e-float-line"></span>
              <label class="e-float-text">Születési dátum</label>
            </div> -->
            <div style="min-width: 300px; padding: 0px 5px">
              <fp-date-picker textStyle="light" label="Születési dátum" />
            </div>
          </b-row>
          <ejs-button class="main-button" id="registration-registration-button"
            >Regisztráció</ejs-button
          >
        </b-row>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Registration",
  components: {},
  data() {
    return {
      sexList: [
        { id: 1, Name: "Nő" },
        { id: 2, Name: "Férfi" },
      ],
      localFields: { text: "Name", value: "id" },
    };
  },
};
</script>
<style>
@import "../../styles/syncfusion-radio-button.css";
@import "../../styles/syncfusion-input.css";
@import "../../styles/syncfusion-dropdown-list.css";

#registration-box {
  width: 50%;
  margin: auto;
}
@media screen and (max-width: 400px) {
  #registration-box {
    width: 90%;
  }
}
#registration-logo-img {
  left: 50%;
  position: relative;
  transform: translate(-50%, 0);
  margin: 50px 0px;
  height: 150px;
}
</style>