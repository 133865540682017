var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.form)?_c('div',{staticStyle:{"margin":"10px 0px"}},[_c('div',{staticClass:"h3",staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s(_vm.form.Title ? _vm.form.Title[_vm.language] : null)+" ")]),_c('div',{staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s(_vm.form.Description ? _vm.form.Description[_vm.language] : null)+" ")]),_c('div',{staticStyle:{"display":"none"}},[_c('input',{attrs:{"id":"fp-form-import-input","type":"file"},on:{"input":_vm.selectFile}})]),_c('form-schema-inputs',{key:'form-schema-input-' + _vm.forceUpdateCount,attrs:{"component":_vm.form.UiSchema,"evaluateObjectModel":_vm.evaluation.ScoreObjectModel,"disabled":_vm.disabled,"scoreable":_vm.scoreable,"language":_vm.language},on:{"evaluating":function($event){return _vm.$set(_vm.evaluation, 'ScoreObjectModel', $event)}},model:{value:(_vm.form.ObjectModel),callback:function ($$v) {_vm.$set(_vm.form, "ObjectModel", $$v)},expression:"form.ObjectModel"}}),_c('form-schema-inputs'),(
      _vm.scoreable ||
        (_vm.form.EvaluationMethod == _vm.$enums.EvaluationMethod.Manual && _vm.disabled)
    )?_c('div',[_vm._v(" "+_vm._s(_vm.$t("components.formEvaulating.manualEvaluation"))+" "),_c('fp-input',{attrs:{"disabled":!_vm.scoreable,"label":_vm.$t('components.formEvaulating.short')},model:{value:(_vm.evaluation.ShortDescription),callback:function ($$v) {_vm.$set(_vm.evaluation, "ShortDescription", $$v)},expression:"evaluation.ShortDescription"}}),_c('fp-text-area',{attrs:{"disabled":!_vm.scoreable,"label":_vm.$t('components.formEvaulating.long')},model:{value:(_vm.evaluation.LongDescription),callback:function ($$v) {_vm.$set(_vm.evaluation, "LongDescription", $$v)},expression:"evaluation.LongDescription"}}),_c('fp-text-area',{attrs:{"disabled":!_vm.scoreable,"label":_vm.$t('components.formEvaulating.note')},model:{value:(_vm.evaluation.Note),callback:function ($$v) {_vm.$set(_vm.evaluation, "Note", $$v)},expression:"evaluation.Note"}})],1):_vm._e(),(
      _vm.disabled &&
        _vm.form.Evaluation &&
        _vm.form.EvaluationMethod == _vm.$enums.EvaluationMethod.Auto &&
        _vm.form.Evaluation.ScoreAchived &&
        _vm.form.Evaluation.ScoreRangeNotification
    )?_c('div',[_c('fp-text-area',{attrs:{"disabled":true,"label":_vm.$t('components.formEvaulating.autoResult'),"value":_vm.getAutoEvaluation()}})],1):_vm._e(),(_vm.form.UiSchema.Buttons && (!_vm.disabled || (_vm.disabled && _vm.scoreable)))?_c('div',{staticStyle:{"text-align":"center"}},_vm._l((_vm.form.UiSchema.Buttons),function(button){return _c('b-button',{key:button.VariableName,staticStyle:{"margin":"0px 10px"},attrs:{"disabled":_vm.disabled && !_vm.scoreable},on:{"click":function($event){return _vm.functionMethod(button.Function)}}},[_vm._v(_vm._s(button.Label ? button.Label[_vm.language] : null))])}),1):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }