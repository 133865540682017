import Vue from "vue";
import VueI18n from "vue-i18n";

import hu from "./Hu";
import en from "./En";
import de from "./De";

import localDictionary from "./oldDictionary";

Vue.use(VueI18n);

const dictionaries = {
  hu: {
    ...hu,
    ...localDictionary.hu,
  },
  de: {
    ...de,
    ...localDictionary.de,
  },
  en: {
    ...en,
    ...localDictionary.en,
  },
};

const fallbackLocales = {
  hu: ["hu", "HUN", "hu-HU", "hu-hu", "hu-HUN"],
  de: [
    "de",
    "deu",
    "DEU",
    "de-BE",
    "de-DE",
    "de-LI",
    "de-LU",
    "de-CH",
    "de-be",
    "de-de",
    "de-li",
    "de-lu",
    "de-ch",
  ],
};
function getLocale() {
  var browseLocale = "hu";
  Object.entries(fallbackLocales).forEach(([loc, fallbackLocs]) => {
    const storageLang = sessionStorage.getItem(
      "FP_LL"
    );
    if (storageLang) {
      browseLocale = storageLang;
    } else if (
      fallbackLocs.includes(navigator.language || navigator.userLanguage)
    ) {
      browseLocale = loc;
    }
  });
  return browseLocale;
}

const i18n = new VueI18n({
  locale: getLocale(),
  messages: dictionaries,
});
console.log(dictionaries);
export default i18n;
