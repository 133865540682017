import { render, staticRenderFns } from "./Long.vue?vue&type=template&id=11a9f4a0&"
import script from "./Long.vue?vue&type=script&lang=js&"
export * from "./Long.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../Projects/register/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports