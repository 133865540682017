export const HuValidate = {
  validate: {
    checkMinMax: "Minimum: {min}, maximum: {max} lehet megjelölni",
    dateFormat: "A dátumot YYYY-MM-DD formátumba kell megadni",
    durationFormat: "Az időtartam formátuma HH:MM kell lennie",
    endDateNotBeforeEqualStart:
      "A befejezés dátuma nem lehet megegyező vagy korábbi, mint a kezdési dátum.",
    endDateNotBeforeStart:
      "A befejezés dátuma nem lehet előbbi mint a kezdési dátum.",
    greaterThanZero: "Nem lehet negatív számot megadni.",
    heightFormat: "A magasságot méterben és 0.00 formátumban kell megadni",
    notMatchPassword: "Nem egyezik meg a két jelszó",
    numberMinMax: "Minimum: {min}, maximum: {max} számértéket lehet megadni",
    requiredDate: "A dátum mező kitöltése kötelező a megfelelő formátumban.",
    requiredDateTime:
      "A dátum és az időpont mező kitöltése is kötelező a megfelelő formátumban.",
    requiredField: "Kötelező mező.",
    targetDateFormat:
      "A cél dátum YYYY-MM-DD formátumba kell megadni, ami nem lehet korábbi a kezdési dátumnál",
  },
};
