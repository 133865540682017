import Vue from "vue";
import { EhssClient, RequestMethod } from "@/backend/ehss-client";
import { StoreManager } from "@/storage/store-manager";
import { RequestResponseLogic } from "@/logic/ui/request-response";

const USER = "/user";
const BASE_DATA = "base_data";
const USER_PASSWORD = "/user/change_password";

const SIGNED_USER_DATASTORE_KEY = process.env.VUE_APP_LOGGED_USER_STORAGE_KEY;

export const UserLogic = {
  //User adatok lekérdezése
  async getUser(params) {
    let result = await EhssClient.sendRequest(RequestMethod.GET, USER, params);
    return await RequestResponseLogic.checkRequestResponse(result);
  },
  async getUserData(params) {
    let result = await EhssClient.sendRequest(
      RequestMethod.GET,
      USER + "/" + BASE_DATA,
      params
    );
    return await RequestResponseLogic.checkRequestResponse(result);
  },
  //új user létrehozása
  async createUser(body, params) {
    let result = await EhssClient.sendRequestWithBody(
      RequestMethod.POST,
      USER,
      params,
      body
    );
    return await RequestResponseLogic.checkRequestResponse(result);
  },

  async setUser(userId, body, params) {
    let result = await EhssClient.sendRequestWithBody(
      RequestMethod.PATCH,
      USER + "/" + userId,
      params,
      body
    );
    return await RequestResponseLogic.checkRequestResponse(result);
  },
  //user törlés
  async deleteUser(userId, params) {
    let result = await EhssClient.sendRequest(
      RequestMethod.DELETE,
      USER + "/" + userId,
      params
    );
    return await RequestResponseLogic.checkRequestResponse(result);
  },

  async changePassword(body, params) {
    let result = await EhssClient.sendRequestWithBody(
      RequestMethod.POST,
      USER_PASSWORD,
      params,
      body
    );
    return await RequestResponseLogic.checkRequestResponse(result);
  },

  //TODO: local storageba menteni és bármikor vissza kérdezhető legyen
  //User adatok mentése local storage-ba
  saveLoginUser(data) {
    StoreManager.sessionStoragePut(SIGNED_USER_DATASTORE_KEY, data);
    StoreManager.localStoragePut(SIGNED_USER_DATASTORE_KEY, data);
    Vue.prototype.$loggedUser = data;
  },
  //User adatok lekérdezése local storage-ból
  getSignedUser() {
    var signedUser = StoreManager.sessionStorageGet(SIGNED_USER_DATASTORE_KEY);
    if (!signedUser) {
      signedUser = StoreManager.localStorageGet(SIGNED_USER_DATASTORE_KEY);
    }
    return signedUser;
  },
  //User adatok törlése local storage-ból
  deleteSignedUser() {
    Vue.prototype.$loggedUser = null;
    StoreManager.sessionStorageDelete(SIGNED_USER_DATASTORE_KEY);
    StoreManager.localStorageDelete(SIGNED_USER_DATASTORE_KEY);
  },
};
