export default {
  superior: "Felettes",
  subordinate: "Alárendelt",
  superiors: "Felettesek",
  subordinates: "Alárendeltek",
  editRelation: "Résztvevő kapcsolatok szerkesztése",
  editParticipant: "Résztvevő szerkesztése",
  removeProjectRelation: "Résztvevő kapcsolat törlése",
  removeFromProject: "Résztvevő törlése a projektről",
  deleteParticipant: "Résztvevő felhasználó törlése",
};
