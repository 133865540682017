import ClientMedicalProfile from "./ClientMedicalProfile.vue";
import ClientPersonalProfile from "./ClientPersonalProfile.vue";
import HealthProfessionalProfile from "./HealthProfessionalProfile.vue";
import PartnerProfile from "./PartnerProfile.vue";

export default {
  install(Vue) {
    Vue.component("fp-client-medical-profile", ClientMedicalProfile);
    Vue.component("fp-client-personal-profile", ClientPersonalProfile);
    Vue.component("fp-health-professional-profile", HealthProfessionalProfile);
    Vue.component("fp-partner-profile", PartnerProfile);
  },
};
