<template>
  <div v-if="!params.Hidden">
    <fp-panel-card
      :title="params.Title ? params.Title[language] : null"
      :description="params.Description ? params.Description[language] : null"
      :required="params.Required"
    >
      <template #content>
        <div
          style="margin: 10px 0px"
          v-for="(component, ComponentIndex) in params.Components"
          :key="'components-' + ComponentIndex"
        >
          <form-schema-inputs
            :component="component"
            v-model="tempValue[component.VariableName]"
            :evaluateObjectModel="tempScore[component.VariableName]"
            @evaluating="evaluating($event, component.VariableName)"
            :disabled="disabled"
            :scoreable="scoreable"
            :language="language"
          ></form-schema-inputs>
        </div>
      </template>
    </fp-panel-card>
  </div>
</template>
<script>
//import PanelElement from "./PanelElement.vue";
export default {
  /* components: { PanelElement }, */
  name: "SimplePanelComponent",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    params: Object,
    value: [Object, Array],
    disabled: Boolean,
    scoreable: Boolean,
    evaluateObjectModel: [Object, Array],
    language: String,
  },
  data() {
    return {
      tempValue: this.value ? this.value : {},
      tempScore: this.evaluateObjectModel ? this.evaluateObjectModel : {},
    };
  },
  watch: {
    tempValue(input) {
      this.$emit("change", input);
    },
  },
  methods: {
    evaluating(input, varName) {
      this.$set(this.tempScore, varName, input);
      this.$emit("evaluating", this.tempScore);
    },
    setPanelsId() {
      if (this.value) {
        const temp = this.value;
        temp.forEach((x) => {
          x.Id = ++this.KeyIndex;
        });
        return temp;
      } else {
        return [{ Id: ++this.KeyIndex }];
      }
    },
  },
};
</script>