<template>
  <div v-if="!params.Hidden">
    <div v-if="params.DisplayFormat == $enums.SectionDisplayFormat.OnePage">
      <div
        style="margin: 10px 0px"
        v-for="component in params.Components"
        :key="'root-component-' + component.VariableName + '-' + component.Id"
      >
        <form-schema-inputs
          :component="component"
          v-model="tempValue[component.VariableName]"
          :evaluateObjectModel="tempScore[component.VariableName]"
          @evaluating="evaluating($event, component.VariableName)"
          :disabled="disabled"
          :scoreable="scoreable"
          :language="language"
        ></form-schema-inputs>
      </div>
    </div>
    <div v-else>
      <fp-tabs :lazy="false" :tabs="params.Components">
        <template v-slot:title="component">
          {{ component.Title ? component.Title[language] : null }}
        </template>
        <template v-slot:content="component">
          <div v-if="tempValue">
            <form-schema-inputs
              :hasTab="true"
              :component="component"
              :disabled="disabled"
              :scoreable="scoreable"
              v-model="tempValue[component.VariableName]"
              :evaluateObjectModel="tempScore[component.VariableName]"
              @evaluating="evaluating($event, component.VariableName)"
              :language="language"
            />
          </div>
          <!-- <b-row cols="1">
            <div
              style="padding: 10px"
              v-for="(component, componentIndex) in Section.Components"
              :key="
                'components-' + component.VariableName + '-' + componentIndex
              "
            >
              <div v-if="tempValue">
                <form-schema-inputs
                  :component="component"
                  :disabled="disabled"
                  :scoreable="scoreable"
                  v-model="tempValue[component.VariableName]"
                  :evaluateObjectModel="tempScore[component.VariableName]"
                  @evaluating="evaluating($event, component.VariableName)"
                />
              </div>
            </div>
          </b-row> -->
        </template>
      </fp-tabs>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
export default {
  name: "RootComponent",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    params: Object,
    value: Object,
    disabled: Boolean,
    scoreable: Boolean,
    evaluateObjectModel: Object,
    language: String,
  },
  data() {
    return {
      tempValue: this.value ? this.value : {},
      tempScore: this.evaluateObjectModel ? this.evaluateObjectModel : {},
    };
  },
  watch: {
    tempValue(input) {
      this.$emit("change", input);
    },
  },
  methods: {
    evaluating(input, varName) {
      this.$set(this.tempScore, varName, input);
      this.$emit("evaluating", this.tempScore);
    },
    changeValue(input) {
      this.$emit("change", input);
    },
    setVariables() {
      if (this.value) {
        this.params.Components.forEach((element) => {
          if (!this.value[element.VariableName]) {
            Vue.set(this.value, element.VariableName, {});
          }
        });
      }
      return this.value;
    },
  },
  mounted() {
    this.changeValue(this.value);
  },
};
</script>
