<template>
  <!-- TODO: szótárazni -->
  <div class="eh-partner-project">
    <div class="eh-flex-container-start" style="margin-bottom:10px">
      <button class="eh-action-button" @click="importProject">
        Importálás
      </button>
      <button
        class="eh-action-button eh-action-button-grey"
        @click="saveProject"
        style="width: 150px"
      >
        <span v-if="!uploading">{{ $t("base.basic.save") }}</span>
        <b-spinner small="true" v-else />
      </button>
    </div>
    <fp-tabs :lazy="true" :tabs="projectCardComponents">
      <template #title="Component">{{ Component.Title }}</template>
      <template #content="Component">
        <component
          :key="Component.ComponentName + '-' + forceUpdateProject"
          v-if="project"
          :is="Component.ComponentName"
          :project.sync="project"
          :participantList.sync="participantList"
          :roleList.sync="project.Roles"
          :items.sync="itemList"
          :projectStyle.sync="projectStyleSheet"
          :isImportedStyle.sync="isImportedStyle"
          :enabledUiElements.sync="project.EnabledUiElements"
          :enabledModules.sync="project.EnabledModules"
          :isImpotedEnabledObjects.sync="isImpotedEnabledObjects"
        />
      </template>
    </fp-tabs>
    <fp-form-modal :closable="false" v-model="addOwnToProject">
      <template #modal-header>
        <div>Saját felhasználó hozzárendelése</div>
        <div>
          Adja hozzá saját felhasználóját a projekthez a további beállításokért
        </div>
      </template>
      <template #content>
        <fp-select
          label="Szerepkör"
          v-model="ownUserToProject.RoleName"
          valueKey="RoleName"
          textKey="RoleName"
          :items="project.Roles"
        />
        <fp-select label="Címke" v-model="ownUserToProject.TagIds" />
        <div>Paraméterek szerepkör szerint</div>
        <fp-input label="Megjegyzés" v-model="ownUserToProject.Remark" />
        <b-button @click="addOwnUserToProject">Mentés</b-button>
      </template>
    </fp-form-modal>
  </div>
</template>
<script>
import { UserLogic } from "@/logic/backend/user";
import { ProjectLogic } from "@/logic/backend/project";
import { ProjectUiLogic } from "@/logic/ui/project";
import { AttachmentLogic } from "@/logic/backend/attachment";
/* components */
import ProjectDataSheet from "@/components/project-components/DataSheet";
import ProjectRoles from "@/components/project-components/Roles";
import ProjectItems from "@/components/project-components/ProjectItems";
import ProjectParticipants from "@/components/project-components/Participants";
import EnabledElements from "@/components/project-components/EnabledElements";
import Style from "@/components/project-components/Style";

export default {
  name: "WebAppPartnerNewProject",
  components: {
    ProjectDataSheet,
    ProjectRoles,
    ProjectItems,
    ProjectParticipants,
    Style,
    EnabledElements,
  },
  data() {
    return {
      project: {
        Research: {
          Name: {},
          Description: {},
          ResearchPlan: {},
          ResearchGoal: {},
          SelectionCriteria: {},
          PatientBlind: false,
          DoctorBlind: false,
          TreatmentParticipantCount: 0,
          ControlParticipantCount: 0,
          StartDate: null,
          EndDate: null,
        },
        Type: null,
        Roles: [],
        Remark: "",
        EnabledUiElements: {
          Client: [
            "Questionnaire",
            "NotificationTemplate",
            "Workflow",
            "UserTask",
            "Document",
            "Appointment",
            "Meeting",
            "ServiceSearch",
            "Covid19Pass",
            "TravelGuide",
            "HealthGuide",
          ],
          HealthProfessional: [
            "Questionnaire",
            "NotificationTemplate",
            "Workflow",
            "UserTask",
            "Document",
            "Appointment",
            "Meeting",
            "ServiceSearch",
            "Covid19Pass",
            "TravelGuide",
            "HealthGuide",
          ],
          Partner: [
            "Questionnaire",
            "NotificationTemplate",
            "Workflow",
            "UserTask",
            "Document",
            "Appointment",
            "Meeting",
            "ServiceSearch",
            "Covid19Pass",
            "TravelGuide",
            "HealthGuide",
          ],
        },
        EnabledModules: {
          Client: [
            "BodyWeight",
            "Nutrition",
            "Exercise",
            "Cardiology",
            "Diabetology",
            "Oncology",
            "Pulmonology",
            "Depression",
            "Asthma",
            "Medicine",
          ],
          HealthProfessional: [
            "BodyWeight",
            "Nutrition",
            "Exercise",
            "Cardiology",
            "Diabetology",
            "Oncology",
            "Pulmonology",
            "Depression",
            "Asthma",
            "Medicine",
          ],
          Partner: [
            "BodyWeight",
            "Nutrition",
            "Exercise",
            "Cardiology",
            "Diabetology",
            "Oncology",
            "Pulmonology",
            "Depression",
            "Asthma",
            "Medicine",
          ],
        },
      },
      emptyProject: {
        Research: {
          Name: {},
          Description: {},
          ResearchPlan: {},
          ResearchGoal: {},
          SelectionCriteria: {},
          PatientBlind: false,
          DoctorBlind: false,
          TreatmentParticipantCount: 0,
          ControlParticipantCount: 0,
          StartDate: null,
          EndDate: null,
        },
        Type: null,
        Roles: [],
        Remark: "",
        EnabledUiElements: {
          Client: [
            "Questionnaire",
            "NotificationTemplate",
            "Workflow",
            "UserTask",
            "Document",
            "Appointment",
            "Meeting",
            "ServiceSearch",
            "Covid19Pass",
            "TravelGuide",
            "HealthGuide",
          ],
          HealthProfessional: [
            "Questionnaire",
            "NotificationTemplate",
            "Workflow",
            "UserTask",
            "Document",
            "Appointment",
            "Meeting",
            "ServiceSearch",
            "Covid19Pass",
            "TravelGuide",
            "HealthGuide",
          ],
          Partner: [
            "Questionnaire",
            "NotificationTemplate",
            "Workflow",
            "UserTask",
            "Document",
            "Appointment",
            "Meeting",
            "ServiceSearch",
            "Covid19Pass",
            "TravelGuide",
            "HealthGuide",
          ],
        },
        EnabledModules: {
          Client: [
            "BodyWeight",
            "Nutrition",
            "Exercise",
            "Cardiology",
            "Diabetology",
            "Oncology",
            "Pulmonology",
            "Depression",
            "Asthma",
            "Medicine",
          ],
          HealthProfessional: [
            "BodyWeight",
            "Nutrition",
            "Exercise",
            "Cardiology",
            "Diabetology",
            "Oncology",
            "Pulmonology",
            "Depression",
            "Asthma",
            "Medicine",
          ],
          Partner: [
            "BodyWeight",
            "Nutrition",
            "Exercise",
            "Cardiology",
            "Diabetology",
            "Oncology",
            "Pulmonology",
            "Depression",
            "Asthma",
            "Medicine",
          ],
        },
      },
      projectCardComponents: [
        {
          ComponentName: "ProjectDataSheet",
          Title: this.$t("partner.newProject.dataSheet.title"),
        },
        {
          ComponentName: "ProjectRoles",
          Title: this.$t("partner.newProject.roles.title"),
        },
        {
          ComponentName: "ProjectItems",
          Title: "Elemek",
        },
        {
          ComponentName: "ProjectParticipants",
          Title: this.$t("partner.newProject.participants.title"),
        },
        {
          ComponentName: "EnabledElements",
          Title: "Beállítások",
        },
        {
          ComponentName: "Style",
          Title: "Design stílus",
        },
      ],
      ownUserToProject: {
        UserId: this.$loggedUser.UserId,
        RoleName: null,
        SuperiorIds: [],
        TagIds: [],
        Parameters: {},
        Remark: null,
      },
      uploading: false,
      participantList: [],
      itemList: [],
      createdProjectId: null,
      forceUpdateProject: 1,
      addOwnToProject: false,
      projectStyleSheet: null,
      projectCssStyle: null,
      isImportedStyle: false,
      isImpotedEnabledObjects: false,
    };
  },
  watch: {
    /* itemList(input) {
      console.log(input);
    }, */
    async projectStyleSheet(stringCss) {
      this.projectCssStyle =
        ":root " + stringCss.replaceAll('"', "").replaceAll(",", ";");
    },
  },
  methods: {
    async importProject() {
      const importedProject = await ProjectUiLogic.importObject();
      if (importedProject) {
        this.project = {
          Research: importedProject.Research,
          Type: importedProject.Type,
          Roles: importedProject.Roles,
          Remark: importedProject.Remark,
          SupportedLanguages: importedProject.SupportedLanguages,
          EnabledModules: importedProject.EnabledModules,
          EnabledUiElements: importedProject.EnabledUiElements,
        };
        this.isImpotedEnabledObjects = true;
        this.participantList = importedProject.Participants;
        this.itemList = importedProject.Items;
        this.projectStyleSheet = importedProject.StyleSheetId
          ? await this.getProjectStyleSheet(importedProject.StyleSheetId)
          : null;
      }
      this.forceUpdateProject++;
    },
    async getProjectStyleSheet(styleSheetId) {
      this.isImportedStyle = true;
      return await this.getStyle(styleSheetId);
    },
    async getStyle(fileId) {
      const getAttachmentResponse = await AttachmentLogic.downloadAttachment(
        fileId
      );
      if (!getAttachmentResponse.Code) {
        return await getAttachmentResponse.text();
      }
    },
    async saveProject() {
      const saveProjectResponse = await ProjectLogic.createProject(
        this.project
      );
      if (!saveProjectResponse.Code) {
        //TODO: sikeres
        /*this.addOwnToProject = true;*/
        this.createdProjectId = saveProjectResponse.ProjectId;
        if (
          !this.participantList.find((p) => p.UserId == this.$loggedUser.UserId)
        ) {
          this.addOwnToProject = true;
        } else {
          await this.saveProjectDetails();
        }
      } else {
        //TODO: hiba
      }
    },
    async saveProjectDetails() {
      if (this.itemList) {
        await this.saveItems();
      }
      if (this.participantList) {
        await this.saveParticipants();
      }
      if (this.projectCssStyle) {
        await this.saveProjectStyleSheet();
      }
      this.updateUser();
      this.$router.push({ name: "WebAppPartnerProjects" });
    },
    async saveProjectStyleSheet() {
      const styleSheetId = await this.getStyleSheetId();
      const saveStyleResponse = await ProjectLogic.setProject(
        { StyleSheetId: styleSheetId },
        this.createdProjectId
      );
      if (!saveStyleResponse.Code) {
        //TODO: sikeres
      } else {
        //TODO: hiba
      }
    },
    async getStyleSheetId() {
      var blob = new Blob([this.projectCssStyle]);
      if (blob) {
        const uploadResult = await AttachmentLogic.uploadAttachment(
          blob,
          this.$enums.AttachmentTypeName.ProjectStyleSheet.TypeName,
          this.createdProjectId
        );
        if (!uploadResult.Code) {
          return uploadResult.AttachmentId;
        } else {
          this.$bvToast.toast(uploadResult.Message, {
            title: this.$t("requestResponse.attachment.errorUploadDocument"),
            variant: "danger",
            solid: true,
            AutoHideDelay: 10000,
          });
        }
      }
      //this.projectStyleSheet
    },
    async saveParticipants() {
      const body = this.participantList.map((p) => {
        return {
          UserId: p.UserId,
          RoleName: p.RoleName,
          SuperiorIds:
            p.SuperiorIds.includes(this.$loggedUser.UserId) ||
            p.UserId == this.$loggedUser.UserId
              ? p.SuperiorIds
              : [...p.SuperiorIds, this.$loggedUser.UserId],
          TagIds: p.TagIds,
          Parameters: {},
          Remark: p.Remark,
        };
      });
      const saveParticipantsResponse = await ProjectLogic.createParticipant(
        this.createdProjectId,
        body
      );
      if (!saveParticipantsResponse.Code) {
        //TODO: sikeres
        //await this.setRelationship()
      } else {
        //TODO: hiba
      }
    },
    //TODO: participants relationship kialakítás
    /*async setRelationship() {
      const projectDetails = await this.getProjectDetails()
      if (projectDetails) {
        const createdParticipants = projectDetails.Participants
        this.participantList.forEach(p=>{

          const importedNew = createdParticipants.find(cp=>cp.UserId == p.UserId)
        })
      }
    },*/
    /*async getProjectDetails() {
      const getProjectDetailsResponse = await ProjectLogic.getProjectDetails({ProjectId: this.project.ProjectId})
      if (!getProjectDetailsResponse.Code) {
        return getProjectDetailsResponse
      } else {
        return null
      }
    },*/
    async saveItems() {
      const body = this.itemList.filter((e, i) => {
        return (
          i ===
          this.itemList.findIndex((obj) => {
            return (
              obj.EntityId === e.EntityId &&
              obj.EntityTypeName == e.EntityTypeName
            );
          })
        );
      });
      const saveItemsResponse = await ProjectLogic.setItem(
        body,
        this.createdProjectId
      );
      if (!saveItemsResponse.Code) {
        //TODO: sikeres
      } else {
        //TODO: hiba
      }
    },
    async addOwnUserToProject() {
      const addOwnToProjectResponse = await ProjectLogic.createParticipant(
        this.createdProjectId,
        [this.ownUserToProject]
      );
      if (!addOwnToProjectResponse.Code) {
        //TODO: sikeres
        this.addOwnToProject = false;
        await this.saveProjectDetails();
      } else {
        //TODO: hiba
      }
    },
    //TODO: szerepkör szerint localba mentés és átléptetés
    updateUser() {
      this.$loggedUser.Projects.Partner.push({
        ProjectName: this.project.Research.Name,
        ProjectId: this.createdProjectId,
      });
      this.$loggedUser.SelectedProject = {
        ProjectName: this.project.Research.Name,
        ProjectId: this.createdProjectId,
      };
      UserLogic.saveLoginUser(this.$loggedUser);
      this.$emit("forceUpdate");
    },
  },
  //betöltéskor lefut egyszer
  async mounted() {
    //oldal cím beállítása
    this.$store.dispatch("setPageTitle", this.$t("partner.newProject.title"));
  },
};
</script>
<style scoped>
#new-fp-modal .modal-content {
  border-radius: 10px;
  margin: 100px 0;
}

#new-fp-modal .modal-content header {
  padding: 0;
}
</style>
