export default {
  publicHealthcareCardNumber: "Nummer der öffentlichen Gesundheitskarte",
  publicHealthcareCardValidBy: "Gültigkeitsdatum der Gesundheitskarte",
  euSocialSecurityNumber: "EU-Sozialversicherungsnummer",
  name: "Name",
  fullName: "Vollständiger Name",
  ssn: "Sozialversicherungsnummer",
  email: "E-Mail",
  emailAddress: "E-Mail-Adresse",
  password: "Passwort",
  passwordAgain: "Passwortbestätigung",
  registrationCode: "Registrierungscode",
};
