<template>
  <div>
    <span style="margin-left: 5px">
      <div v-if="params.Title" v-html="params.Title[language]"></div>
    </span>
    <span
      v-if="params.Required && !params.Label"
      style="color: red; font-size: 14px"
    >
      * {{ $t("Base.required") }}
    </span>
    <fp-input
      :placeholder="params.PlaceHolder ? params.PlaceHolder[language] : null"
      :description="params.Description ? params.Description[language] : null"
      :required="params.Required"
      :disabled="!params.Enabled != false || disabled"
      :state="checkValidInput"
      :error="
        'Minimum: ' +
        params.Minimum +
        ', maximum: ' +
        params.Maximum +
        ' számértéket lehet megadni'
      "
      type="number"
      textStyle="dark"
      v-model="inputValue"
    />
  </div>
</template>
<script>
export default {
  name: "Integer",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    params: Object,
    value: Number,
    disabled: Boolean,
    language: String,
  },
  computed: {
    checkValidInput() {
      if (
        this.params.Maximum &&
        this.params.Minimum &&
        (this.inputValue > this.params.Maximum ||
          this.inputValue < this.params.Minimum)
      ) {
        return false;
      }
      return true;
    },
    inputValue: {
      get() {
        return this.value ? this.value : this.params.DefaultValue;
      },
      set(value) {
        this.$emit("change", Number.parseInt(value));
      },
    },
  },
};
</script>
<style scoped>
input.text-field-input:focus ~ .input-text-placeholder,
input.text-field-input.not-empty ~ .input-text-placeholder {
  color: white;
  opacity: 1;
}
input.text-field-input.not-valid-input ~ .error-text,
.input-checkbox.invalid-input .error-text {
  color: red;
  font-size: 12px;
  padding-left: 5px;
  margin-top: -5px;
  display: block;
}
input ~ .error-text,
.input-checkbox .error-text {
  display: none;
}
input.text-field-input:focus {
  outline: none;
}
input.text-field-input:disabled {
  background: lightgray;
}
input.text-field-input.not-valid-input {
  border-color: red;
}
input.text-field-input:focus ~ .input-text-placeholder,
input.text-field-input.not-empty ~ .input-text-placeholder {
  top: -16px;
  left: 15px;
  font-size: 11px;
  opacity: 0.4;
  display: inline;
}
.text-field-input-box {
  position: relative;
  margin-top: 15px;
}
.input-text-placeholder {
  opacity: 0.5;
  position: absolute;
  pointer-events: none;
  left: 15px;
  top: 10px;
  transition: 0.2s ease all;
}

input.text-field-input {
  font-size: 14px;
  border: solid 3px;
  border-color: var(--eh-primary-5);
  border-radius: 10px;
  height: 40px;
  padding-left: 15px;
  width: 100%;
}
.input-text-description {
  font-size: 14px;
  margin-left: 10px;
  color: #00000099;
}
</style>
