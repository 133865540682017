export default {
  superior: "Vorgesetzter(in)",
  subordinate: "Untergeordneter(in)",
  superiors: "Vorgesetzteren(inen)",
  subordinates: "Untergeordneteren(inen)",
  editRelation: "Bearbeiten den Teilnehmerverbindungen",
  editParticipant: "Bearbeiten des Teilnehmers",
  removeProjectRelation: "Beziehung zum aktuellen Teilnehmer entfernen",
  removeFromProject: "Teilnehmer aus dem Projekt löschen",
  deleteParticipant: "Teilnehmerkonto entfernen",
};
