<template>
  <div class="eh-partner-project">
    <div>
      <div class="eh-flex-container-start" style="margin-bottom: 10px">
        <b-button @click="exportProject()">{{
          $t("base.basic.export")
        }}</b-button>
      </div>
      <div v-if="project">
        <fp-tabs
          :disabledTabs="isEditing"
          noneDisabledKey="isEditing"
          :tabs="projectCardComponents"
        >
          <template #title="Component">{{ Component.Title }}</template>
          <template #content="Component">
            <component
              :is="Component.ComponentName"
              :createdProjectId="project.ProjectId"
              :project.sync="project"
              :participantList.sync="project.Participants"
              :roleList.sync="project.Roles"
              :items.sync="project.Items"
              :isCreated="true"
              :projectStyle.sync="projectStyleSheet"
              :enabledUiElements.sync="project.EnabledUiElements"
              :enabledModules.sync="project.EnabledModules"
              :enabledFunctions.sync="project.EnabledFunctions"
              @getList="getSelectedProject"
              @editingTab="editingTab(Component, $event)"
            />
          </template>
        </fp-tabs>
      </div>
      <div v-else>{{ $t("partner.projects.dontProject") }}</div>
    </div>
  </div>
</template>
<script>
import { ProjectLogic } from "@/logic/backend/project";
import { ProjectUiLogic } from "@/logic/ui/project";
import { AttachmentLogic } from "@/logic/backend/attachment";

/* components */
import DataSheet from "@/components/project-components/DataSheet";
import Roles from "@/components/project-components/Roles";
import Participants from "@/components/project-components/Participants";
import ProjectItems from "@/components/project-components/ProjectItems";
import Style from "@/components/project-components/Style";
import EnabledElements from "@/components/project-components/EnabledElements";

export default {
  name: "WebAppPartnerProjects",
  components: {
    DataSheet,
    Roles,
    Participants,
    ProjectItems,
    Style,
    EnabledElements,
  },
  data() {
    return {
      projectCardComponents: [
        {
          ComponentName: "DataSheet",
          Title: this.$t("partner.projects.dataSheet"),
        },
        {
          ComponentName: "Roles",
          Title: this.$t("partner.projects.roles"),
        },
        {
          ComponentName: "ProjectItems",
          Title: "Elemek",
        },
        {
          ComponentName: "Participants",
          Title: this.$t("partner.newProject.participants.title"),
        },
        {
          ComponentName: "EnabledElements",
          Title: "Beállítások",
        },
        {
          ComponentName: "Style",
          Title: "Design stílus",
        },
      ],
      project: null,
      projectStyleSheet: null,
      isEditing: false,
    };
  },
  //TODO:csekkolni miért raktam ide? xd
  /* watch: {
    async projectStyleSheet(stringCss) {
      if (stringCss) {
        this.projectCssStyle =
          ":root " + stringCss.replaceAll('"', "").replaceAll(",", ";");
      } else {
        this.projectCssStyle = null;
      }
    },
  }, */
  methods: {
    editingTab(editingComponent, isEditing) {
      this.isEditing = isEditing;
      const tab = this.projectCardComponents.find(
        (t) => t.ComponentName == editingComponent.ComponentName
      );
      this.$set(tab, "isEditing", isEditing);
    },
    exportProject() {
      ProjectUiLogic.exportProject(this.project);
    },
    async getSelectedProject() {
      const getSelectedProjectResponse = await ProjectLogic.getProjectDetails({
        ProjectId: this.$loggedUser.SelectedProject.ProjectId,
      });
      if (!getSelectedProjectResponse.Code) {
        this.project = getSelectedProjectResponse[0];
        if (this.project.StyleSheetId) {
          this.projectStyleSheet = await this.getStyleSheet(
            this.project.StyleSheetId
          );
        }
      } else {
        this.$bvToast.toast(getSelectedProjectResponse.Message, {
          title: "Hiba",
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    async getStyleSheet(styleSheetId) {
      const getAttachmentResponse = await AttachmentLogic.downloadAttachment(
        styleSheetId
      );
      if (!getAttachmentResponse.Code) {
        return await getAttachmentResponse.text();
      }
    },
  },
  //betöltéskor lefut egyszer
  async mounted() {
    await this.getSelectedProject();
    //oldal cím beállítása
    this.$store.dispatch("setPageTitle", this.$t("partner.projects.title"));
  },
};
</script>
<style scoped>
#new-fp-modal .modal-content {
  border-radius: 10px;
  margin: 100px 0px;
}

#new-fp-modal .modal-content header {
  padding: 0px;
}
</style>
