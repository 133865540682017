export default {
  title: "Cím",
  description: "Leírás",
  location: "Helyszín",
  fromDate: "{date}-tól",
  toDate: "{date}-ig",
  remark: "Bemerkung",
  language: "Sprache",
  languages: "Sprachen",
  startDate: "Anfang",
  endDate: "Ende",
  needLanguage: "Wählen Sie zuerst eine Sprache!",
  filter: "Filter",
  registration: "Anmeldung",
  login: "Login",
  required: "Erforderlich",
};
