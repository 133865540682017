import FileSaver from 'file-saver';

// TODO: Error handling
export const ProjectUiLogic = {
  exportProject(project) {
    const jsonString = JSON.stringify(project, null, '\t')
    let blob = new Blob([jsonString], {type: "application/json"});
    FileSaver.saveAs(blob, project.Research.Name ? project.Research.Name.hu : 'projekt' + ".json");
  },
  async importObject() {
    return new Promise((resolve) => {
      let fileUpload = document.createElement("input");
      fileUpload.type = "file";
      let parsedProject = null
      fileUpload.addEventListener("change", (event) => {
        let fr = new FileReader()
        let file = event.target.files[0]
        fr.onloadend = (event) => {
          parsedProject = JSON.parse(event.target.result);
          resolve(parsedProject)
        }
        fr.readAsText(file)
      })
      fileUpload.click();
    })
  },
}