<template>
  <div>
    <fp-app-header
      :signedName="signedName"
      :siteTitle="siteTitle"
      :siteColor="siteColor"
      :signedProfilePicture="userPicture"
      :menuElements="healthMenuElements"
      :projectEnabledUiElements="project ? project.EnabledUiElements : null"
      @forceUpdate="forceUpdate"
    />
    <router-view
      :key="'views-specialist-routerview-' + forceUpdateKey"
      style="width: 95%; margin: auto"
      name="specialist"
      :project="project"
      @color-change="colorChange"
      @signed-user-name="signedUserNameChange"
      @picture-changed="changeUserPicture"
    />
  </div>
</template>
<script>
import { ProjectLogic } from "@/logic/backend/project";
export default {
  props: {
    user: {
      type: Object,
    },
  },
  data() {
    return {
      signedName: null,
      siteTitle: null,
      siteColor: null,
      userPicture: null,
      forceUpdateKey: 0,
      project: null,
      healthMenuElements: [
        /* {
          url: "PatientList",
          langKey: "healthProfessional.menu.patientList",
          key: "patient-list",
        }, */
        /* {
          url: "Questionnaires",
          langKey: "healthProfessional.menu.questionnaires",
          key: "questionnaires",
        }, */
      ],
    };
  },
  methods: {
     async forceUpdate() {
      ++this.forceUpdateKey;
      await this.getProject();
    },
    async getProject() {
      const selectedProject = this.$loggedUser.SelectedProject;
      if (selectedProject && selectedProject.ProjectId) {
        const getProjectResponse = await ProjectLogic.getProjectDetails({
          ProjectId: selectedProject.ProjectId,
        });
        if (!getProjectResponse.Code) {
          this.project = getProjectResponse[0];
        } else {
          this.$bvToast.toast(getProjectResponse.Message, {
            title: this.$t("requestResponse.basic.errorGetNamedData", {
              name: this.$t("healthProfessional.projects.currentProject"),
            }),
            variant: "danger",
            solid: true,
            AutoHideDelay: 10000,
          });
          this.project = null;
        }
      } else {
        this.project = null;
      }
    },
    colorChange(newColor) {
      this.siteColor = null;
      if (newColor) {
        this.siteColor = newColor;
      }
    },
    signedUserNameChange(newName) {
      if (newName) {
        this.signedName = newName;
      }
    },
    changeUserPicture(imgUrl) {
      this.userPicture = imgUrl;
    },
  },
  async mounted() {
    await this.getProject();
  },
};
</script>
