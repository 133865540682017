const Button = () => import("./Button.vue");
const Checkbox = () => import("./Checkbox.vue");
const DatePicker = () => import("./DatePicker.vue");
const DateRangeButton = () => import("./DateRangeButton.vue");
const DateTimePicker = () => import("./DateTimePicker.vue");
const Input = () => import("./Input.vue");
const Select = () => import("./Select.vue");
const Slider = () => import("./Slider.vue");
const TextArea = () => import("./TextArea.vue");
const TimePicker = () => import("./TimePicker.vue");
const ToggleSwitch = () => import("./ToggleSwitch.vue");
const BoubleModalView = () => import("./BoubleModalView.vue");
const ConfirmNotification = () => import("./ConfirmNotification.vue");
const FormModal = () => import("./FormModal.vue");
const ListBox = () => import("./ListBox.vue");
const Pagination = () => import("./Pagination.vue");
const PanelCard = () => import("./PanelCard.vue");
const Tab = () => import("./Tab.vue");
const TableList = () => import("./TableList.vue");
const Tabs = () => import("./Tabs.vue");
const RssReader = () => import("./RssReader.vue");
const SvgIcon = () => import("./SvgIcon.vue");

export default {
  install(Vue) {
    Vue.component("fp-button", Button);
    Vue.component("fp-checkbox", Checkbox);
    Vue.component("fp-date-picker", DatePicker);
    Vue.component("fp-date-range-button", DateRangeButton);
    Vue.component("fp-date-time-picker", DateTimePicker);
    Vue.component("fp-input", Input);
    Vue.component("fp-select", Select);
    Vue.component("fp-slider", Slider);
    Vue.component("fp-text-area", TextArea);
    Vue.component("fp-time-picker", TimePicker);
    Vue.component("fp-toggle-switch", ToggleSwitch);
    Vue.component("fp-bouble-modal", BoubleModalView);
    Vue.component("fp-confirm-notification", ConfirmNotification);
    Vue.component("fp-form-modal", FormModal);
    Vue.component("fp-list-box", ListBox);
    Vue.component("fp-pagination", Pagination);
    Vue.component("fp-panel-card", PanelCard);
    Vue.component("fp-tab", Tab);
    Vue.component("fp-table-list", TableList);
    Vue.component("fp-tabs", Tabs);
    Vue.component("fp-rss-reader", RssReader);
    Vue.component("fp-svg-icon", SvgIcon);
  },
};
