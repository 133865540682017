<template>
  <div v-if="!params.Hidden">
    <div v-if="params.DisplayFormat == $enums.SectionDisplayFormat.Tab">
      <fp-tabs :lazy="false" :tabs="params.Components">
        <template v-slot:title="Section">
          {{ Section.Title ? Section.Title[language] : null }}
        </template>
        <template v-slot:content="Section">
          <b-row cols="1">
            <div
              style="padding: 10px"
              v-for="(Component, ComponentIndex) in Section.Components"
              :key="'components-' + ComponentIndex"
            >
              <form-schema-inputs
                v-if="tempValue"
                :component="Component"
                v-model="tempValue[Component.VariableName]"
                :evaluateObjectModel="tempScore[component.VariableName]"
                @evaluating="evaluating($event, component.VariableName)"
                :disabled="disabled"
                :scoreable="scoreable"
                :language="language"
              />
              <form-schema-inputs
                v-else
                :component="Component"
                :disabled="disabled"
                :scoreable="scoreable"
                :language="language"
              />
            </div>
          </b-row>
        </template>
      </fp-tabs>
    </div>
    <div v-else-if="hasTab">
      <div
        style="margin: 10px 0px"
        v-for="(component, ComponentIndex) in params.Components"
        :key="'components-' + ComponentIndex"
      >
        <form-schema-inputs
          :disabled="disabled"
          :scoreable="scoreable"
          :component="component"
          v-model="tempValue[component.VariableName]"
          :evaluateObjectModel="tempScore[component.VariableName]"
          @evaluating="evaluating($event, component.VariableName)"
          @change="changeValue()"
          :language="language"
        />
        <!-- <form-schema-inputs v-else :component="component" /> -->
      </div>
    </div>
    <div v-else>
      <fp-panel-card
        :title="params.Title ? params.Title[language] : null"
        :description="params.Description ? params.Description[language] : null"
      >
        <template #content>
          <div
            style="margin: 10px 0px"
            v-for="(component, ComponentIndex) in params.Components"
            :key="'components-' + ComponentIndex"
          >
            <form-schema-inputs
              :disabled="disabled"
              :scoreable="scoreable"
              :component="component"
              v-model="tempValue[component.VariableName]"
              :evaluateObjectModel="tempScore[component.VariableName]"
              @evaluating="evaluating($event, component.VariableName)"
              @change="changeValue()"
              :language="language"
            />
            <!-- <form-schema-inputs v-else :component="component" /> -->
          </div>
        </template>
      </fp-panel-card>
    </div>
  </div>
</template>
<script>
export default {
  name: "SectionComponent",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    params: Object,
    value: [Object, Array],
    disabled: Boolean,
    scoreable: Boolean,
    evaluateObjectModel: [Object, Array],
    hasTab: Boolean,
    language: String,
  },
  data() {
    return {
      tempValue: this.value ? this.value : {},
      tempScore: this.evaluateObjectModel ? this.evaluateObjectModel : {},
    };
  },
  /* watch: {
    tempValue(input) {
      this.$emit("change", input);
    },
  }, */
  methods: {
    evaluating(input, varName) {
      this.$set(this.tempScore, varName, input);
      this.$emit("evaluating", this.tempScore);
    },
    changeValue() {
      this.$emit("change", this.tempValue);
    },
  },
};
</script>
