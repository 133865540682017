export const EnAdmin = {
  admin: {
    userManager: {
      accountData: "Account information",
      username: "Username",
      userRole: "Role",
      password: "Password",
      passwordAgain: "Repeat password",
      personName: "Name",
      email: "E-mail address",
      flags: "Account property",
      createNewUser: "Create a new user",
    },
  },
};
