<template>
  <div>
    <Header />
    <div class="page-background">
      <div class="body-width" style="height:400px;min-height:calc(100vh - 360px);">
        <h2
          style="padding-top:100px;"
        >Az oldal nem található! :(</h2>
      </div>
    </div>
  </div>
</template>
<script>
import Header from "@/components/header-footer/StaticHeader.vue";
export default {
  components: {
    Header
  }
};
</script>