export const EnBase = {
  "base": {
    "address": {
      "address": "Address",
      "building": "House number",
      "city": "City",
      "latitude": "Lattitude",
      "longitude": "Longitude",
      "street": "Address",
      "subBuilding": "Address 2nd row",
      "zip": "Postcode"
    },
    basic: {
      "back": "Back",
      "add": "Add",
      "cancel": "Cancel",
      "cancellation": "Withdrawal",
      "clear": "Clear",
      "clearFilter": "Empty filters",
      "close": "Close",
      "current": "Current",
      "delete": "Delete",
      "download": "Download",
      "edit": "Edit",
      "error": "Error",
      "exit": "Exit",
      "export": "Export",
      "filling": "Fill in",
      "filter": "Filter",
      "import": "Import",
      "loading": "Loading...",
      "next": "Next",
      "underDevelopment": "Under construction",
      "unit": "Unit of measure",
      "upload": "Upload",
      "value": "Value",
      "view": "View",
      "previous": "Previous",
      "save": "Save",
      "search": "Search",
      "select": "Select",
      "selectSomeElement": "{piece} selected item",
      "send": "Send",
      "share": "Share",
      "speach": "Dictation",
      "stop": "Stop",
      "sync": "Synchronization",
      update: 'Update',
      login:'Login'
    },
    "calendar": {
      "daily": "Daily",
      "date": "Date",
      "day": "Day",
      "from": "from {date}",
      "month": "Month",
      "monthly": "Monthly",
      "time": "Time",
      "to": "to {date}",
      "today": "Today",
      "week": "Week",
      "weekly": "Weekly",
      "year": "Year",
      "yearly": "Annual",
      bootstrap: {
        labelPrevDecade: "Previous Decade",
        labelPrevYear: "Previous year",
        labelPrevMonth: "Previous month",
        labelCurrentMonth: "Current month",
        labelNextMonth: "Next month",
        labelNextYear: "Next year",
        labelNextDecade: "Next Decade",
        labelToday: "Today",
        labelSelected: "Selected date",
        labelNoDateSelected: "No date selected",
        labelCalendar: "Calendar",
        labelNav: "Calendar navigation",
        labelHelp: "Use cursor keys to navigate calendar dates",
      },
    },
    "module": "Module",
    "noData": "No items to display",
    "pleaseSelect": "Please select",
    "title": "Focus Program",
    usernameEmail: 'User account (email)',
    password: 'Password'
  },
}