/* VUE import */
import Vue from "vue";

/* Project modulok behúzás */
import Register from "../../../Plugins/Products/Register";
Vue.use(Register);

/* Local beállítások */
import App from "@/App.vue";
import store from "@/store.js";
import router from "@/router";

/* Globális beállítások */
import i18n from "../../../Plugins/Locale";

/* Vue beállítások */
Vue.config.productionTip = false;
Vue.prototype.Window = window;

Vue.prototype.$loggedUser;
//Vue.prototype.$enums = FPenums;
Vue.prototype.$eesztToken;

//Vue.prototype.$this.$eventBus = new Vue();

//export const this.$eventBus = new Vue();

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");