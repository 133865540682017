import Appointment from "./Appointment";
import Base from "./Base";
import Chart from "./Chart";
import Document from "./Document";
import Form from "./Form";
import Operation from "./Operation";
import Participant from "./Participant";
import Patient from "./Patient";
import Profile from "./Profile";
import Project from "./Project";
import ProjectParticipant from "./ProjectParticipant";
import Tag from "./Tag";
import Template from "./Template";

export default {
  Appointment: Appointment,
  Base: Base,
  Chart: Chart,
  Document: Document,
  Form: Form,
  Operation: Operation,
  Participant: Participant,
  Patient: Patient,
  Profile: Profile,
  Project: Project,
  ProjectParticipant: ProjectParticipant,
  Tag: Tag,
  Template: Template,
};
