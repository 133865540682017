import Vue from "vue";
import { ClientService, RequestMethod } from "../client-service";
import { StoreManager } from "@/storage/store-manager";
import { RequestResponseLogic } from "../Ui/request-response";

const USER = "/user";
const BASE_DATA = "base_data";
const USER_PASSWORD = "/user/change_password";
const REQUEST_PASSWORD = "/user/request_password_change";
const PASSWORD_TOKEN = "/user/change_password_token";
const REGISTRATION = "/user/registration";

const SIGNED_USER_DATASTORE_KEY = process.env.VUE_APP_LOGGED_USER_STORAGE_KEY;

export const UserLogic = {
  //User adatok lekérdezése
  async getUser(params) {
    let result = await ClientService.sendRequest(
      RequestMethod.GET,
      USER,
      params
    );
    return await RequestResponseLogic.checkRequestResponse(result);
  },
  async getUserData(params) {
    let result = await ClientService.sendRequest(
      RequestMethod.GET,
      USER + "/" + BASE_DATA,
      params
    );
    return await RequestResponseLogic.checkRequestResponse(result);
  },
  //új user létrehozása
  async createUser(body, params) {
    let result = await ClientService.sendRequestWithBody(
      RequestMethod.POST,
      USER,
      params,
      body
    );
    return await RequestResponseLogic.checkRequestResponse(result);
  },

  async setUser(userId, body, params) {
    let result = await ClientService.sendRequestWithBody(
      RequestMethod.PATCH,
      USER + "/" + userId,
      params,
      body
    );
    return await RequestResponseLogic.checkRequestResponse(result);
  },
  //user törlés
  async deleteUser(userId, params) {
    let result = await ClientService.sendRequest(
      RequestMethod.DELETE,
      USER + "/" + userId,
      params
    );
    return await RequestResponseLogic.checkRequestResponse(result);
  },

  //user password
  async changePassword(body, params) {
    let result = await ClientService.sendRequestWithBodyWithoutAuth(
      RequestMethod.POST,
      USER_PASSWORD,
      params,
      body
    );
    return await RequestResponseLogic.checkRequestResponse(result);
  },

  async requestPasswordChange(username, params) {
    console.log(username);
    let result = await ClientService.sendRequestWithoutAuth(
      RequestMethod.POST,
      REQUEST_PASSWORD + "?username=" + username,
      params
    );
    console.log(result);
    return await RequestResponseLogic.checkRequestResponse(result);
  },

  async changePasswordToken(body, params) {
    let result = await ClientService.sendRequestWithBodyWithoutAuth(
      RequestMethod.POST,
      PASSWORD_TOKEN,
      params,
      body
    );
    return await RequestResponseLogic.checkRequestResponse(result);
  },

  async registrationUser(body, params) {
    let result = await ClientService.sendRequestWithBodyWithoutAuth(
      RequestMethod.POST,
      REGISTRATION,
      params,
      body
    );
    return await RequestResponseLogic.checkRequestResponse(result);
  },

  //TODO: local storageba menteni és bármikor vissza kérdezhető legyen
  //User adatok mentése local storage-ba
  saveLoginUser(data) {
    StoreManager.sessionStoragePut(SIGNED_USER_DATASTORE_KEY, data);
    StoreManager.localStoragePut(SIGNED_USER_DATASTORE_KEY, data);
    Vue.prototype.$loggedUser = data;
  },
  //User adatok lekérdezése local storage-ból
  getSignedUser() {
    var signedUser = StoreManager.sessionStorageGet(SIGNED_USER_DATASTORE_KEY);
    if (!signedUser) {
      signedUser = StoreManager.localStorageGet(SIGNED_USER_DATASTORE_KEY);
    }
    return signedUser;
  },
  //User adatok törlése local storage-ból
  deleteSignedUser() {
    Vue.prototype.$loggedUser = null;
    StoreManager.sessionStorageDelete(SIGNED_USER_DATASTORE_KEY);
    StoreManager.localStorageDelete(SIGNED_USER_DATASTORE_KEY);
  },
};
