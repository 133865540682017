export default {
  save: "Mentés",
  cancel: "Mégsem",
  send: "Küldés",
  sendIt: "Elküldés",
  exit: "Kilépés",
  checkAll: "Összes kijelölés",
  uncheckAll: "Kijelölés megszüntetése",
  login: "Bejelentkezés",
  edit: "Szerkesztés",
  delete: "Törlés",
  open: "Megnyitás",
  view: "Megtekintés",
};
