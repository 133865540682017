<template>
  <div v-if="!params.Hidden">
    <div v-if="params.Title" v-html="params.Title[language]"></div>
    <div v-if="params.Description">
      <div
        class="label-description"
        v-html="params.Description[language]"
      ></div>
    </div>
  </div>
</template>
<script>
export default {
  name: "LabelComponent",
  props: {
    params: Object,
    language: String,
  },
};
</script>
<style scoped>
.label-description {
  font-size: 14px;
  color: #00000099;
}
</style>
