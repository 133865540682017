import { ClientService, RequestMethod } from "../client-service";
import { RequestResponseLogic } from "../Ui/request-response";

const USER_DOCUMENTS = "/document/user";
const USER_DOCUMENT_FILE = "/document/user/file";

const EESZT_SYNC = "/document/user/eeszt_sync";

export const DocumentsLogic = {
  //user dokumentum listájának kérése
  async getProfileDocuments(params) {
    let result = await ClientService.sendRequest(
      RequestMethod.GET,
      USER_DOCUMENTS,
      params
    );
    return await RequestResponseLogic.checkRequestResponse(result);
  },
  //beteg dokumentum listájának kérése
  async getPatientProfileDocuments(patientId, params) {
    let result = await ClientService.sendRequest(
      RequestMethod.GET,
      USER_DOCUMENTS + "/" + patientId,
      params
    );
    return await RequestResponseLogic.checkRequestResponse(result);
  },
  //dokumentum (infók) létrehozása
  async createDocument(data, params) {
    let result = await ClientService.sendRequestWithBody(
      RequestMethod.POST,
      USER_DOCUMENTS,
      params,
      data
    );
    return await RequestResponseLogic.checkRequestResponse(result);
  },
  //file feltöltése
  async uploadDocument(file, documentId, params) {
    let result = await ClientService.sendRequestWithFile(
      RequestMethod.PUT,
      USER_DOCUMENT_FILE + "/" + documentId,
      params,
      file
    );
    return await RequestResponseLogic.checkRequestResponse(result);
  },
  //user dokumentumainak letöltése
  async downloadProfileDocument(documentId, params) {
    let result = await ClientService.sendRequest(
      RequestMethod.GET,
      USER_DOCUMENT_FILE + "/" + documentId,
      params
    );
    const responseBody = await RequestResponseLogic.checkRequestResponse(
      result,
      false
    );
    return responseBody.Data;
  },
  //beteg dokumentumának letöltése
  async downloadPatientProfileDocument(documentId, patientId, params) {
    let result = await ClientService.sendRequest(
      RequestMethod.GET,
      USER_DOCUMENT_FILE + "/" + patientId + "/" + documentId,
      params
    );
    const responseBody = await RequestResponseLogic.checkRequestResponse(
      result,
      false
    );
    return responseBody.Data;
  },
  //user dokumentumának törlése
  async deleteDocument(documentId, params) {
    let result = await ClientService.sendRequest(
      RequestMethod.DELETE,
      USER_DOCUMENTS + "/" + documentId,
      params
    );
    return await RequestResponseLogic.checkRequestResponse(result);
  },
  //user eeszt szinkron
  async eesztSync(params) {
    let result = await ClientService.sendRequest(
      RequestMethod.GET,
      EESZT_SYNC,
      params
    );
    return await RequestResponseLogic.checkRequestResponse(result);
  },

  async eesztSyncPatientProfile(patientId, params) {
    let result = await ClientService.sendRequest(
      RequestMethod.GET,
      EESZT_SYNC + "/" + patientId,
      params
    );
    return await RequestResponseLogic.checkRequestResponse(result);
  },
};
