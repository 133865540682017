import { EhssClient, RequestMethod } from '@/backend/ehss-client'
import { RequestResponseLogic } from '@/logic/ui/request-response'

const PATIENT_LIST_WITH_DATA = '/relationship/patient_list'
const PATIENT_LIST = '/relationship'


export const PatientConnectionLogic = {
    //orvos praxisába tartozó betegek listája
    async getPatientList(params) {
        let result = await EhssClient.sendRequest(RequestMethod.GET, PATIENT_LIST_WITH_DATA, params)
        return await RequestResponseLogic.checkRequestResponse(result)
    },
    //az orvoshoz új beteg kapcsolat mentés
    async createPatientRelationship(body, params) {
        let result = await EhssClient.sendRequestWithBody(RequestMethod.POST, PATIENT_LIST, params, body)
        return await RequestResponseLogic.checkRequestResponse(result)
    },
    async setPatientRelationship(relationshipId, body, params) {
        let result = await EhssClient.sendRequestWithBody(RequestMethod.PATCH, PATIENT_LIST + '/' + relationshipId, params, body)
        return await RequestResponseLogic.checkRequestResponse(result)
    },
    async deleteRelationship(relationshipId, params) {
        let result = await EhssClient.sendRequest(RequestMethod.DELETE, PATIENT_LIST + '/' + relationshipId, params)
        return await RequestResponseLogic.checkRequestResponse(result)
    },

}
