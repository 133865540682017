export default {
  state: {
    basePageTitle: "Medtronic eGYSE",
    pageTitle: null,
  },
  mutations: {
    setPageTitle(state, payload) {
      state.pageTitle = payload;
    },
  },
  actions: {
    setPageTitle: ({ commit }, payload) => {
      commit("setPageTitle", payload);
    },
  },
};
