//Project
import ClinRegSys from "../License/ClinRegSys";

//Module
import Research from "../Modules/Research";
import Document from "../Modules/Document";
import Task from "../Modules/Task";
import Form from "../Modules/Form";
import Workflow from "../Modules/Workflow";

export default {
  install(Vue) {
    //Modul use
    Vue.use(Task);
    Vue.use(Form);
    Vue.use(Document);
    Vue.use(Research);
    Vue.use(Workflow);
    //Project use
    Vue.use(ClinRegSys);

    //Beteglista betöltés
    Vue.prototype.$patientListLoadType =
      Vue.prototype.$enums.SystemParameters.ParticipantListLoadType.JustRelationship;

    Vue.prototype.$productSettings = {
      defaultLang: "hu",
      hasLangSwitch: false,
      hasRegistration: false,
      specialist: {
        participantListLoadType:
          Vue.prototype.$enums.SystemParameters.ParticipantListLoadType
            .JustRelationship.Value,
        canCreateProjectRelation: false,
        canRemoveProjectRelation: false,
        canAddParticipantToProject: false,
        canRemoveParticipantFromProject: false,
        canCreateParticipant: true,
        canDeleteParticipant: false,
        hasParticipantTag: false,
        canCreateTag: false,
        createdTagType:
          Vue.prototype.$enums.SystemParameters.TagManagedType.Both.Value,
      },
      partner: {
        participantListLoadType:
          Vue.prototype.$enums.SystemParameters.ParticipantListLoadType
            .JustRelationship.Value,
        canCreateProjectRelation: false,
        canRemoveProjectRelation: false,
        canAddParticipantToProject: false,
        canRemoveParticipantFromProject: false,
        canCreateParticipant: true,
        canDeleteParticipant: true,
        hasParticipantTag: false,
        canCreateTag: false,
        createdTagType:
          Vue.prototype.$enums.SystemParameters.TagManagedType.Both.Value,
      },
    };
  },
};
