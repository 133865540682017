import { ClientService, RequestMethod } from "../client-service";
import { RequestResponseLogic } from "../Ui/request-response";

const PROJECT = "/project";
const PROJECT_DETAILS = "/project/details";
const PARTICIPANT = "participant";
const SUB_USER = "subordinate_user";
const PARTICIPANT_RELATIONSHIP = "participant/relation";
const DOCTOR_PROGRESS = "doctor_progress";
const MANAGER_PROGRESS = "manager_progress";
const ITEM = "item";

export const ProjectLogic = {
  async createProject(body, params) {
    let result = await ClientService.sendRequestWithBody(
      RequestMethod.POST,
      PROJECT,
      params,
      body
    );
    return await RequestResponseLogic.checkRequestResponse(result);
  },
  async getProjects(params) {
    let result = await ClientService.sendRequest(
      RequestMethod.GET,
      PROJECT,
      params
    );
    return await RequestResponseLogic.checkRequestResponse(result);
  },
  async getProjectDetails(params) {
    let result = await ClientService.sendRequest(
      RequestMethod.GET,
      PROJECT_DETAILS,
      params
    );
    return await RequestResponseLogic.checkRequestResponse(result);
  },
  async setProject(body, projectId, params) {
    let result = await ClientService.sendRequestWithBody(
      RequestMethod.PATCH,
      PROJECT + "/" + projectId,
      params,
      body
    );
    return await RequestResponseLogic.checkRequestResponse(result);
  },
  async deleteProject(projectId, params) {
    let result = await ClientService.sendRequest(
      RequestMethod.DELETE,
      PROJECT + "/" + projectId,
      params
    );
    return await RequestResponseLogic.checkRequestResponse(result);
  },
  //TODO: Deprecated
  async getProjectSubordinates(projectId, params) {
    let result = await ClientService.sendRequest(
      RequestMethod.GET,
      PROJECT + `/${projectId}/` + SUB_USER,
      params
    );
    return await RequestResponseLogic.checkRequestResponse(result);
  },
  //TODO: Deprecated
  async getProjectOtherUsersSubordinates(projectId, userId, params) {
    let result = await ClientService.sendRequest(
      RequestMethod.GET,
      PROJECT + `/${projectId}/` + SUB_USER + `/${userId}`,
      params
    );
    return await RequestResponseLogic.checkRequestResponse(result);
  },
  /* async addParticipantToProject(body, projectId, params) {
    let result = await ClientService.sendRequestWithBody(
      RequestMethod.POST,
      PROJECT + `/${projectId}/` + PARTICIPANT,
      params,
      body
    );
    return await RequestResponseLogic.checkRequestResponse(result);
  }, */
  async createParticipant(projectId, body, params) {
    let result = await ClientService.sendRequestWithBody(
      RequestMethod.POST,
      PROJECT + `/${projectId}/` + PARTICIPANT,
      params,
      body
    );
    return await RequestResponseLogic.checkRequestResponse(result);
  },
  async setParticipant(projectId, participantId, body, params) {
    let result = await ClientService.sendRequestWithBody(
      RequestMethod.PATCH,
      PROJECT + `/${projectId}/` + PARTICIPANT + `/${participantId}`,
      params,
      body
    );
    return await RequestResponseLogic.checkRequestResponse(result);
  },
  async deleteParticipantFromProject(projectId, participantId, params) {
    let result = await ClientService.sendRequest(
      RequestMethod.DELETE,
      PROJECT + `/${projectId}/` + PARTICIPANT + `/${participantId}`,
      params
    );
    return await RequestResponseLogic.checkRequestResponse(result);
  },
  async getProjectDoctorProgress(projectId, participantId, params) {
    let result = await ClientService.sendRequest(
      RequestMethod.GET,
      PROJECT + "/" + projectId + "/" + DOCTOR_PROGRESS + "/" + participantId,
      params
    );
    return await RequestResponseLogic.checkRequestResponse(result);
  },
  async getProjectManagerProgress(projectId, participantId, params) {
    let result = await ClientService.sendRequest(
      RequestMethod.GET,
      PROJECT + "/" + projectId + "/" + MANAGER_PROGRESS + "/" + participantId,
      params
    );
    return await RequestResponseLogic.checkRequestResponse(result);
  },
  async createRelationship(body, projectId, params) {
    let result = await ClientService.sendRequestWithBody(
      RequestMethod.POST,
      PROJECT + "/" + projectId + "/" + PARTICIPANT_RELATIONSHIP,
      params,
      body
    );
    return await RequestResponseLogic.checkRequestResponse(result);
  },
  async deleteRelationship(body, projectId, params) {
    let result = await ClientService.sendRequestWithBody(
      RequestMethod.DELETE,
      PROJECT + "/" + projectId + "/" + PARTICIPANT_RELATIONSHIP,
      params,
      body
    );
    return await RequestResponseLogic.checkRequestResponse(result);
  },
  async setItem(body, projectId, params) {
    let result = await ClientService.sendRequestWithBody(
      RequestMethod.POST,
      PROJECT + "/" + projectId + "/" + ITEM,
      params,
      body
    );
    return await RequestResponseLogic.checkRequestResponse(result);
  },
  async removeItem(projectId, itemId, params) {
    let result = await ClientService.sendRequest(
      RequestMethod.DELETE,
      PROJECT + "/" + projectId + "/" + ITEM + "/" + itemId,
      params
    );
    return await RequestResponseLogic.checkRequestResponse(result);
  },
};
