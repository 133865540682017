// Hungarian locale
// import { HU } from '@/locale/hu'
import { HuBase } from "@/locale/hu/base";
import { HuAdmin } from "@/locale/hu/admin";
import { HuComponents } from "@/locale/hu/components";
import { HuEnums } from "@/locale/hu/enums";
import { HuModule } from "@/locale/hu/module";
import { HuValidate } from "@/locale/hu/validate";
import { HuRequestResponse } from "@/locale/hu/requestResponse";
import { HuHeader } from "@/locale/hu/header";
import { HuFooter } from "@/locale/hu/footer";
import { HuClient } from "@/locale/hu/client";
import { HuHealthProfessional } from "@/locale/hu/healthProfessional";
import { HuPartner } from "@/locale/hu/partner";
import { HuEeszt } from "@/locale/hu/eeszt";

// English locale
// import { EN } from '@/locale/en'
import { EnBase } from "@/locale/en/base";
import { EnAdmin } from "@/locale/en/admin";
import { EnComponents } from "@/locale/en/components";
import { EnEnums } from "@/locale/en/enums";
import { EnModule } from "@/locale/en/module";
import { EnValidate } from "@/locale/en/validate";
import { EnRequestResponse } from "@/locale/en/requestResponse";
import { EnHeader } from "@/locale/en/header";
import { EnFooter } from "@/locale/en/footer";
import { EnClient } from "@/locale/en/client";
import { EnHealthProfessional } from "@/locale/en/healthProfessional";
import { EnPartner } from "@/locale/en/partner";

// German locale
// import { DE } from '@/locale/de'
import { DeBase } from "@/locale/de/base";
import { DeAdmin } from "@/locale/de/admin";
import { DeComponents } from "@/locale/de/components";
import { DeEnums } from "@/locale/de/enums";
import { DeModule } from "@/locale/de/module";
import { DeValidate } from "@/locale/de/validate";
import { DeRequestResponse } from "@/locale/de/requestResponse";
import { DeHeader } from "@/locale/de/header";
import { DeFooter } from "@/locale/de/footer";
import { DeClient } from "@/locale/de/client";
import { DeHealthProfessional } from "@/locale/de/healthProfessional";
import { DePartner } from "@/locale/de/partner";

const hu = {
  ...HuAdmin,
  ...HuBase,
  ...HuComponents,
  ...HuEnums,
  ...HuModule,
  ...HuValidate,
  ...HuRequestResponse,
  ...HuHeader,
  ...HuFooter,
  ...HuClient,
  ...HuHealthProfessional,
  ...HuPartner,
  ...HuEeszt,
};

const en = {
  ...EnBase,
  ...EnAdmin,
  ...EnComponents,
  ...EnEnums,
  ...EnModule,
  ...EnValidate,
  ...EnRequestResponse,
  ...EnHeader,
  ...EnFooter,
  ...EnClient,
  ...EnHealthProfessional,
  ...EnPartner,
};

const de = {
  ...DeBase,
  ...DeAdmin,
  ...DeComponents,
  ...DeEnums,
  ...DeModule,
  ...DeValidate,
  ...DeRequestResponse,
  ...DeHeader,
  ...DeFooter,
  ...DeClient,
  ...DeHealthProfessional,
  ...DePartner,
};

export default {
  hu,
  en,
  de,
};
