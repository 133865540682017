import { render, staticRenderFns } from "./TableComponent.vue?vue&type=template&id=6b8134a2&scoped=true&"
import script from "./TableComponent.vue?vue&type=script&lang=js&"
export * from "./TableComponent.vue?vue&type=script&lang=js&"
import style0 from "./TableComponent.vue?vue&type=style&index=0&id=6b8134a2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../Projects/register/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b8134a2",
  null
  
)

export default component.exports