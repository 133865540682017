<template>
  <div v-if="!params.Hidden">
    <component
      :is="params.SelectType"
      :params="params"
      v-model="tempValue"
      :disabled="disabled"
      :searchable="params.SourceType == $enums.ListBoxComponentSourceType.Base"
      @search="filterList"
      :language="language"
    />
  </div>
</template>
<script>
import Single from "./ListBox/Single.vue";
import Multiple from "./ListBox/Multiple.vue";
import { BaseDataLogic } from "../../../../Logic/Backend/base-data";

export default {
  components: { Single, Multi: Multiple },
  name: "ListBoxComponent",
  data() {
    return {
      tempValue: this.value,
    };
  },
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    params: Object,
    value: [Object, Number, String, Array],
    disabled: Boolean,
    language: String,
  },
  watch: {
    tempValue(input) {
      this.$emit("change", input);
    },
  },
  methods: {
    async filterList(input) {
      if (input) {
        if (
          input.length > 2 &&
          this.$enums.BaseName[this.params.ListBoxSourcePath].FilterDictionary
        ) {
          //TODO base vizsgálat, nyelvesítve van már?
          await this.getDataFromBase({
            [this.$enums.BaseName[this.params.ListBoxSourcePath].Text +
            "." +
            this.$i18n.locale]: input,
          });
        } else if (input.length > 2) {
          //TODO base vizsgálat, nyelvesítve van már?
          await this.getDataFromBase({
            [this.$enums.BaseName[this.params.ListBoxSourcePath].Text]: input,
          });
        } else {
          await this.getDataFromBase();
        }
      } else {
        await this.getDataFromBase({ "-limit": 20 });
      }
    },
    async getDataFromBase(params) {
      var tempList = [];
      if (
        params &&
        this.params.SourceType == this.$enums.ListBoxComponentSourceType.Base
      ) {
        tempList = await this.getData(params);
      }
      if (
        this.tempValue &&
        !tempList.find((item) => item.ItemId === this.tempValue)
      ) {
        const temp = await this.getData({
          [this.$enums.BaseName[this.params.ListBoxSourcePath].Id]: this
            .tempValue,
        });
        tempList.splice(0, 0, temp[0]);
      }
      if (this.params.SelectType != "Multi") {
        tempList.splice(0, 0, {
          ItemId: null,
          Label: { [this.language]: this.$t("enums.default") },
        });
      }
      this.params.Items = tempList;
    },
    async getData(params) {
      var tempList = [];
      const getDataFromBaseResponse = await BaseDataLogic.getBaseData(
        this.params.ListBoxSourcePath,
        params
      );
      if (!getDataFromBaseResponse.Code) {
        tempList = this.convertData(getDataFromBaseResponse);
      } else {
        this.$bvToast.toast(getDataFromBaseResponse.Message, {
          title: this.$t("requestResponse.basic.errorGetList"),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
      return tempList;
    },
    convertData(baseData) {
      return baseData.map((data) => {
        return {
          Label: data[this.$enums.BaseName[this.params.ListBoxSourcePath].Text],
          ItemId: data[this.$enums.BaseName[this.params.ListBoxSourcePath].Id],
        };
      });
    },
    setItemsSequence() {
      if (
        this.params.DisplaySequence ==
        this.$enums.ListBoxComponentDisplaySequence.Random
      ) {
        this.params.Items = this.params.Items.forEach((x) => {
          x.OrderNumber = x.ItemId * Math.random() * 100;
        }).sort((a, b) => (a.OrderNumber - b.OrderNumber ? -1 : 1));
      }
    },
    async loadList() {
      if (
        this.params.SourceType == this.$enums.ListBoxComponentSourceType.Base
      ) {
        await this.getDataFromBase({ "-limit": 20 });
      } else {
        this.params.Items = JSON.parse(JSON.stringify(this.params.Items));
      }
    },
  },
  async beforeMount() {
    await this.loadList();
    this.setItemsSequence();
  },
};
</script>
