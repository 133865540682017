<template>
  <div style="margin: 10px auto">
    <router-link to="UserManager"
      ><b-button>{{ $t("base.basic.back") }}</b-button></router-link
    >
    <div>
      {{ $t("admin.userManager.accountData") }}
      <b-row cols="1" cols-md="2">
        <div class="column-element">
          <fp-input
            v-model="userData.Username"
            :label="$t('admin.userManager.username')"
            :required="true"
            :state="validation.hasError('userData.Username')"
            :error="validation.firstError('userData.Username')"
          />
        </div>
        <div class="column-element">
          <fp-select
            type="multi"
            v-model="userData.Roles"
            :label="$t('admin.userManager.userRole')"
            valueKey="Value"
            :textKeyConverter="
              (role) => {
                return $t(role.Text);
              }
            "
            :required="true"
            :items="Object.values($enums.UserRoleWithHome)"
          >
            <template #element="element">
              {{ $t(element.Text) }}
            </template>
          </fp-select>
        </div>
        <div class="column-element">
          <fp-input
            v-model="userData.Password"
            :label="$t('admin.userManager.password')"
            type="password"
            :required="true"
          />
        </div>
        <div class="column-element">
          <fp-input
            :label="$t('admin.userManager.passwordAgain')"
            type="password"
            v-model="userData.PasswordAgain"
            :required="true"
          />
        </div>
        <div class="column-element">
          <fp-input
            v-model="userData.PersonName"
            :label="$t('admin.userManager.personName')"
            :required="true"
          />
        </div>
        <div class="column-element">
          <fp-input
            v-model="userData.EmailAddress"
            :label="$t('admin.userManager.email')"
            :required="true"
            :state="validation.hasError('userData.EmailAddress')"
            :error="validation.firstError('userData.EmailAddress')"
          />
        </div>
        <div class="column-element">
          <fp-select
            type="multi"
            v-model="userData.Flags"
            :label="$t('admin.userManager.flags')"
            valueKey="Value"
            :textKeyConverter="
              (e) => {
                return $t(e.Text);
              }
            "
            :items="Object.values($enums.UserFlag)"
          >
            <template #element="element">
              {{ $t(element.Text) }}
            </template>
          </fp-select>
        </div>
      </b-row>
    </div>
    <b-button @click="saveUser">{{ $t("base.basic.save") }}</b-button>
  </div>
</template>
<script>
import { UserLogic } from "@/logic/backend/user";
export default {
  name: "NewUser",
  data() {
    return {
      userData: {
        Username: null,
        Password: null,
        PasswordAgain: null,
        Roles: [],
        PersonName: null,
        EmailAddress: null,
        Flags: [],
      },
    };
  },
  //TODO: validció
  validators: {
    "userData.Username": function(value) {
      return this.$validator
        .value(value)
        .required(this.$t("validate.requiredField"));
    },
    "userData.EmailAddress": function(value) {
      return this.$validator
        .value(value)
        .required(this.$t("validate.requiredField"))
        .email();
    },
  },
  methods: {
    async saveUser() {
      const valid = await this.$validate();
      if (valid) {
        const saveUserResponse = await UserLogic.createUser(this.userData);
        if (!saveUserResponse.Code) {
          this.$bvToast.toast(this.$t("requestResponse.basic.successSave"), {
            title: this.$t("base.basic.save"),
            variant: "success",
            solid: true,
          });
        } else {
          this.$bvToast.toast(saveUserResponse.Message, {
            title: this.$t("requestResponse.basic.errorSave"),
            variant: "danger",
            solid: true,
            AutoHideDelay: 10000,
          });
        }
      }
    },
  },
  mounted() {
    this.$store.dispatch("setPageTitle", this.$t("admin.userManager.createNewUser"));
  },
};
</script>
<style scoped>
.column-element {
  padding: 0px 10px;
}
</style>
