<template>
  <div>
    <span style="margin-left: 5px">
      <div v-if="params.Title" v-html="params.Title[language]"></div>
    </span>
    <span
      v-if="params.Required && !params.Label"
      style="color: red; font-size: 14px"
    >
      * {{ $t("Base.required") }}
    </span>
    <fp-select
      :items="params.Items"
      :description="params.Description ? params.Description[language] : null"
      :disabled="!params.Enabled != false || disabled"
      :state="isValid"
      :error="
        'Minimum: ' +
        params.Minimum +
        ', maximum: ' +
        params.Maximum +
        ' lehet megjelölni'
      "
      :searchable="searchable"
      @search="$emit('search', $event)"
      type="multi"
      v-model="tempValue"
      valueKey="ItemId"
      :textKeyConverter="
        (element) => {
          return $getLanguagedText(element.Label);
        }
      "
    >
      <template #element="element">
        {{ $getLanguagedText(element.Label) }}
      </template>
    </fp-select>
  </div>
</template>
<script>
export default {
  name: "Multiple",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    params: Object,
    value: [Object, Array, String, Number, Boolean],
    disabled: Boolean,
    searchable: Boolean,
    language: String,
  },
  data() {
    return {
      tempValue: this.value ? this.value : [],
    };
  },
  computed: {
    isValid() {
      if (this.tempValue) {
        //this.$emit("change", this.tempValue);
        return (
          this.tempValue.length <= this.params.Maximum &&
          this.tempValue.length >= this.params.Minimum
        );
      }
      return false;
    },
  },
  watch: {
    tempValue(input) {
      this.$emit("change", input);
    },
  },
};
</script>
