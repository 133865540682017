export default {
  Chart: {
    Interval: {
      Day: { Value: "Day", Text: "enums.ChartInterval.Day" },
      Week: { Value: "Week", Text: "enums.ChartInterval.Week" },
      Month: { Value: "Month", Text: "enums.ChartInterval.Month" },
      Year: { Value: "Year", Text: "enums.ChartInterval.Year" },
    },
    Size: {
      Small: {
        Value: "Small",
        Text: "Chart.sizeSmall",
        className: "chart-small",
      },
      Medium: {
        Value: "Medium",
        Text: "Chart.sizeMedium",
        className: "chart-medium",
      },
      Large: {
        Value: "Large",
        Text: "Chart.sizeLarge",
        className: "chart-large",
      },
    },
  },
};
