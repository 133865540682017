export const HuEnums = {
  enums: {
    default: "Nincs kijelölve",
    AuthenticationResult: {
      Failure: "Sikertelen",
      Success: "Sikeres",
    },
    BloodType: {
      ABm: "AB-",
      ABp: "AB+",
      Am: "A-",
      Ap: "A+",
      Bm: "B-",
      Bp: "B+",
      Zm: "0+",
      Zp: "0-",
    },
    CareType: {
      Inpatient: "Fekvőbeteg",
      Outpatient: "Járóbeteg",
    },
    ChartInterval: {
      Day: "Nap",
      Month: "Hónap",
      Week: "Hét",
      Year: "Év",
    },
    ChartSize: {
      Large: "Nagy",
      Medium: "Közepes",
      Small: "Kicsi",
    },
    Circumstances: {
      HeavyExercise: "Magas intenzitás",
      LightExercise: "Alacsony intenzitás",
      Rest: "Nyugodt állapot",
      Unknown: "Nem ismert",
    },
    DataOperation: {
      Create: "Létrehozás",
      LogicalDelete: "Logikai törlés",
      PhisicalDelete: "Fizikai törlés",
      Read: "Olvasás",
      Update: "Feltöltés",
    },
    DataSource: {
      Aeek: "OKFÖ",
      Eeszt: "EESZT",
      FocusProgram: "NFBR",
      GoogleFit: "GoogleFit",
      HealthKit: "HealthKit",
      Ogyei: "OGYEI",
      UserInput: "Felhasználói bevitel",
      Vitadock: "Vitadock",
    },
    DaysOfWeek: {
      Friday: "Péntek",
      Monday: "Hétfő",
      Saturday: "Szombat",
      Sunday: "Vasárnap",
      Thursday: "Csütörtök",
      Tuesday: "Kedd",
      Wednesday: "Szerda",
    },
    DocumentCategory: {
      Contract: "Szerződés",
      Ehr: "EHR dokumentum",
      Other: "Egyéb dokumentum",
      Permit: "Engedély",
      PersonalDocument: "Személyes dokumentum",
      Phr: "PHR dokumentum",
      Technical: "Technikai",
    },
    DocumentStatus: {
      Created: "Létrehozott",
      Uploaded: "Feltöltött",
    },
    FormCategory: {
      General: "Általános",
      Project: "Projekt",
    },
    FormStatus: {
      Active: "Aktív",
      Evaluated: "Értékelt",
      Filled: "Kitöltött",
      Inactive: "Inaktív",
      InProgress: "Kitölthető",
      UnderEvaluation: "Értékelés alatt",
    },
    MaritalStatus: {
      Divorced: "Elvált",
      Married: "Házas",
      Single: "Egyedülálló",
      Widowed: "Özvegy",
    },
    MealName: {
      Breakfast: "Reggeli",
      Dinner: "Vacsora",
      Elevenses: "Tízórai",
      Lunch: "Ebéd",
      Snack: "Nasi",
    },
    MealQuantity: {
      High: "Magas mennyiségű elfogyasztott táplálék",
      Low: "Kis mennyiségű elfogyasztott táplálék",
      Medium: "Közepes mennyiségű elfogyasztitt táplálék",
      Unknown: "Ismeretlen mennyiségű elfogyasztott táplálék",
    },
    MealRelation: {
      AfterMeal: "Étkezés után",
      BeforeMeal: "Étkezés előtt",
      Unknown: "Az étkezés ideje ismeretlen",
    },
    MedicineTimingType: {
      Biweekly: "Két hetente",
      Daily: "Naponta",
      EveryFiveDays: "Öt naponta",
      EveryFourDays: "Négy naponta",
      EverySixDays: "Hat naponta",
      EveryThreeDays: "Három naponta",
      EveryTwoDays: "Két naponta",
      Monthly: "Havonta",
      Once: "Egyszeri",
      OnDaysOfWeek: "A hét napjai",
      Weekly: "Hetente",
    },
    PhysicalActivity: {
      ExtremelyActive: "Rendkívül aktív",
      ExtremelyInactive: "Rendkívül inaktív",
      ModeratelyActive: "Mérsékelten aktív",
      Sedentary: "Ülő munka",
      VigorouslyActive: "Erősen aktív",
    },
    ProjectBaseRole: {
      Doctor: "Egészségügyi szakember",
      Manager: "Partner",
      Patient: "Ügyfél",
    },
    ProjectPermission: {
      AddDoctor:
        "Új egészségügyi szakember szerepkörű felhasználó hozzáadása a projekthez",
      AddDoctorRelation:
        "Új kapcsolat létrehozása egészségügyi szakember szerepkörű felhasználóval",
      AddItem: "Elem hozzáadása a projekthez",
      AddManager: "Új partner szerepkörű felhasználó hozzáadása a projekthez",
      AddManagerRelation:
        "Új kapcsolat létrehozása partner szerepkörű felhasználóval",
      AddPatient: "Új ügyfél szerepkörű felhasználó hozzáadása a projekthez",
      AddPatientRelation:
        "Új kapcsolat létrehozása ügyfél szerepkörű felhasználóval",
      AddSubordinateDoctor:
        "Új alárendelt egészségügyi szakember szerepkörű felhasználó hozzáadása a projekthez",
      AddSubordinateDoctorRelation:
        "Alárendelt, egészségügyi szakember szerepkörű projekt résztvevőhöz kapcsolat létrehozása",
      AddSubordinateManager:
        "Új alárendelt partner szerepkörű felhasználó hozzáadása a projekthez",
      AddSubordinateManagerRelation:
        "Alárendelt, partner szerepkörű projekt résztvevőhöz kapcsolat létrehozása",
      AddSubordinatePatient:
        "Új alárendelt ügyfél szerepkörű felhasználó hozzáadása a projekthez",
      AddSubordinatePatientRelation:
        "Alárendelt, ügyfél szerepkörű projekt résztvevőhöz kapcsolat létrehozása",
      EditBaseData: "Projekt alapadatainak szerkesztése",
      EditResearch: "Projekt kutatási tervének szerkesztése",
      EditSubordinate: "Alárendelt felhasználó szerkesztése a projektben",
      EditManager: "Partner szerepkörű felhasználó szerkesztése a projektben",
      EditDoctor:
        "Egészségügyi szakember szerepkörű felhasználó szerkesztése a projektben",
      EditPatient: "Ügyfél szerepkörű felhasználó szerkesztése a projektben",
      RemoveDoctor:
        "Egészségügyi szakember szerepkörű felhasználó eltávolítása a projektből",
      RemoveItem: "Elem eltávolítása a projektből",
      RemoveManager: "Partner szerepkörű felhasználó eltávolítása a projektből",
      RemovePatient: "Ügyfél szerepkörű felhasználó eltávolítása a projektből",
      RemoveRelation: "Kapcsolat eltávolítása a projektből",
      RemoveSubordinate: "Alárendelt felhasználó eltávolítása a projektből",
      RemoveSubordinateRelation:
        "Alárendelt kapcsolat eltávolítása a projektből",
      SeeDoctorProgress:
        "Egészségügyi szakember szerepkörű felhasználók előrehaladásának megjelenítése",
      SeeDoctors:
        "Egészségügyi szakember szerepkörű felhasználók megjelenítése",
      SeeItems: "Projekt elemek megjelenítése",
      SeeManagerProgress:
        "Partner szerepkörű felhasználók előrehaladásának megjelenítése",
      SeeManagers: "Partner szerepkörű felhasználók megjelenítése",
      SeeOwnProgress: "Saját előrehaladás megjelenítése",
      SeePatientProgress:
        "Ügyfél szerepkörű felhasználók előrehaladásának megjelenítése",
      SeePatients: "Ügyfél szerepkörű felhasználók megjelenítése",
      SeeResearch: "Kutatási terv megjelenítése",
      SeeSubordinateProgress:
        "Alárendelt résztvevők előrehaladásának megjelenítése",
    },
    ProjectTaskType: {
      CompleteWorkflow: "Végezze el a folyamatot",
      HaveDoctorCount: "Léptessen be egészségügyi szakemberek a projektbe",
      HavePatientCount: "Léptessen be ügyfeleket a projektbe",
      HaveSubordinateCount: "Léptessen be felhasználókat a projektbe",
    },
    RelationshipType: {
      Brother: "Fiútestvér",
      Father: "Apa",
      GrandFather: "Nagyapa",
      GrandMother: "Nagymama",
      Mother: "Anya",
      Sister: "Lánytestvér",
    },
    Sex: {
      Both: "Két nemű",
      Female: "Nő",
      Male: "Férfi",
      Unknown: "Nem ismert",
    },
    SkinType: {
      TypeI: "I. típus",
      TypeII: "II. típus",
      TypeIII: "III. típus",
      TypeIV: "IV. típus",
      TypeV: "V. típus",
      TypeVI: "VI. típus",
    },
    TaskPriority: {
      High: "Magas",
      Low: "Alacsony",
      Medium: "Közepes",
      Urgent: "Sürgős",
    },
    TaskStatus: {
      Added: "Hozzáadott",
      Completed: "Készen",
      Failed: "Sikertelen",
      InProgress: "Folyamatban",
      Overdue: "Lejárt",
    },
    TaskType: {
      AcquireBadge: "Jelvény megszerzés",
      AddToDiary: "Napló bejegyzés",
      DoManual: "Manuális",
      EvaluateForm: "Kérdőív kiértékelés",
      FillForm: "Kérdőív kitöltés",
      TakeMedicine: "Gyógyszer bevétel",
    },
    Unit: {
      Deciliter: "Deciliter",
      Gram: "Gramm",
      Kilogram: "Kilógramm",
      Liter: "Liter",
      Milliliter: "Milliliter",
      Pieces: "Darab",
    },
    UserFlag: {
      Inactive: "Inaktív",
      MustChangePassword: "Első beléptetésnél kötelező jelszó módosítás",
      NoLogin: "Nem léphet be",
      Impersonated: "Orvos kezeli a profilját",
    },
    UserRole: {
      Administrator: "Adminisztrátor",
      Client: "Ügyfél",
      HealthProfessional: "Egészségügyi szakember",
      Partner: "Partner",
      Supervisor: "Felelős",
      Technical: "Technikai szakember",
    },
    WorkflowStep: {
      NotificationTemplateStep: "Értesítés küldés (sablon)",
      EndStep: "VÉGE",
      NotificationStep: "Értesítés küldés",
      StartStep: "START",
      JoinControlInstance: "Csatlakozási pont",
      ForkControlInstance: "Döntési pont",
    },
    WorkflowTask: {
      BadgeTask: "Jelvény megszerzés",
      DiaryTask: "Mérés vagy naplózás",
      FillFormTask: "Űrlap vagy kérdőív kitöltés",
      ManualTask: "Egyedi feladat",
      SubWorkflowTask: "Részfolyamat végrehajtás",
    },
    ProjectStatus: {
      Planned: "Tervezett",
      Running: "Futó",
      Finished: "Befejezett",
      InProgress: "Folyamatban",
    },
    ProjectPrivacy: {
      DoubleBlind: "Dupla Vak",
      Blind: "Vak",
      None: "Publikus",
    },
    InvitationStatus: {
      Pending: "Függő",
      Accepted: "Elfogadott",
      Rejected: "Visszautasított",
    },
    InvitationRelationshipType: {
      Project: "Kapcsolat felvétele projekt orvosként",
      Chosen: "Kapcsolat felvétele választott orvosként",
      GeneralPracticioner: "Kapcsolat felvétele háziorvosként",
    },
    DoctorPatientRelationshipType: {
      GeneralPracticioner: "Háziorvos",
      Chosen: "Választott",
      Project: "Projekt",
    },
    EmailPriority: {
      NonUrgent: "Nem sűrgős",
      Normal: "Normál",
      Urgent: "Sűrgős",
    },
    UserInterfaceElement: {
      Questionnaire: "Kérdőívek",
      NotificationTemplate: "Értesítés sablonok",
      Workflow: "Protokollok",
      UserTask: "Felhasználó feladatok",
      Document: "Dokumentumtár",
      Appointment: "Időpont foglalás",
      Meeting: "Értekezlet",
      ServiceSearch: "Szolgáltatás kezelő",
      Covid19Pass: "Covid útlevél",
      TravelGuide: "Utikalauz (mobil)",
      HealthGuide: "Egészségkalauz",
    },
    ModuleName: {
      BodyWeight: "Testtömeg modul",
      Nutrition: "Táplálkozás modul",
      Exercise: "Testmozgás modul",
      Cardiology: "Kardiológiai modul",
      Diabetology: "Diabetológiai modul",
      Oncology: "Onkológiai modul",
      Pulmonology: "Pulmonológiai modul",
      Depression: "Depresszió modul",
      Asthma: "Asztma modul",
      Medicine: "Gyógyszer modul",
    },
    FunctionName: {
      BloodGlucose: "Vércukorszint napló",
      BloodPressure: "Vérnyomás napló",
      BodyWeight: "Testtömeg napló",
      Ekg: "EKG napló",
      Exercise: "Mozgás napló",
      Insulin: "Inzulin napló",
      Medicine: "Gyógyszer napló",
      Mood: "Kedély napló",
      Nutrition: "Táplálkozás napló",
      OxygenSaturation: "Véroxigénszint napló",
      Pulse: "Pulzus napló",
      RibcageMeter: "Bordakosármérés napló",
      Sleep: "Alvás napló",
    },
  },
};
