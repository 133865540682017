export default {
  title: "Cím",
  description: "Leírás",
  location: "Helyszín",
  fromDate: "{date}-tól",
  toDate: "{date}-ig",
  remark: "Remark",
  language: "Language",
  languages: "Languages",
  startDate: "Start",
  endDate: "End",
  needLanguage: "Choose a language first!",
  filter: "Filter",
  registration: "Registration",
  login: "Login",
  required: "Required",
};
