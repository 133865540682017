<template>
  <div>
    <Header />
    <router-view style="width: 95%; margin: auto" name="static" />
  </div>
</template>
<script>
import Header from "@/components/header-footer/StaticHeader.vue";
export default {
  components: {
    Header,
  },
};
</script>