const AssignableList =()=>import('./AssignableList.vue')
const FillableList =()=>import('./FillableList.vue')
const WaitForEvaluateList =()=>import('./WaitForEvaluateList.vue')

export default {
  install(Vue) {
    Vue.component("fp-questionnaire-assignable-list", AssignableList);
    Vue.component("fp-questionnaire-fillable-list", FillableList);
    Vue.component("fp-questionnaire-wait-evaluate-list", WaitForEvaluateList);
  },
};
