<template>
  <div>
    <component
      :is="params.TextType"
      :params="params"
      v-model="tempValue"
      :disabled="disabled"
      :language="language"
    />
  </div>
</template>
<script>
import Long from "./Long.vue";
import Short from "./Short.vue";
export default {
  components: { Long, Short },
  name: "String",
  data() {
    return {
      tempValue: this.value,
    };
  },
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    params: Object,
    value: [String, Number],
    disabled: Boolean,
    scoreable: Boolean,
    language: String,
  },
  watch: {
    tempValue(input) {
      this.$emit("change", input);
    },
  },
};
</script>
