export const HuAdmin = {
  admin: {
    userManager: {
      accountData: "Fiók adatok",
      username: "Felhasználónév",
      userRole: "Szerepkör",
      password: "Jelszó",
      passwordAgain: "Jelszó ismétlése",
      personName:'Név',
      email:'Email cím',
      flags:'Fiók tulajdonsága',
      createNewUser:'Új felhasználó létrehozása'
    },
  },
};
