export const DeBase = {
  base: {
    address: {
      address: "Adresse",
      building: "Hausnummer",
      city: "Stadt",
      latitude: "Breitengrad",
      longitude: "Längengrad",
      street: "Adresse",
      subBuilding: "Weitere Adressangaben",
      zip: "Postleitzahl",
    },
    basic: {
      current: "Aktuell",
      next: "Nächste",
      select: "Auswählen",
      send: "Senden",

      back: "Zurück",
      add: "Hinzufügen",
      cancel: "Abbrechen",
      cancellation: "Widerruf",
      clear: "Klar",
      clearFilter: "Leere Filter",
      close: "Schließen",
      delete: "Löschen",
      download: "Herunterladen",
      edit: "Bearbeiten",
      error: "Error",
      exit: "Verlassen",
      export: "Export",
      filling: "Ausfüllen",
      filter: "Filter",
      import: "Importieren",
      loading: "Wird geladen...",
      underDevelopment: "In Bearbeitung",
      unit: "Maßeinheit",
      upload: "Hochladen",
      value: "Wert",
      view: "Ansicht",
      previous: "Vorhergehend",
      save: "Speichern",
      search: "Suche",
      selectSomeElement: "{piece} ausgewählter Artikel",
      share: "Share",
      speach: "Diktat",
      stop: "Stopp",
      sync: "Synchronisation",
      update: "Aktualisieren",
      login: "Anmeldung",
    },
    calendar: {
      daily: "Täglich",
      date: "Datum",
      day: "Tag",
      from: "Vom {date}",
      month: "Monat",
      monthly: "Monatlich",
      time: "Zeit",
      to: "Bis {date}",
      today: "Heute",
      week: "Woche",
      weekly: "Wöchentlich",
      year: "Jahr",
      yearly: "Jährlich",
      bootstrap: {
        labelPrevDecade: "Vorheriges Jahrzehnt",
        labelPrevYear: "Vorheriges Jahr",
        labelPrevMonth: "Vorheriger Monat",
        labelCurrentMonth: "Aktueller Monat",
        labelNextMonth: "Nächster Monat",
        labelNextYear: "Nächstes Jahr",
        labelNextDecade: "Nächstes Jahrzehnt",
        labelToday: "Heute",
        labelSelected: "Ausgewähltes Datum",
        labelNoDateSelected: "Kein Datum gewählt",
        labelCalendar: "Kalender",
        labelNav: "Kalendernavigation",
        labelHelp: "Mit den Pfeiltasten durch den Kalender navigieren",
      },
    },
    module: "Modul",
    noData: "Keine Elemente zum Anzeigen",
    pleaseSelect: "Bitte auswählen",
    title: "Fokusprogramm",
    usernameEmail: "Benutzerkonto (E-Mail)",
    password: "Passwort",
  },
};
