<template>
  <div style="padding: 100px 25px 0px">
    <div style="text-align: center">
      <div>EESZT bejelentkezés folyamatban</div>
      <div>
        Sikeres bejelentkezés esetén az oldal automatikusan bezáródik a folyamat
        végén, ne zárja be amíg nem végez!
      </div>
      <div>
        Hiba esetén ellenőrizze, hogy a saját eSzemélyije van-e bedugva az
        olvasóba, valamint helyesen helyezte-e be az igazolványát.
      </div>
      <b-spinner />
    </div>
  </div>
</template>
<script>
import { AuthLogic } from "@/logic/backend/auth";
export default {
  name: "EszigRedirect",
  methods: {
    async loginEeszt(token) {
      const body = { RidToken: token };
      const loginEesztResponse = await AuthLogic.loginEeszt(body);
      if (!loginEesztResponse.Code) {
        this.$store.dispatch(
          "setHasEesztToken",
          this.$loggedUser.HasEesztToken
        );
        window.close();
        //this.$router.go(-1);
      } else {
        this.$bvToast.toast(loginEesztResponse.Message, {
          title: "Hiba történt a bejelentkezés során!",
          variant: "danger",
          solid: true,
        });
      }
    },
  },
  async mounted() {
    console.log(this.$route.query);
    if (this.$route.query["id_token"]) {
      await this.loginEeszt(this.$route.query["id_token"]);
    }
  },
};
</script>
