import { ClientService, RequestMethod } from '../client-service'
import { RequestResponseLogic } from '../Ui/request-response'

const USER_TASK = '/user_task'
const TASK_COMPLETE = '/user_task/complete'
const TASK_UNCOMPLETE = '/user_task/uncomplete'

export const UserTaskLogic = {
    async getTasks(params) {
        let result = await ClientService.sendRequest(RequestMethod.GET, USER_TASK, params)
        return await RequestResponseLogic.checkRequestResponse(result)
    },
    async completeTask(taskId, params) {
        let result = await ClientService.sendRequest(RequestMethod.POST, TASK_COMPLETE + '/' + taskId, params)
        return await RequestResponseLogic.checkRequestResponse(result)
    },
    async uncompleteTask(taskId, params) {
        let result = await ClientService.sendRequest(RequestMethod.POST, TASK_UNCOMPLETE + '/' + taskId, params)
        return await RequestResponseLogic.checkRequestResponse(result)
    }
}