export const DeComponents = {
  components: {
    labor: {
      loinc: "LOINC-Code",
      refMax: "maximaler Referenzbereich",
      refMin: "minimaler Referenzbereich",
    },
    selectOrWrite: "Wählen Sie aus der Liste aus oder geben Sie freihand ein.",
    table: {
      closeAllGroup: "Alle Gruppen schließen",
      format: "Format",
      name: "Dokumentenname",
      openAllGroup: "Alle Gruppen öffnen",
      type: "Typ",
      uploadDate: "Ladedatum",
      uploadDoc: "Dokument hochladen",
      selectFile: "Dateiauswahl",
      fileType: "Dateityp",
      eesztSync: "Synchronisation mit Partnersoftware",
      selectAll: "Alle auswählen",
      unselectAll: "Auswahl löschen",
    },
    form: {
      oneDocumentUploaded: "{count} hochgeladene Dokumente",
      noSelectedDocument: "Kein Dokument ausgewählt",
    },
    formEvaulating: {
      manualEvaluation: "Textliche Auswertung",
      short: "Kurztextauswertung",
      long: "Langtextauswertung",
      note: "Kommentar",
      autoResult: "Ergebnis der automatischen Auswertung",
    },
    taskTable: {
      priority: "Priorität",
      status: "Status",
      type: "Art",
      startDate: "Anfangsdatum",
      deadline: "Frist",
      task: "Aufgabe: {description}",
      taskCompleted: "Aufgabe erledigt",
      taskUncompleted: "Eintrag rückgängig machen",
    },
    changePassword: {
      title: "Passwortänderung",
      old: "Altes Passwort",
      new: "Neues Passwort",
      again: "Wiederholen Sie das Passwort",
    },
    header: {
      errorAccountName: "Konto",
    },
    dictate: {
      addNewDoc: "Neues Dokument hinzufügen",
      textGenerate: "Text generieren",
      inProgress: "In Bearbeitung...",
      notSupported: "Dieser Browser unterstützt keine Audiowiedergabe. :(",
      writedText: "Geschriebener Text",
      docName:
        "Name des zu erstellenden Textdokuments (keine Erweiterung erforderlich)",
      fileName: "Dateiname",
      saveFile: "Speichern unter",
      uploadPHR: "Als PHR-Dokument hochladen",
      uploadOther: "Als anderes Dokument hochladen",
      writedTextDescription: "Der Text ist frei editierbar.",
      startRecord: "Starten Sie die Aufnahme",
      stopRecord: "Beenden Sie die Aufnahme",
    },
    templates: {
      errorName: "Vorlage",
      newTemplate: "Fügen Sie eine neue Vorlage hinzu",
      noTemplate: "Keine Vorlage zum Anzeigen",
      lang: "Vorlagensprache",
      name: "Name der Vorlage",
      addEmail: "E-Mail-Vorlage hinzufügen",
      addSMS: "SMS-Vorlage hinzufügen",
      addPush: "PUSH-Vorlage hinzufügen",
      email: "E-Mail-Vorlage",
      sms: "SMS-Vorlage",
      push: "Push-Vorlage",
      emailSubject: "Gegenstand",
      removeEmail: "E-Mail-Vorlage entfernen",
      smsMessage: "Botschaft",
      removeSMS: "SMS-Vorlage entfernen",
      pushTitle: "Titel",
      pushMessage: "Botschaft",
      pushImg: "Symbolbild-URL",
      removePush: "Push-Vorlage entfernen",
      addToProject: "Vorlage zum Projekt hinzugefügt",
    },
  },
};
