//TODO: nevek egységesítése WebApp tag törlése
import Vue from "vue";
import VueRouter from "vue-router";
import { UserLogic } from "@/logic/backend/user";
import { AuthLogic } from "@/logic/backend/auth";
/* Static */
import Static from "@/views/static/RouterView.vue";
import Login from "@/views/static/Login.vue";
import ForgottenPassword from "@/views/static/ForgottenPassword.vue";
import Registration from "@/views/static/Registration.vue";
import NotFound from "@/views/static/NotFound.vue";
/* Admin */
import Admin from "@/views/admin/RouterView.vue";
import WebAppAdminHome from "@/views/admin/Home.vue";
/* Admin - Main menu */
import WebAppAdminProfile from "@/views/admin/main-menu/Profile.vue";
import WebAppAdminProjectRole from "@/views/admin/main-menu/ProjectRole.vue";
import WebAppAdminNewProject from "@/views/admin/main-menu/NewProject";
import WebAppAdminProject from "@/views/admin/main-menu/Project";
/* Admin - Sub menu */
import WebAppAdminNewUser from "@/views/admin/sub-menu/NewUser.vue";
import WebAppAdminUserManager from "@/views/admin/sub-menu/UserManager.vue";
/* Specialist */
import EszigRedirect from "@/views/specialist/EszigRedirect.vue";
import Specialist from "@/views/specialist/RouterView.vue";
//import WebAppSpecialistHome from "@/views/specialist/Home.vue";
/* Specialist - Menu */
import WebAppSpecialistProfile from "@/views/specialist/menu/Profile.vue";
import WebAppSpecialistPatientList from "@/views/specialist/menu/PatientList.vue";
import WebAppSpecialistQuestionnaires from "@/views/specialist/menu/Questionnaires.vue";
//import WebAppSpecialistRecipeReportManager from "@/views/specialist/menu/RecipeReportManager.vue";

// TODO: Lazy-load components if possible
// TODO: Extracting large parents to separate files might be a good idea, this is getting pretty huge

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Static",
    component: Static,
    children: [
      {
        path: "/",
        name: "Home",
        components: {
          static: Login,
        },
      },
      {
        path: "/Login",
        name: "Login",
        components: {
          static: Login,
        },
      },
      {
        path: "/Registration",
        name: "Registration",
        components: {
          static: Registration,
        },
      },
      {
        path: "/ForgottenPassword",
        name: "ForgottenPassword",
        components: {
          static: ForgottenPassword,
        },
      },
    ],
  },
  {
    path: "/eSzigRedirect",
    name: "EszigRedirect",
    component: EszigRedirect,
  },
  {
    path: "/Admin",
    name: "Admin",
    component: Admin,
    props: true,
    children: [
      {
        path: "",
        name: "WebAppAdminHome",
        components: {
          admin: WebAppAdminHome,
        },
      },
      {
        path: "Home",
        name: "WebAppAdminHome",
        components: {
          admin: WebAppAdminHome,
        },
      },
      {
        path: "Profile",
        name: "WebAppAdminProfile",
        components: {
          admin: WebAppAdminProfile,
        },
        props: {
          admin: true,
        },
      },
      {
        path: "ProjectRole",
        name: "WebAppAdminProjectRole",
        components: {
          admin: WebAppAdminProjectRole,
        },
        props: {
          admin: true,
        },
      },
      {
        path: "NewUser",
        name: "WebAppAdminNewUser",
        components: {
          admin: WebAppAdminNewUser,
        },
        props: {
          admin: true,
        },
      },
      {
        path: "UserManager",
        name: "WebAppAdminUserManager",
        components: {
          admin: WebAppAdminUserManager,
        },
        props: {
          admin: true,
        },
      },
      {
        path: "NewProject",
        name: "WebAppAdminNewProject",
        components: {
          admin: WebAppAdminNewProject,
        },
        props: {
          admin: true,
        },
      },
      {
        path: "Project",
        name: "WebAppAdminProject",
        components: {
          admin: WebAppAdminProject,
        },
        props: {
          admin: true,
        },
      },
    ],
    meta: ["Administrator"],
  },
  {
    path: "/Specialist",
    name: "Specialist",
    component: Specialist,
    props: true,
    children: [
      {
        path: "",
        name: "WebAppSpecialistHome",
        components: {
          specialist: WebAppSpecialistPatientList,
        },
      },
      {
        path: "Home",
        name: "WebAppSpecialistHome",
        components: {
          specialist: WebAppSpecialistPatientList,
        },
      },
      {
        path: "PatientList",
        name: "WebAppSpecialistPatientList",
        components: {
          specialist: WebAppSpecialistPatientList,
        },
        props: {
          specialist: true,
        },
      },
      {
        path: "Profile",
        name: "WebAppSpecialistProfile",
        components: {
          specialist: WebAppSpecialistProfile,
        },
        props: {
          specialist: true,
        },
      },
      {
        path: "Questionnaires",
        name: "WebAppSpecialistQuestionnaires",
        components: {
          specialist: WebAppSpecialistQuestionnaires,
        },
        props: {
          specialist: true,
        },
      },
    ],
    meta: ["Supervisor", "HealthProfessional", "Administrator", "Technical"],
  },

  {
    path: "*",
    component: NotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const toParentRoute = to.matched.find((m) => Array.isArray(m.meta));
  var authorize = null;
  if (Array.isArray(to.meta)) {
    authorize = to.meta;
  } else if (toParentRoute) {
    authorize = toParentRoute.meta;
  }
  const token = AuthLogic.canStartRequest();
  const loggedUserResponse = UserLogic.getSignedUser();
  //TODO: ha van token de valamiért nincs signedUser akkor az kell újra generálni
  if (!token || !loggedUserResponse) {
    const staticPage = to.matched.find((x) => x.name == "Static");
    //nincs érvényes token
    if (to.name != "Login" && !staticPage) {
      /* var url = to.path;
      const queriesArray = Object.keys(to.query);
      if (queriesArray.length > 0) {
        url = url + "?";
        queriesArray.forEach((key) => (url += "&" + key + "=" + to.query[key]));
      } */
      //ha nem a Login oldalon van, akkor érvénytelen token esetén a Login oldalra irányít
      AuthLogic.clearSessionStorage();
      return next({ name: "Login" /* , query: { returnUrl: url }  */ });
    }
  }
  if (Array.isArray(authorize)) {
    //ha van a megnyitni kívánt oldalnak a metá-ban szerepkörök
    if (
      authorize.length &&
      loggedUserResponse &&
      !authorize.some((auth) => loggedUserResponse.Roles.includes(auth))
    ) {
      //ha a szerepkörökben a bejelentkezett felhasználó szerepköre nem szerepel akkor vissza irányít a Menube
      const loggedUserHomePage =
        Vue.prototype.$enums.UserRoleWithHome[loggedUserResponse.SelectedRole];

      return next({ name: loggedUserHomePage.Home });
    }
  }
  if (!Vue.prototype.$loggedUser && loggedUserResponse) {
    Vue.prototype.$loggedUser = loggedUserResponse;
  }

  if (
    Vue.prototype.$loggedUser &&
    to.path.split("/")[1] != from.path.split("/")[1]
  ) {
    const newRole = Object.values(Vue.prototype.$enums.UserRoleWithHome).find(
      (role) => role.Home.includes(to.path.split("/")[1])
    );
    if (newRole) {
      Vue.prototype.$loggedUser.SelectedRole = newRole.Value;
      UserLogic.saveLoginUser(Vue.prototype.$loggedUser);
    }
  }
  //ha van token és nincs jogosultság korlátozás (metában nincs szerepkör)
  next();

  if (from.name != to.name) {
    window.scrollTo(0, 0);
  }
});

export default router;
