export const EnPartner = {
  partner: {
    documents: {
      errorName: "document",
    },
    home: {
      dailyTask: "Daily tasks",
      partnerProgress: "Participant's progress in the project",
      progress: "Progress",
      projectProgress: "Project progress",
      taskDescription: "Description",
      taskPriority: "Priority",
      taskStatus: "Status",
      taskTitle: "To do",
      taskType: "Type",
      timeProgress: "Project progress over time",
    },
    logs: {
      table: {
        date: "Date",
        parameter: "Parameter",
        result: "Result",
        type: "Activity",
        user: "User",
      },
      title: "Operation log",
    },
    menu: {
      documents: "Document library",
      home: "Personal homepage",
      registrationParticipant: "New participant",
      templates: "Templates",
    },
    newProject: {
      controlPatientCount: "Number of control patients",
      dataSheet: {
        title: "Datasheet",
      },
      participants: {
        addParticipant: "Add participant",
        deleteParticipants: "Delete participants",
        table: {
          fields: {
            name: "Participant name",
            role: "Project role",
            selected: "Selected",
          },
        },
        title: "Participants",
      },
      roles: {
        addRole: "Add role",
        deleteRoles: "Delete roles",
        newRole: {
          baseRoles: {
            Doctor: "Doctor",
            Manager: "Manager",
            Patient: "Patient",
          },
          permissions: {
            BaseData: "Base data",
            Doctor: "Doctor",
            DoctorProgress: "Doctor progress",
            DoctorRelation: "Doctor relations",
            Doctors: "Doctors",
            Item: "Item",
            Manager: "Manager",
            ManagerProgress: "Manager progress",
            ManagerRelation: "Manager relation",
            Managers: "Managers",
            OwnProgress: "Own progress",
            Patient: "Patient",
            PatientProgress: "Patient progress",
            PatientRelation: "Patient relation",
            Patients: "Patients",
            Relation: "Relation",
            Research: "Research",
            Subordinate: "Subordinate",
            SubordinateDoctor: "Subordinate doctor",
            SubordinateDoctorRelation: "Subordinate doctor relation",
            SubordinateManager: "Subordinate manager",
            SubordinateManagerRelation: "Subordinate manager relation",
            SubordinatePatient: "Subordinate patient",
            SubordinatePatientRelation: "Subordinate patient relation",
            SubordinateProgress: "Subordinate progress",
            SubordinateRelation: "Subordinate relation",
          },
          permissionTypes: {
            add: "Add",
            edit: "Edit",
            remove: "Remove",
            see: "Read",
          },
        },
        permissions: "Permissions",
        table: {
          fields: {
            baseRoles: "Roles",
            name: "Name",
            permissions: "Permissions",
            selected: "Selected",
          },
        },
        title: "Roles",
      },
      testPatientCount: "Number of test patients",
      title: "New project",
    },
    participantList: {
      communicationTab: {
        call: "Call",
        chat: "Chat",
        communication: "Communication",
        communicationTypes: "Communication types",
        email: "E-mail",
        emailAttachment: "Attachment",
        emailBody: "Email body",
        emailNew: "New email",
        emailPriority: "Priority",
        emailSubject: "Subject",
        openButton: "Display",
        operations: "Operations",
        push: "Push",
        pushNew: "New push",
        pushPhoto: "Notification image",
        pushText: "Notification text",
        pushTitle: "Notification title",
        send: "Sending",
        sendMessages: "Send a message",
        sendTime: "Time of sending",
        sms: "SMS",
        smsNew: "New SMS",
        smsText: "SMS text",
        templateMessageTypes: "Available templates",
        templateName: "Template name",
        templateSupportedLanguages: "Available languages",
        type: "Type",
      },
      documentsTab: {
        documentType: "Document type",
      },
      formsTab: {
        assignment: "Assign",
        description: "Description",
        endDate: "Completion date",
        language: "Language",
        newForm: "Add a new form",
        operations: "Operations",
        state: "Form status",
        title: "Form title",
      },
      newParticipantForm: {
        note: "Notes",
        projectRole: "Project role",
        requestResponseMessage: {
          successSave:
            "You have successfully saved the new participant to the project",
        },
        tags: "Tags",
        username: "Participant username",
      },
      participantList: {
        addNewParticipantToProject: "Add new participant to project",
        errorTagsName: "tags",
        notBirthday: "Date of birth not specified",
        notFoundParticipant: "No participants to show",
        tagFilter: "Tag filter",
      },
      participantTabs: {
        communication: "Communication",
        documents: "Documents",
        forms: "Participants forms",
        personalData: "Personal profile",
        projects: "Projects",
        questionnaires: "Participants questionnaires",
        workflow: "Processes",
      },
      personalDataTab: {
        deleteFromProject: "Delete from project",
        editProfile: "Edit profile",
        editTag: "Label editing",
        errorName: "medical profile",
      },
      questionnairesTab: {
        cancelButton: "Cancel",
        deadline: "Deadline",
        evaluatingButton: "Evaluate",
        fromDate: "Release date",
        giveAgain: "Reissue",
        operations: "Operations",
        progress: "Progress",
        questionnaire: "Questionnaire",
        requestResponseMessage: {
          successCancel: "Successful questionnaire withdrawal",
          successReassigned: "Successful reissue of questionnaire",
          successSave: "Successful saving",
        },
        state: "Questionnaire status",
        warningButton: "Warning",
      },
      tag: {
        edit: "Edit",
        editTag: "Edit tags",
        language: "Language",
        newTag: "Create new tag",
        noTags: "There are no label to display yet",
        own: "Own",
        project: "Project",
        tagColor: "Tag color",
        tagInfo: "Double-click to edit an existing label",
        tagManager: "Tag manager",
        tagName: "Tag name",
        tags: "Tags",
      },
      title: "Participants",
      workflowTab: {
        currentInProgress: "Performing step...",
        currentSteps: "Currently running process step(s)",
        errorStartedName: "process started",
        progress: "Progress",
        stepWaiting: "Process pending",
        workflow: "Process",
      },
    },
    profile: {
      address: {
        building: "House number",
        city: "City",
        latitude: "Width",
        longitude: "Length",
        street: "Name and nature of public space",
        subBuilding: "Additional title addition",
        zip: "Postcode",
      },
      chat: "Chat",
      communication: "Communication",
      departmentName: "Department's name",
      email: "E-mail",
      emailAddress: "E-mail address",
      institutionName: "Institution name",
      name: "Name",
      phone: "Phone number",
      position: "Position",
      push: "Push",
      sms: "SMS",
      title: "Profile",
      username: "Username",
    },
    projects: {
      controllGroup: "Control group",
      controlPatientCount: "Of which control patient",
      dataSheet: "Datasheet",
      description: "Description",
      dontProject: "Not connected to a project",
      filter: "Filter",
      institution: "Institution",
      none: "None",
      participants: "Participants",
      patientIn: "Number of patients involved",
      privacy: "Privacy",
      progress: "Progress",
      projectGoal: "Project goal",
      projectName: "Project name",
      projectTitle: "Project title",
      roles: "Roles",
      selectionCriteria: "Selection criteria",
      specialistCount: "Number of doctors involved",
      specialistName: "Doctor's name",
      specialists: "Doctors",
      state: "State",
      status: "Status",
      tasksProgress: "Task progress",
      testGroup: "Testg roup",
      testPatientCount: "Of which test patient",
      timestamp: "Period",
      title: "Project",
    },
    questionnaires: {
      assignable: "Assignable questionnaires",
      assignableTab: {
        createDate: "Date created",
        errorName: "assignable questionnaire",
        fillTime: "Time to complete",
        formCreatedDate: "Created date",
        formDescription: "Description",
        formLang: "Languages",
        formTitle: "Title",
        giveQuestionnaries:
          "Distribution of the selected questionnaire to {count} selected participants",
        newdeadline: "Deadline",
        newdeadlineUnit: "DAY.HH:MM:SS",
        newFormByTask: "Assigning a questionnaire as a task",
        newFormHardDeadline: "It completes the task when it expires",
        newSendDate: "Date of sending",
        noParticipant: "There are no participants to display",
        noTag: "Unlabeled",
        participantName: "Participant's name",
        participantNote: "Comment",
        supportedLanguages: "Available languages",
      },
      waitingForEvaluting: "Questionnaires awaiting evaluation",
      waitingTab: {
        deadline: "Deadline",
        filledDate: "Completion Date",
        givenDate: "Assignment Date",
        participant: "Filling participant",
        progress: "Progress",
        title: "Questionnaire title",
      },
    },
    registrationParticipant: {
      accountData: "Account data",
      email: "E-mail address",
      flags: "Account property",
      name: "Name",
      password: "Password",
      passwordAgain: "Password again",
      role: "Role",
      username: "Username",
    },
    research: {
      title: "Virtual Research Space",
    },
    tasks: {
      deadline: "Deadline",
      description: "Description",
      priority: "Priority",
      startDate: "Beginning date",
      status: "Status",
      task: "Task",
      title: "To do",
      type: "Type",
    },
    templates: {
      addEmail: "Add E-mail template",
      addPush: "Add PUSH template",
      addSMS: "Add SMS template",
      addToProject: "Template added to project",
      email: "Email template",
      emailSubject: "Subject",
      errorName: "template",
      lang: "Template language",
      name: "Name of the template",
      newTemplate: "Add a new template",
      noTemplate: "No template to display",
      push: "Push template",
      pushImg: "Icon image URL",
      pushMessage: "Message",
      pushTitle: "Title",
      removeEmail: "Remove email template",
      removePush: "Remove Push template",
      removeSMS: "Remove SMS template",
      sms: "SMS template",
      smsMessage: "Message",
    },
    workflows: {
      title: "Process store",
    },
  },
};
