export const EnEnums = {
  enums: {
    default: "Not Specified",
    AuthenticationResult: {
      Failure: "Unsuccessful",
      Success: "Successful",
    },
    BloodType: {
      ABm: "AB-",
      ABp: "AB+",
      Am: "A-",
      Ap: "A+",
      Bm: "B-",
      Bp: "B+",
      Zm: "0+",
      Zp: "0-",
    },
    CareType: {
      Inpatient: "Inpatient",
      Outpatient: "Outpatient",
    },
    ChartInterval: {
      Day: "Day",
      Month: "Month",
      Week: "Week",
      Year: "Year",
    },
    ChartSize: {
      Large: "Large",
      Medium: "Medium",
      Small: "Small",
    },
    Circumstances: {
      HeavyExercise: "High intensity movement",
      LightExercise: "Low intensity movement",
      Rest: "Relax",
      Unknown: "Unknown circumstance",
    },
    DataOperation: {
      Create: "Creation",
      LogicalDelete: "Logical deletion",
      PhisicalDelete: "Physical erasure",
      Read: "Reading",
      Update: "Uploading",
    },
    DataSource: {
      Aeek: "OKFÖ",
      Eeszt: "EESZT",
      FocusProgram: "Focus Program",
      GoogleFit: "GoogleFit",
      HealthKit: "HealthKit",
      Ogyei: "OGYEI",
      UserInput: "User input",
      Vitadock: "Vitadock",
    },
    DaysOfWeek: {
      Friday: "Friday",
      Monday: "Monday",
      Saturday: "Saturday",
      Sunday: "Sunday",
      Thursday: "Thursday",
      Tuesday: "Tuesday",
      Wednesday: "Wednesday",
    },
    DocumentCategory: {
      Contract: "Contract",
      Ehr: "EHR document",
      Other: "Other document",
      Permit: "License",
      PersonalDocument: "Personal document",
      Phr: "PHR document",
      Technical: "Technical",
    },
    DocumentStatus: {
      Created: "Created",
      Uploaded: "Uploaded",
    },
    FormCategory: {
      General: "General",
      Project: "Project",
    },
    FormStatus: {
      Active: "Active",
      Evaluated: "Evaluated",
      Filled: "Filled out",
      Inactive: "Inactive",
      InProgress: "Under filling",
      UnderEvaluation: "Under evaluation",
    },
    MaritalStatus: {
      Divorced: "Divorced",
      Married: "Married",
      Single: "Single",
      Widowed: "Widow",
    },
    MealName: {
      Breakfast: "Breakfast",
      Dinner: "Dinner",
      Elevenses: "Ten o'clock",
      Lunch: "Lunch",
      Snack: "Snack",
    },
    MealQuantity: {
      High: "High amount of food consumed",
      Low: "Small amount of food consumed",
      Medium: "Medium amount of food consumed",
      Unknown: "Unknown amount of food consumed",
    },
    MealRelation: {
      AfterMeal: "After meal",
      BeforeMeal: "Before meal",
      Unknown: "The timing of the meal is unknown",
    },
    MedicineTimingType: {
      Biweekly: "Every two weeks",
      Daily: "Daily",
      EveryFiveDays: "Every five days",
      EveryFourDays: "Every four days",
      EverySixDays: "Every six days",
      EveryThreeDays: "Every three days",
      EveryTwoDays: "Every two days",
      Monthly: "Monthly",
      Once: "Once",
      OnDaysOfWeek: "The days of the week",
      Weekly: "Weekly",
    },
    PhysicalActivity: {
      ExtremelyActive: "Extremely active",
      ExtremelyInactive: "Extremely inactive",
      ModeratelyActive: "Moderately active",
      Sedentary: "Sitting work",
      VigorouslyActive: "Very active",
    },
    ProjectBaseRole: {
      Doctor: "Healthcare professional",
      Manager: "Partner",
      Patient: "Patient",
    },
    ProjectPermission: {
      AddDoctor: "Add a new healthcare professional user to the project",
      AddDoctorRelation:
        "Establish a new relationship with a user with the role of healthcare professional",
      AddItem: "Add an item to the project",
      AddManager: "Add a new partner user to the project",
      AddManagerRelation: "Create a new connection with a partner user",
      AddPatient: "Add a new client user to the project",
      AddPatientRelation: "Create a new connection with a client user",
      AddSubordinateDoctor:
        "Add a new subordinate healthcare professional user to the project",
      AddSubordinateDoctorRelation:
        "Establish a relationship with a subordinate project participant in the role of a health professional",
      AddSubordinateManager: "Add a new child user to the project",
      AddSubordinateManagerRelation:
        "Establish a relationship with a subordinate, partner project participant",
      AddSubordinatePatient:
        "Add a new subordinate customer user to the project",
      AddSubordinatePatientRelation:
        "Establish a relationship with a subordinate, client-role project participant",
      EditBaseData: "Edit project master data",
      EditResearch: "Edit project research plan",
      RemoveDoctor: "Remove a healthcare professional user from the project",
      RemoveItem: "Remove an item from the project",
      RemoveManager: "Remove a partner user from the project",
      RemovePatient: "Remove a client user from the project",
      RemoveRelation: "Remove a connection from the project",
      RemoveSubordinate: "Remove a child relationship from the project",
      RemoveSubordinateRelation: "Remove a child relationship from the project",
      SeeDoctorProgress:
        "Display the progress of users in the role of healthcare professional",
      SeeDoctors: "Display users with the role of healthcare professional",
      SeeItems: "Display project items",
      SeeManagerProgress: "View the progress of partner users",
      SeeManagers: "Show partner users",
      SeeOwnProgress: "Show my progress",
      SeePatientProgress: "Display the progress of client users",
      SeePatients: "Display customer role users",
      SeeResearch: "Display research plan",
      SeeSubordinateProgress:
        "Display the progress of subordinate participants",
    },
    ProjectTaskType: {
      CompleteWorkflow: "Perform the process",
      HaveDoctorCount: "Involve health professionals in the project",
      HavePatientCount: "Involve clients in the project",
      HaveSubordinateCount: "Sign up users in the project",
    },
    RelationshipType: {
      Brother: "Brother",
      Father: "Father",
      GrandFather: "Grandfather",
      GrandMother: "Grandmother",
      Mother: "Mother",
      Sister: "Sister",
    },
    Sex: {
      Both: "Bisex",
      Female: "Female",
      Male: "Male",
      Unknown: "Unknown",
    },
    SkinType: {
      TypeI: "Type I",
      TypeII: "Type II",
      TypeIII: "Type III",
      TypeIV: "Type IV",
      TypeV: "Type V",
      TypeVI: "Type VI",
    },
    TaskPriority: {
      High: "High",
      Low: "Low",
      Medium: "Medium",
      Urgent: "Urgent",
    },
    TaskStatus: {
      Added: "Added",
      Completed: "Completed",
      Failed: "Failed",
      InProgress: "In progress",
      Overdue: "Expired",
    },
    TaskType: {
      AcquireBadge: "Earn a badge",
      AddToDiary: "Diary entry",
      DoManual: "Manual",
      EvaluateForm: "Questionnaire evaluation",
      FillForm: "Questionnaire completion",
      TakeMedicine: "Medication intake",
    },
    Unit: {
      Deciliter: "Deciliter",
      Gram: "Gram",
      Kilogram: "Kilogram",
      Liter: "Liter",
      Milliliter: "Milliliter",
      Pieces: "Pieces",
    },
    UserFlag: {
      Inactive: "Inactive",
      MustChangePassword: "Password change required for first login",
      NoLogin: "Can't log in",
      Impersonated: "Doctor is managing the profile",
    },
    UserRole: {
      Administrator: "Administrator",
      Client: "Customer",
      HealthProfessional: "Healthcare professional",
      Partner: "Partner",
      Supervisor: "Supervisor",
      Technical: "Technician",
    },
    WorkflowStep: {
      NotificationTemplateStep: "Send an email",
      EndStep: "END",
      NotificationStep: "Send notification",
      StartStep: "START",
      JoinControlInstance: "Connection point",
      ForkControlInstance: "Branching point",
    },
    WorkflowTask: {
      BadgeTask: "Earn a badge",
      DiaryTask: "Measure or diary entry",
      FillFormTask: "Filling in a form or questionnaire",
      ManualTask: "Unique task",
      SubWorkflowTask: "Subprocess execution",
    },
    ProjectStatus: {
      Planned: "Planned",
      Running: "Running",
      Finished: "Finished",
      InProgress: "In progress",
    },
    ProjectPrivacy: {
      DoubleBlind: "Double blind",
      Blind: "Blind",
      None: "Public",
    },
    InvitationStatus: {
      Pending: "Pending",
      Accepted: "Accepted",
      Rejected: "Rejected",
    },
    InvitationRelationshipType: {
      Project: "Creating contact by project doctor",
      Chosen: "Creating contact by doctor of choice",
      GeneralPracticioner: "Creating contact by GP",
    },
    DoctorPatientRelationshipType: {
      GeneralPracticioner: "General practitioner",
      Chosen: "Doctor of choice",
      Project: "Project doctor",
    },
    EmailPriority: {
      NonUrgent: "Non-urgent",
      Normal: "Normal",
      Urgent: "Urgent",
    },
    UserInterfaceElement: {
      Questionnaire: 'Questionnaire',
      NotificationTemplate: 'Notification template',
      Workflow: 'Workflows',
      UserTask: 'User tasks',
      Document: 'Document library',
      Appointment: 'Appointments',
      Meeting: 'Meeting',
      ServiceSearch: "Service search",
      Covid19Pass: 'Covid passport',
      TravelGuide: 'Travel guide (mobile)',
      HealthGuide: 'Health guide',
    },
    ModuleName: {
      BodyWeight: 'Testtömeg modul',
      Nutrition: 'Táplálkozás modul',
      Exercise: 'Testmozgás modul',
      Cardiology: 'Kardiológiai modul',
      Diabetology: 'Diabetológiai modul',
      Oncology: 'Onkológiai modul',
      Pulmonology: 'Pulmonológiai modul',
      Depression: 'Depresszió modul',
      Asthma: 'Asztma modul',
      Medicine: 'Gyógyszer modul',
    },
    FunctionName: {
      BloodGlucose: "Vércukorszint napló",
      BloodPressure: "Vérnyomás napló",
      BodyWeight: "Testtömeg napló",
      Ekg: "EKG napló",
      Exercise: "Mozgás napló",
      Insulin: "Inzulin napló",
      Medicine: "Gyógyszer napló",
      Mood: "Kedély napló",
      Nutrition: "Táplálkozás napló",
      OxygenSaturation: "Véroxigénszint napló",
      Pulse: "Pulzus napló",
      RibcageMeter: "Bordakosármérés napló",
      Sleep: "Alvás napló",
    },
  },
};
