<template>
  <div>
    <label>{{ $t("base.address.address") }}</label>
    <div style="margin-top: -10px">
      <!-- Irányítószám -->
      <fp-input
        :disabled="disabled"
        :label="$t('base.address.zip')"
        v-model="tempValue.ZipCode"
      />
      <!-- Város -->
      <fp-input
        :disabled="disabled"
        :label="$t('base.address.city')"
        v-model="tempValue.City"
      />
      <!-- Közterület neve és jellege -->
      <fp-input
        :disabled="disabled"
        :label="$t('base.address.street')"
        v-model="tempValue.Street"
      />
      <!-- Házszám -->
      <fp-input
        :disabled="disabled"
        :label="$t('base.address.building')"
        v-model="tempValue.Building"
      />
      <!-- Egyéb cím kiegészítés -->
      <fp-input
        :disabled="disabled"
        :label="$t('base.address.subBuilding')"
        v-model="tempValue.SubBuilding"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: "Address",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: Object,
    disabled: Boolean,
  },
  data() {
    return {
      tempValue: this.value
        ? this.value
        : {
            ZipCode: null,
            City: null,
            Street: null,
            Building: null,
            SubBuilding: null,
            Latitude: 0,
            Longitude: 0,
          },
      defaultValue: {
        ZipCode: null,
        City: null,
        Street: null,
        Building: null,
        SubBuilding: null,
        Latitude: 0,
        Longitude: 0,
      },
    };
  },
  watch: {
    value(input) {
      this.tempValue = input
        ? input
        : JSON.parse(JSON.stringify(this.defaultValue));
    },
    tempValue(input) {
      this.$emit("change", input);
    },
  },
};
</script>
