const Charts = () => import("./Charts.vue");
const Communication = () => import("./Communication.vue");
const Documents = () => import("./Documents.vue");
const Forms = () => import("./Forms.vue");
const HealthProfessionalProfile = () =>
  import("./HealthProfessionalProfile.vue");
const PartnerProfile = () => import("./PartnerProfile.vue");
const MedicalProfile = () => import("./MedicalProfile.vue");
const PersonalProfile = () => import("./PersonalProfile.vue");
const Prescriptions = () => import("./Prescriptions.vue");
const Questionnaires = () => import("./Questionnaires.vue");
const Workflow = () => import("./Workflow.vue");
const NotAllow = () => import("../../NotAllow.vue");

export default {
  install(Vue) {
    //Modul komponensek regisztrálása

    /* //Komponensek Modul függőséggel
    
    
    //Charts
    if (
      Vue.prototype.$activeModules &&
      Vue.prototype.$activeModules.includes("Chart")
    ) {
      Vue.component("fp-participant-chart", Charts);
    } else {
      Vue.component("fp-participant-chart", NotAllow);
    }
    //Template
    if (
      Vue.prototype.$activeModules &&
      Vue.prototype.$activeModules.includes("Template")
    ) {
      Vue.component("fp-participant-communication", Communication);
    } else {
      Vue.component("fp-participant-communication", NotAllow);
    }
    //Document
    if (
      Vue.prototype.$activeModules &&
      Vue.prototype.$activeModules.includes("Document")
    ) {
      Vue.component("fp-participant-document", Documents);
    } else {
      Vue.component("fp-participant-document", NotAllow);
    }
    //Forms
    if (
      Vue.prototype.$activeModules &&
      Vue.prototype.$activeModules.includes("Form")
    ) {
      Vue.component("fp-participant-form", Forms);
    } else {
      Vue.component("fp-participant-form", NotAllow);
    }
    //Prescriptions
    if (
      Vue.prototype.$activeModules &&
      Vue.prototype.$activeModules.includes("Prescription")
    ) {
      Vue.component("fp-participant-prescription", Prescriptions);
    } else {
      Vue.component("fp-participant-prescription", NotAllow);
    }
    //Questionnaires
    if (
      Vue.prototype.$activeModules &&
      Vue.prototype.$activeModules.includes("Questionnaire")
    ) {
      Vue.component("fp-participant-questionnaire", Questionnaires);
    } else {
      Vue.component("fp-participant-questionnaire", NotAllow);
    }
    //Workflow
    if (
      Vue.prototype.$activeModules &&
      Vue.prototype.$activeModules.includes("Workflow")
    ) {
      Vue.component("fp-participant-workflow", Workflow);
    } else {
      Vue.component("fp-participant-workflow", NotAllow);
    } */

    //TODO: fenti formátum használata?
    Vue.component("fp-participant-chart", Charts);
    Vue.component("fp-participant-communication", Communication);
    Vue.component("fp-participant-document", Documents);
    Vue.component("fp-participant-form", Forms);
    Vue.component("fp-participant-prescription", Prescriptions);
    Vue.component("fp-participant-questionnaire", Questionnaires);
    Vue.component("fp-participant-workflow", Workflow);

    //Nincs modul kapcsolódás
    Vue.component("fp-participant-medical-profile", MedicalProfile);
    Vue.component("fp-participant-personal-profile", PersonalProfile);
    Vue.component(
      "fp-participant-health-prof-profile",
      HealthProfessionalProfile
    );
    Vue.component("fp-participant-partner-profile", PartnerProfile);
  },
};
