<template>
  <div class="medical-profile-data">
    <div class="fp-boubles-title">
      {{ $t("client.profile.medicalData.implant") }}
    </div>
    <div v-if="arrayData && !disabled">
      <div class="fp-boubles" v-for="group in groupedArrays" :key="group.key">
        <fp-bouble-modal
          :title="group.key"
          :length="group.value.length"
          @openDetails="$set(group, 'OpenDetails', true)"
        />
        <!-- Lista megtekintés -->
        <fp-form-modal v-model="group.OpenDetails" :title="group.key">
          <template #content>
            <b-list-group
              v-if="group.value.length > 0"
              class="fp-list-box-group"
            >
              <b-list-group-item
                v-for="(element, index) in group.value"
                :key="index"
                class="fp-group-item"
                :class="
                  group.value.length == 1
                    ? ' single-element '
                    : (index == group.value.length - 1
                        ? ' last-element '
                        : ' not-last-element ') + ' multiple-element '
                "
              >
                <div>
                  <b>{{ $t("client.profile.medicalData.name") }}: </b
                  >{{ element.Name }}<br />
                  <b>{{ $t("client.profile.medicalData.insertionDate") }}: </b
                  >{{ $convertDateToString(element.StartDate, "YYYY-MM-DD") }}
                  <br />
                  <b> {{ $t("client.profile.medicalData.exceptionDate") }}: </b>
                  {{ $convertDateToString(element.EndDate, "YYYY-MM-DD") }}
                </div>
                <div
                  class="item-delete-icon"
                  v-b-tooltip.hover="$t('base.basic.delete')"
                  :style="
                    disabled
                      ? 'display:none'
                      : 'vertical-align: bottom; margin: auto 0px auto 40px'
                  "
                >
                  <b-icon
                    icon="x-circle"
                    style="cursor: pointer"
                    @click="deleteElement(element)"
                  />
                </div>
              </b-list-group-item>
            </b-list-group>
          </template>
        </fp-form-modal>
      </div>
      <div class="fp-plus-icon">
        <b-icon @click="showCreateNew = true" icon="plus-circle-fill" />
      </div>
    </div>
    <div class="fp-empty" v-else>
      {{ $t("base.noData") }}
    </div>
    <!-- Új elem felvétele -->
    <fp-form-modal
      v-model="showCreateNew"
      :title="$t('client.profile.medicalData.newImplant')"
    >
      <template #content>
        <fp-input
          :label="$t('client.profile.medicalData.name')"
          v-model="newElement.Name"
        />
        <fp-input
          :label="$t('client.profile.medicalData.insertionDate')"
          v-model="newElement.StartDate"
        />
        <fp-input
          :label="$t('client.profile.medicalData.exceptionDate')"
          v-model="newElement.EndDate"
        />
        <b-button @click="addNewElement">{{ $t("base.basic.save") }}</b-button>
      </template>
    </fp-form-modal>
  </div>
</template>
<script>
//import { HelperMethods } from "@/logic/ui/helpers";
export default {
  name: "Implant",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: Array,
    disabled: Boolean,
  },
  data() {
    return {
      arrayData: this.value,
      groupedArrays: [],
      showCreateNew: false,
      newElement: {
        Name: null,
        StartDate: null,
        EndDate: null,
      },
      defaultNewElement: {
        Name: null,
        StartDate: null,
        EndDate: null,
      },
    };
  },
  watch: {
    value(input) {
      this.arrayData = input;
      this.groupedArrays = this.checkDuplicateName();
    },
  },
  methods: {
    /* Ellenőrzi és rendezi a diplukált csoportokat a bouble nevek alapján.
       Ellátja az elemeke ElementId-val, ami a törléshez szükséges az elem beazonosításához. */
    //duplikáció vizsgálat
    checkDuplicateName() {
      //index alapból 0
      var index = 0;
      //végig megyünk a lista minden elemén és adunk nekik Element Id-t
      this.arrayData.forEach((x) => (x.ElementId = index++));
      //csoportosítjuk a lista elemeket megadott változó alapján
      var tempMap = this.$groupByMostEfficient(
        this.arrayData,
        (listE) => listE.Name
      );
      //a kapott csoportosított map átalakítása listává
      var tempArray = [];
      tempMap.forEach((value, key) => {
        if (key) {
          tempArray.push({ key: key, value: value });
        }
      });
      //vissza érünk a generált listával
      return tempArray;
    },
    deleteElement(elem) {
      const index = this.arrayData.indexOf(elem);
      if (index >= 0) {
        this.arrayData.splice(index, 1);
        this.groupedArrays = this.checkDuplicateName();
        this.$emit("change", this.arrayData);
      }
    },
    addNewElement() {
      this.newElement.StartDate = this.$convertDateToString(
        this.newElement.StartDate
      );
      this.newElement.EndDate = this.$convertDateToString(
        this.newElement.EndDate
      );
      this.arrayData.push(this.newElement);
      this.groupedArrays = this.checkDuplicateName();
      this.$emit("change", this.arrayData);
      this.showCreateNew = false;
      this.newElement = JSON.parse(JSON.stringify(this.defaultNewElement));
    },
  },
  beforeMount() {
    this.groupedArrays = this.checkDuplicateName();
  },
};
</script>