export default {
  title: "Cím",
  name: "Megnevezés",
  description: "Leírás",
  location: "Helyszín",
  fromDate: "{date}-tól",
  toDate: "{date}-ig",
  remark: "Megjegyzés",
  language: "Nyelv",
  languages: "Nyelvek",
  startDate: "Kezdés",
  endDate: "Vége",
  needLanguage: "Először válasszon nyelvet!",
  filter: "Szűrő",
  registration: "Regisztráció",
  login: "Bejelentkezés",
  required: "Kötelező",
};
