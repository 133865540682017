export const StoreManager = {
  sessionStoragePut(key, object) {
    sessionStorage.setItem(key, JSON.stringify(object));
  },

  sessionStorageGet(key) {
    const storedData = sessionStorage.getItem(key);
    if (storedData && storedData != 'undefined' && storedData != 'null') {
      return JSON.parse(storedData);
    }
    return null;
  },

  sessionStorageCheck(key) {
    return sessionStorage.getItem(key) !== null;
  },

  sessionStorageDelete(key) {
    sessionStorage.removeItem(key);
  },
  localStoragePut(key, object) {
    localStorage.setItem(key, JSON.stringify(object));
  },

  localStorageGet(key) {
    const storedData = localStorage.getItem(key);
    if (storedData && storedData != 'undefined' && storedData != 'null') {
      return JSON.parse(storedData);
    }
    return null;
  },

  localStorageCheck(key) {
    return localStorage.getItem(key) !== null;
  },

  localStorageDelete(key) {
    localStorage.removeItem(key);
  },

  /*  sessionStoragePut(key, object) {
        sessionStorage.setItem(key, JSON.stringify(object))
    },

    sessionStorageGet(key) {
        return JSON.parse(sessionStorage.getItem(key))
    },

    sessionStorageCheck(key) {
        return sessionStorage.getItem(key) !== null
    },

    sessionStorageDelete(key) {
        sessionStorage.removeItem(key)
    } */
};
