<template>
  <div v-if="!params.Hidden">
    <fp-panel-card
      :title="params.Title ? params.Title[language] : null"
      :description="params.Description ? params.Description[language] : null"
      :required="params.Required"
    >
      <template #content>
        <div
          v-for="(panelValue, index) in tempValue"
          :key="'panel-component-' + panelValueIds[index]"
        >
          <fp-panel-card
            style="margin: 10px 0px"
            :isShowAddButton="
              params.Enabled != false &&
              !disabled &&
              tempValue.length <= params.MaximumComponentNumber
            "
            :isShowDeleteButton="
              params.Enabled != false &&
              !disabled &&
              tempValue.length > params.MinimumComponentNumber
            "
            @addNewElement="addNewElement(index)"
            @deleteElement="deleteElementByIndex(index)"
          >
            <template #content>
              <div
                style="margin: 10px 0px"
                v-for="(component, ComponentIndex) in params.Components"
                :key="'components-' + ComponentIndex"
              >
                <form-schema-inputs
                  :component="component"
                  :disabled="disabled"
                  :scoreable="scoreable"
                  v-model="panelValue[component.VariableName]"
                  :evaluateObjectModel="
                    tempScore[index][component.VariableName]
                  "
                  @evaluating="
                    evaluating($event, index, component.VariableName)
                  "
                  :language="language"
                ></form-schema-inputs>
              </div>
            </template>
          </fp-panel-card>
        </div>
        <div
          @click="addNewElement(0)"
          v-if="tempValue && !tempValue.length"
          class="add-button"
        >
          <b-icon style="margin: 0px" class="h2" icon="plus-circle"></b-icon>
        </div>
      </template>
    </fp-panel-card>
  </div>
</template>
<script>
//import PanelElement from "./PanelElement.vue";
export default {
  /* components: { PanelElement }, */
  name: "MultiplePanelComponent",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    params: Object,
    value: [Object, Array],
    disabled: Boolean,
    scoreable: Boolean,
    evaluateObjectModel: [Object, Array],
    language: String,
  },
  data() {
    return {
      KeyIndex: 0,
      tempValue: null,
      tempScore: null,
      panelValueIds: [],
    };
  },
  watch: {
    tempValue(input) {
      this.$emit("change", input);
    },
  },
  methods: {
    evaluating(input, index, varName) {
      this.$set(this.tempScore[index], varName, input);
      this.$emit("evaluating", this.tempScore);
    },
    addNewElement(index) {
      this.panelValueIds.splice(index + 1, 0, ++this.KeyIndex);
      this.tempValue.splice(index + 1, 0, {});
      this.tempScore.splice(index + 1, 0, {});
    },
    deleteElementByIndex(index) {
      this.panelValueIds.splice(index, 1);
      this.tempValue.splice(index, 1);
      this.tempScore.splice(index, 1);
    },
    setPanelsId() {
      if (this.value) {
        this.value.forEach(() => {
          this.panelValueIds.push(++this.KeyIndex);
        });
        return this.value;
      } else if (this.params.MinimumComponentNumber) {
        const temp = [];
        for (let i = 0; i < this.params.MinimumComponentNumber; i++) {
          const id = ++this.KeyIndex;
          temp.push({});
          this.panelValueIds.push(id);
        }
        return temp;
      } else {
        this.panelValueIds.push(++this.KeyIndex);
        return [{}];
      }
    },
  },
  mounted() {
    this.tempValue = this.setPanelsId();
    this.tempScore = this.evaluateObjectModel
      ? this.evaluateObjectModel
      : JSON.parse(JSON.stringify(new Array(this.tempValue.length).fill({})));
  },
};
</script>
<style scoped>
.add-button {
  background: var(--eh-primary-2);
  color: var(--eh-primary-5);
  border: solid 3px var(--eh-primary-5);
  border-radius: 6px;
  padding: 10px;
  width: fit-content;
  cursor: pointer;
}
span.panel-description {
  font-size: 12px;
  margin-left: 5px;
  margin-bottom: 5px;
}
.panel-box {
  border: solid 3px #627e8f;
  border-radius: 10px;
}
.panel-title-box {
  width: fit-content;
  background: #627e8f;
  border-bottom-right-radius: 10px;
  color: white;
  padding: 10px;
  padding-right: 20px;
  margin-right: 30px;
}
.panel-content-box {
  padding: 10px;
}
</style>
