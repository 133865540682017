export default {
  publicHealthcareCardNumber: "Közgyógyigazolvány száma",
  publicHealthcareCardValidBy: "Közgyógyigazolvány érvényességének a vége",
  euSocialSecurityNumber: "Európai egészségbiztosítási kártya száma",
  name: "Név",
  fullName: "Teljes név",
  ssn: "TAJ szám",
  email: "E-mail",
  emailAddress: "E-mail cím",
  password: "Jelszó",
  passwordAgain: "Jelszó megerősítés",
  registrationCode: "Regisztrációs kód",
};
