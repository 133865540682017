<template>
  <div style="margin: 20px">
    <div class="eh-flex-container">
      <fp-input
        class="eh-margin-right eh-fix-filter-input-width"
        :label="$t('base.basic.filter')"
        @change="filterList"
        v-model="filter"
      />
      <fp-select
        :label="$t('partner.newProject.roles.table.fields.baseRoles')"
        :items="baseRoleSelectOptions"
        valueKey="BaseRoleName"
        @change="filterList"
        v-model="baseRoleFilter"
        class="eh-filter-min-width eh-fix-filter-input-width"
      >
        <template #element="element">
          <span v-if="element.BaseRoleName">{{
            $t(
              "partner.newProject.roles.newRole.baseRoles" +
                "." +
                element.BaseRoleName
            )
          }}</span>
          <span v-else>{{ $t("enums.default") }}</span>
        </template>
      </fp-select>
      <button v-if="!roleReadonly"
        class="eh-action-button"
        style="margin-top: 15px;"
        @click="createNewRole"
      >
        {{ $t("partner.newProject.roles.addRole") }}
      </button>
      <!-- <operation-buttons v-if="!roleReadonly"
        style="margin-top: 15px;"
        :disableEESZT="true"
        :disableShare="true"
        :uploadByCategory="false"
        :disableDelete="true"
        :disableDictateText="true"
        :disableDownload="true"
        :disableUpload="true"
        @selectAll="selectAll()"
        @clearSelected="clearSelected()"
      /> -->
    </div>
    <b-container fluid="true">
      <b-row>
        <b-col>
          <fp-table-list
            class="questionnaires-to-patient-table"
            style="display: table; width: 100%"
            :bordered="true"
            :items="filteredRoleList"
            :fields="roleListFields"
            @row-selected="onOtherRowSelected"
            selectMode="multi"
            :selectable="!roleReadonly"
            :isSelectAll.sync="isSelectAll"
            :isSelectClear.sync="isSelectClear"
          >
            <template #Selected="{ rowSelected }">
              <template v-if="rowSelected">
                <div>
                  <span aria-hidden="true">&check;</span>
                  <span class="sr-only">Selected</span>
                </div>
              </template>
              <template v-else>
                <span aria-hidden="true">&nbsp;</span>
                <span class="sr-only">Not selected</span>
              </template>
            </template>
            <template #BaseRoles="row">
              <div
                class="label-content"
                v-for="baseRole in row.item.BaseRoles"
                :key="row.item.RoleName + baseRole"
              >
                {{
                  $t(
                    "partner.newProject.roles.newRole.baseRoles" +
                      "." +
                      baseRole
                  )
                }}
              </div>
            </template>
            <template #Permissions="row">
              <b-icon
                class="h4"
                v-b-tooltip.hover="$t('base.basic.view')"
                style="cursor: pointer"
                icon="eye-fill"
                @click="openPermissions(row.item)"
              />
            </template>
          </fp-table-list>
        </b-col>
      </b-row>
    </b-container>
    <div class="eh-default-first-row-container">
      <button v-if="!roleReadonly" class="eh-action-button" @click="removeSelected()">
        {{ $t("partner.newProject.roles.deleteRoles") }}
        {{ selectedRoleList.length }}
      </button>
    </div>
    <permissions-form
      v-model="showPermissionsForm"
      :isNew="isNewRole"
      :editable="isCreated"
      :roleReadonly="roleReadonly"
      :role.sync="roleItem"
      @addRole="addRole"
      @roleEdit="editRole"
    />
  </div>
</template>

<script>
import PermissionsForm from "@/components/project-components/PermissionsForm";
import { ProjectLogic } from "@/logic/backend/project";

export default {
  name: "Roles",
  components: {
    PermissionsForm,
  },
  props: {
    roleList: {
      type: Array,
      required: true,
    },
    isCreated: Boolean,
    createdProjectId: Number,
    roleReadonly: Boolean,
  },
  data() {
    return {
      filteredRoleList: this.roleList,
      selectedRoleList: [],
      isSelectClear: false,
      isSelectAll: false,
      roleListFields: [
        {
          key: "Selected",
          label: this.$t("partner.newProject.roles.table.fields.selected"),
          class: "eh-table-select-column-size",
        },
        {
          key: "RoleName",
          label: this.$t("partner.newProject.roles.table.fields.name"),
        },
        {
          key: "BaseRoles",
          label: this.$t("partner.newProject.roles.table.fields.baseRoles"),
        },
        {
          key: "Permissions",
          label: this.$t("partner.newProject.roles.table.fields.permissions"),
        },
      ],
      baseRoleSelectOptions: [
        { BaseRoleName: "Manager" },
        { BaseRoleName: "Doctor" },
        { BaseRoleName: "Patient" },
        { BaseRoleName: null },
      ],
      filter: null,
      baseRoleFilter: null,
      showNewRoleForm: false,
      showPermissionsForm: false,
      roleItem: null,
      isNewRole: false,
    };
  },
  watch: {
    roleList() {
      this.filterList();
    },
    showPermissionsForm(input) {
      if (!input) {
        this.roleItem = null;
      }
    },
  },
  methods: {
    async addRole(role) {
      this.$emit("update:roleList", [...this.roleList, role]);
      if (this.isCreated) {
        await this.setRoleToProject([...this.roleList, role]);
      }
    },
    async editRole(role) {
      const index = this.roleList
        .map(function(e) {
          return e.RoleName;
        })
        .indexOf(role.RoleName);

      const body = {
        ["Roles[" + index + "]"]: role,
      };
      const addRoleResponse = await ProjectLogic.setProject(
        body,
        this.createdProjectId
      );
      if (!addRoleResponse.Code) {
        //TODO: sikeres
        this.$set(this.roleList, index, role);
        this.$emit("update:roleList", this.roleList);
      } else {
        //TODO: hiba
      }
    },
    async setRoleToProject(roleList) {
      const body = {
        Roles: roleList,
      };
      const addRoleResponse = await ProjectLogic.setProject(
        body,
        this.createdProjectId
      );
      if (!addRoleResponse.Code) {
        //TODO: sikeres
      } else {
        //TODO: hiba
      }
    },
    filterList() {
      this.filteredRoleList = this.roleList;
      if (this.filter) {
        this.filteredRoleList = this.$filterList(this.filter, this.roleList, [
          "RoleName",
        ]);
      }
      if (this.baseRoleFilter) {
        this.filteredRoleList = this.filteredRoleList.filter((role) => {
          return role.BaseRoles.includes(this.baseRoleFilter, 0);
        });
      }
    },
    async removeSelected() {
      let newRoleList = [];
      this.roleList.forEach((role) => {
        const foundMatch = this.selectedRoleList.find(
          (selectedRole) => selectedRole.RoleName === role.RoleName
        );
        if (foundMatch === undefined) {
          newRoleList.push(role);
        }
      });
      this.clearSelected();
      this.$emit("update:roleList", newRoleList);
      if (this.isCreated) {
        await this.setRoleToProject(newRoleList);
      }
    },
    openPermissions(role) {
      this.roleItem = role;
      this.showPermissionsForm = true;
      this.isNewRole = false;
    },
    createNewRole() {
      this.showPermissionsForm = true;
      this.isNewRole = true;
    },
    selectAll() {
      this.isSelectAll = true;
    },
    clearSelected() {
      this.isSelectClear = true;
    },
    onOtherRowSelected(items) {
      this.selectedRoleList = items;
    },
  },
};
</script>

<style scoped>
/* @import "../../styles/fp-table-style.css"; */

.date-picker-calendar-quest {
  position: absolute;
  top: 40px;
  right: -100px;
  background: white;
  border: solid 3px #627183;
  border-radius: 10px;
  text-align: center;
  z-index: 10;
}

.label-content {
  display: inline-block;
  margin: 5px 5px;
  padding: 5px 10px;
  border-radius: 5px;
  width: fit-content;
  text-align: center;
  cursor: default;
  background: lightgray;
  border: solid 2px gray;
  /* duplaklikk szöveg kijelölés tiltás */
  user-select: none; /* standard syntax */
  -webkit-user-select: none; /* webkit (safari, chrome) browsers */
  -moz-user-select: none; /* mozilla browsers */
  -khtml-user-select: none; /* webkit (konqueror) browsers */
  -ms-user-select: none; /* IE10+ */
}
</style>
