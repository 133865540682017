export const EnRequestResponse = {
  requestResponse: {
    appointment: {
      errorApply: "An error occurred while applying for the date!",
      errorCancellation: "There was an error while cancelling the appointment!",
      errorClear: "There was an error clearing the date!",
      errorVisitorDelete: "An error occurred while removing!",
      succeccApply: "You have successfully applied for the selected date!",
      successApplyMessage: "Successful application!",
      successCancellation:
        "You have successfully unsubscribed from the selected date!",
      successCancellationMessage: "Successful appointment cancellation.",
      successClear: "You have successfully cleared your date!",
      successVisitorDelete:
        "You have successfully removing the participant from the selected date!",
      successVisitorDeleteMessage: "Successful removal!",
    },
    attachment: {
      errorDownloadDocument:
        "An error occurred while downloading the document!",
      errorDownloadProfilePhoto: "Error downloading profile picture!",
      errorUploadDocument: "An error occurred while uploading the document!",
      errorUploadProfilePhoto: "Error uploading profile picture!",
    },
    basic: {
      deleteElementConfirmMessage:
        "You will delete one of the entries! Do you want to continue deleting?",
      deleteProfileConfirmMessage:
        "Deleting a user account is permanent, cannot be restored, all data will be lost! Are you sure you want to delete the account?",
      errorDelete: "Error deleting!",
      errorDeleteElement: "An error occurred while deleting the item!",
      errorGetDiseaseList: "An error occurred while loading the disease list!",
      errorGetExerciseTypeList: "Error loading motion types!",
      errorGetHospitalList:
        "An error occurred while loading the hospital list!",
      errorGetHospitalWardList:
        "An error occurred while loading the hospital ward list!",
      errorGetList: "Error loading list!",
      errorGetMedicineList: "Error loading medication list!",
      errorGetNamedData: "Error loading data for {name}!",
      errorGetNamedList: "Error loading list {name}!",
      errorGetPharmacyList:
        "An error occurred while loading the pharmacy list!",
      errorModifySave: "Error saving changes!",
      errorRichTextToolbar:
        "The toolbar is only available in the editing interface!",
      errorRichTextToolbarTitle: "Operation failed",
      errorSave: "Error saving!",
      successAssign: "Successful assignment!",
      successDelete: "Delete successful!",
      successDeleteElement: "You have successfully deleted the item!",
      successModifySave: "You have successfully saved your changes!",
      successRemove: "Removal successful!",
      successSave: "Successful saving!",
      successSend: "Successful sending!",
    },
    communication: {
      errorGetTemplate:
        "An error occurred while loading the message templates!",
      errorMessagesList:
        "An error occurred while loading the list of previously sent messages!",
      errorSendMessage: "An error occurred while sending the messages!",
      successSendMessage: "Your messages have been sent successfully!",
    },
    dictate: {
      errorCreateDoc: "An error occurred while creating the document.",
      errorFormat: "Incorrect response format",
      errorInCommunication: "Error communicating. HTTP status code: {response}",
      errorProcess: "Error processing. Status: {response}",
      errorUploadDoc: "HAn error occurred while uploading the document.",
      pleaseFile: "Please enter a filename.",
      successUpload: "Upload successful",
    },
    document: {
      errorDelete: "Error deleting documents!",
      errorDeleteByName:
        "An error occurred while deleting the document named {name}!",
      errorDownload: "Error downloading documents!",
      errorDownloadByName:
        "An error occurred while downloading the document named {name}!",
      errorEESZTsync: "Error during EESZ synchronization!",
      errorUpload: "An error occurred while uploading the documents!",
      errorUploadByName:
        "An error occurred while uploading the document named {name}!",
      successDelete: "You have successfully deleted the documents!",
      successEESZTsync: "Successfully synced {count} documents!",
      successUpload: "You have successfully uploaded the documents!",
    },
    form: {
      errorCreateInstance: "An error occurred while assigning the form!",
      errorSave: "An error occurred while saving the form!",
      errorSend: "An error occurred while submitting the form!",
      successCreateInstance: "Successful form assignment!",
      successSave: "You have successfully saved the form!",
      successSend: "You have successfully submitted the form!",
    },
    invitation: {
      errorGet: "An error occurred while loading the invitations!",
      errorInvitationDelete: "There was an error revoking the invitation!",
      errorSend: "An error occurred while sending the invitation!",
      successInvitationDelete: "You have successfully revoked the invitation!",
      successInvitationDeleteMessage: "Successful withdrawal!",
      successSend: "You have successfully sent out the invitation!",
      successSendTitle: "Send invitation",
    },
    location: {
      errorCannotGetLocation: "Failed to query your location!",
      errorGetLocation: "An error occurred while querying your location!",
      errorLocationByBrowse:
        "Your browser does not support querying your location!",
    },
    medicinePlan: {
      errorDelete: "An error occurred while deleting the medication plan!",
      errorGetList: "An error occurred while loading medication plans!",
      errorSave: "An error occurred while uploading the medication plan!",
      successDelete: "You have successfully canceled your medication plan!",
      successSave: "You have successfully saved your medication plan!",
    },
    moduleDiary: {
      errorDeleteElement: "Error deleting log entry!",
      errorGetList: "An error occurred while loading the log!",
      errorSave: "An error occurred while uploading the entry!",
      successDeleteElement: "Successfully deleted entry!",
      successSave: "You have successfully saved your post!",
    },
    moduleHealthGoal: {
      errorDelete: "An error occurred while deleting the health goal!",
      errorGet: "Error loading health goal {name}!",
      errorSave: "An error occurred while recording the health goal!",
      successDelete: "You have successfully deleted the health goal!",
      successSave: "You have successfully saved your health goal!",
    },
    notification: {
      errorAccept: "An error occurred while accepting the invitation!",
      errorReject: "An error occurred while declining the invitation!",
      successAccept: "You have successfully accepted the invitation!",
      successAcceptMessage: "Successful acceptance!",
      successReject: "You have successfully declined the invitation!",
      successRejectMessage: "Successful rejection!",
    },
    project: {
      errorDeletePatientFrom:
        "An error occurred while deleting the patient from the project!",
      errorDeletePatientFromSubordinates:
        "An error occurred while deleting the patient connection within the project!",
      errorGet: "Error loading project data!",
      errorGetProgress: "Error loading project progress!",
      errorGetProjectSubordinates: "Error loading patient list!",
      errorSavePatientTo: "Error saving new patient!",
      successDeletePatientFrom:
        "You have successfully deleted a patient from the project!",
      successDeletePatientFromSubordinates:
        "You have successfully deleted the patient connection within the project!",
      successSavePatientTo:
        "You have successfully saved the new patient to the project!",
    },
    questionnaire: {
      errorAssignedMessage:
        "An error occurred while assigning the selected questionnaire to the selected patients!",
      errorAssignedTitle:
        "An error occurred while distributing the questionnaire!",
      errorCancel: "An error occurred while canceling the questionnaire!",
      errorGet: "An error occurred while loading the questionnaire!",
      errorReassigned: "An error occurred while reissuing the questionnaire!",
      errorSave: "Error saving questionnaire!",
      errorSaveEvaluate: "Error saving questionnaire evaluation!",
      errorSend: "An error occurred while submitting the questionnaire!",
      errorSendEvaluate:
        "An error occurred while submitting the questionnaire evaluation!",
      successAssignedMessage:
        "You have successfully assigned the selected questionnaire to the selected patients!",
      successAssignedTitle: "Successful questionnaire distribution!",
      successCancel: "Successful questionnaire withdrawal!",
      successReassigned: "Successful questionnaire reissue!",
      successSave: "You have successfully saved the questionnaire!",
      successSaveEvaluate:
        "You have successfully saved the questionnaire evaluation!",
      successSend: "You have successfully submitted the questionnaire!",
      successSendEvaluate:
        "You have successfully submitted the evaluation of the questionnaire!",
    },
    task: {
      errorTaskComplete: "An error occurred while completing the task!",
      errorTaskUncomplete: "An error occurred while canceling the task!",
      successTaskComplete: "You have successfully completed the task!",
      successTaskUncomplete: "You have successfully canceled the task!",
    },
    template: {
      errorAddToProject:
        "An error occurred while assigning the template to the project!",
      errorRemoveFromProject:
        "An error occurred while removing the template from the project!",
      successAddToProject:
        "You have successfully assigned a template to the project!",
      successRemoveFromProject:
        "You have successfully deleted the template from the project!",
    },
    userSetting: {
      errorProjectRemark:
        "An error occurred while retrieving the project comment!",
      errorProjectRemarkModify:
        "An error occurred while modifying the project comment!",
      errorRelationshipTypeRemark:
        "An error occurred while retrieving the connection type and comment!",
      errorRelationshipTypeRemarkModify:
        "An error occurred while changing the connection type and comment!",
    },
    workflow: {
      errorAbortMessage: "An error occurred while aborting the process!",
      errorCreateInsatnce:
        "An error occurred while creating the process instance!",
      errorStart: "An error occurred while starting the process!",
      successAbortMessage: "Successful process abort!",
      successAbortTitle: "Process interrupt",
      successStart: "You have successfully started the process!",
      successStartTitle: "Starting a process",
    },
  },
};
