var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.params.Hidden)?_c('div',[_c('span',{staticStyle:{"margin-left":"5px"}},[(_vm.params.Title)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.params.Title[_vm.language])}}):_vm._e()]),(_vm.params.Required && !_vm.params.Label)?_c('span',{staticStyle:{"color":"red","font-size":"14px"}},[_vm._v(" * "+_vm._s(_vm.$t("Base.required"))+" ")]):_vm._e(),(_vm.params.Description)?_c('div',[_c('div',{staticClass:"input-text-description",domProps:{"innerHTML":_vm._s(_vm.params.Description[_vm.language])}})]):_vm._e(),_c('div',{class:_vm.params.DisplayType == _vm.$enums.DisplayType.Horizontal
        ? 'input-checkbox-box-horizontal'
        : ''},_vm._l((_vm.tempList),function(option,index){return _c('div',{key:'checkbox-' + index,staticClass:"input-checkbox-option",on:{"click":function($event){return _vm.clickItem(option)}}},[_c('b-icon',{staticClass:"input-checkbox-option-icon",class:(_vm.isValid ? ' input-valid ' : ' input-not-valid ') +
          (_vm.tempValue.length == 0
            ? ' input-value-empty '
            : ' input-value-not-empty ') +
          (option.Value ? ' input-checked ' : ' input-unchecked ') +
          (_vm.params.Enabled != false && !_vm.disabled ? '' : ' input-disabled '),attrs:{"icon":option.Value ? 'check-square-fill' : 'square'}}),_c('span',[_vm._v(_vm._s(option.Label ? option.Label[_vm.language] : null))])],1)}),0),(!_vm.isValid && _vm.tempValue.length > 0)?_c('span',{staticClass:"checkbox-error-text"},[_vm._v(" "+_vm._s(_vm.$t("validate.checkMinMax", { min: _vm.params.Minimum, max: _vm.params.Maximum }))+" ")]):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }