export const DeRequestResponse = {
  requestResponse: {
    appointment: {
      errorApply: "Fehler bei der Beantragung des Termins ",
      errorCancellation: "Fehler beim Abmelden des Datums ",
      errorClear: "Fehler bei der Freigabe des Termins ",
      errorVisitorDelete: "Beim Auschecken ist ein Fehler aufgetreten!",
      succeccApply:
        "Sie haben sich erfolgreich um den ausgewählten Termin beworben.",
      successApplyMessage: "Erfolgreiche Anwendung",
      successCancellation:
        "Sie haben sich erfolgreich zum ausgewählten Datum abgemeldet.",
      successCancellationMessage: "Erfolgreiche Abmeldung",
      successClear: "Sie haben Ihren Termin erfolgreich freigegeben.",
      successVisitorDelete:
        "Sie haben den Teilnehmer erfolgreich zum ausgewählten Datum abgemeldet.",
      successVisitorDeleteMessage: "Abmeldung erfolgreich",
    },
    attachment: {
      errorDownloadDocument:
        "Beim Herunterladen des Dokuments ist ein Fehler aufgetreten.",
      errorDownloadProfilePhoto: "Fehler beim Herunterladen des Profilbilds",
      errorUploadDocument:
        "Beim Hochladen des Dokuments ist ein Fehler aufgetreten.",
      errorUploadProfilePhoto: "Fehler beim Hochladen des Profilbildes",
    },
    basic: {
      deleteElementConfirmMessage:
        "Sie löschen einen Eintrag. Möchten Sie mit dem Löschen fortfahren?",
      deleteProfileConfirmMessage:
        "Das Löschen eines Nutzerkontos ist dauerhaft und kann nicht wiederhergestellt werden. Alle Daten gehen verlore. Möchten Sie das Konto wirklich löschen?",
      errorDelete: "Fehler beim Löschen",
      errorDeleteElement: "Fehler beim Löschen des Elements ",
      errorGetDiseaseList: "Fehler beim Laden der Krankheitsliste ",
      errorGetExerciseTypeList: "Fehler beim Laden der Bewegungstypen",
      errorGetHospitalList: "Fehler beim Laden der Krankenhausliste ",
      errorGetHospitalWardList: "Fehler beim Laden der Stationsliste",
      errorGetList: "Fehler beim Laden der Liste der Krankenstationen",
      errorGetMedicineList: "Fehler beim Laden der Medikamentenliste!",
      errorGetNamedData: "Fehler beim Laden der Daten für {name}!",
      errorGetNamedList: "Fehler beim Laden der Liste {name}!",
      errorGetPharmacyList: "Fehler beim Laden der Apothekenliste ",
      errorModifySave: "Fehler beim Speichern der Änderungen",
      errorRichTextToolbar:
        "Die Symbolleiste ist nur in der Bearbeitungsoberfläche verfügbar.",
      errorRichTextToolbarTitle: "Operation fehlgeschlagen",
      errorSave: "Fehler beim Speichern",
      successAssign: "Erfolgreicher Auftrag!",
      successDelete: "Erfolgreich gelöscht",
      successDeleteElement: "Artikel erfolgreich gelöscht",
      successModifySave: "Sie haben Ihre Änderungen erfolgreich gespeichert.",
      successRemove: "Entfernung erfolgreich!",
      successSave: "Erfolgreich gespeichert",
      successSend: "Erfolgreiches Senden!",
    },
    communication: {
      errorGetTemplate:
        "Beim Laden der Nachrichtenvorlagen ist ein Fehler aufgetreten!",
      errorMessagesList:
        "Beim Laden der Liste der zuvor gesendeten Nachrichten ist ein Fehler aufgetreten!",
      errorSendMessage:
        "Beim Senden der Nachrichten ist ein Fehler aufgetreten!",
      successSendMessage: "Ihre Nachrichten wurden erfolgreich gesendet!",
    },
    dictate: {
      errorCreateDoc: "Fehler beim Erstellen des Dokuments",
      errorFormat: "Fehlerhaftes Antwortformat",
      errorInCommunication:
        "Fehler bei der Kommunikation. HTTP-Statuscode: {response}",
      errorProcess: "Fehlerverarbeitung. Status: {response}",
      errorUploadDoc: "Fehler beim Hochladen des Dokuments",
      pleaseFile: "Bitte geben Sie einen Dateinamen ein.",
      successUpload: "Hochladen erfolgreich",
    },
    document: {
      errorDelete: "Fehler beim Löschen von Dokumenten",
      errorDeleteByName:
        "Fehler beim Löschen des Dokuments mit dem Namen {name} ",
      errorDownload: "Fehler beim Herunterladen von Dokumenten",
      errorDownloadByName:
        "Fehler beim Herunterladen des Dokuments namens {name} ",
      errorEESZTsync: "Fehler bei der Synchronisierung",
      errorUpload: "Fehler beim Hochladen der Dokumente ",
      errorUploadByName:
        "Fehler beim Hochladen des Dokuments mit dem Namen {name}",
      successDelete: "Sie haben die Dokumente erfolgreich gelöscht.",
      successEESZTsync: "{count} Dokumente erfolgreich synchronisiert",
      successUpload: "Sie haben die Dokumente erfolgreich hochgeladen.",
    },
    form: {
      errorCreateInstance: "Fehler beim Zuweisen des Formulars ",
      errorSave: "Fehler beim Speichern des Formulars ",
      errorSend: "Fehler beim Absenden des Formulars ",
      successCreateInstance: "Formularzuordnung erfolgreich",
      successSave: "Sie haben das Formular erfolgreich gespeichert.",
      successSend: "Sie haben das Formular erfolgreich abgeschickt.",
    },
    invitation: {
      errorGet: "Fehler beim Laden der Einladungen ",
      errorInvitationDelete: "Fehler beim Widerruf der Einladung ",
      errorSend: "Fehler beim Senden der Einladung ",
      successInvitationDelete: "Einladung erfolgreich widerrufen",
      successInvitationDeleteMessage: "Widerruf erfolgreich",
      successSend: "Einladung erfolgreich versandt",
      successSendTitle: "Einladung senden",
    },
    location: {
      errorCannotGetLocation: "Abfrage Ihres Standorts fehlgeschlagen",
      errorGetLocation: "Fehler bei der Abfrage Ihres Standorts",
      errorLocationByBrowse:
        "Ihr Browser unterstützt keine Abfrage Ihres Standorts.",
    },
    medicinePlan: {
      errorDelete: "Fehler beim Löschen des Medikationsplans ",
      errorGetList: "Fehler beim Laden von Medikamentenplänen ",
      errorSave: "Fehler beim Hochladen des Medikationsplans ",
      successDelete: "Sie haben Ihren Medikationsplan erfolgreich storniert.",
      successSave: "Sie haben Ihren Medikamentenplan erfolgreich gespeichert.",
    },
    moduleDiary: {
      errorDeleteElement: "Fehler beim Löschen des Log-Eintrags",
      errorGetList: "Fehler beim Laden des Protokolls",
      errorSave: "Fehler beim Hochladen des Eintrags ",
      successDeleteElement: "Eintrag erfolgreich gelöscht",
      successSave: "Sie haben Ihren Beitrag erfolgreich gespeichert.",
    },
    moduleHealthGoal: {
      errorDelete: "Fehler beim Löschen des Gesundheitsziels ",
      errorGet: "Fehler beim Laden des Gesundheitsziels {name}",
      errorSave: "Fehler beim Aufzeichnen des Gesundheitsziels ",
      successDelete: "Sie haben das Gesundheitsziel erfolgreich gelöscht.",
      successSave: "Sie haben das Gesundheitsziel erfolgreich gelöscht.",
    },
    notification: {
      errorAccept: "Fehler bei der Annahme der Einladung ",
      errorReject: "Fehler bei der Ablehnung der Einladung ",
      successAccept: "Sie haben die Einladung erfolgreich angenommen.",
      successAcceptMessage: "Annahme erfolgreich",
      successReject: "Sie haben die Einladung erfolgreich abgelehnt.",
      successRejectMessage: "Absage erfolgreich",
    },
    project: {
      errorDeletePatientFrom:
        "Fehler beim Löschen des Patienten aus dem Projekt ",
      errorDeletePatientFromSubordinates:
        "Fehler beim Löschen der Patientenverbindung innerhalb des Projekts",
      errorGet: "Fehler beim Laden der Projektdaten",
      errorGetProgress: "Fehler beim Laden des Projektfortschritts",
      errorGetProjectSubordinates: "Fehler beim Laden der Patientenliste",
      errorSavePatientTo: "Fehler beim Speichern des neuen Patienten",
      successDeletePatientFrom:
        "Sie haben erfolgreich einen Patienten aus dem Projekt gelöscht.",
      successDeletePatientFromSubordinates:
        "Sie haben die Patientenverbindung im Projekt erfolgreich gelöscht.",
      successSavePatientTo:
        "Sie haben erfolgreich einen neuen Patienten im Projekt gespeichert.",
    },
    questionnaire: {
      errorAssignedMessage:
        "Fehler beim Zuordnen des ausgewählten Fragebogens zu den ausgewählten Patienten ",
      errorAssignedTitle: "Fehler beim Verteilen des Fragebogens ",
      errorCancel: "Fehler beim Abbruch des Fragebogens",
      errorGet: "Fehler beim Laden des Fragebogens ",
      errorReassigned: "Fehler beim erneuten Ausgeben des Fragebogens ",
      errorSave: "Fehler beim Speichern des Fragebogens",
      errorSaveEvaluate: "Fehler beim Speichern des Fragebogens",
      errorSend: "Fehler beim Absenden des Fragebogens ",
      errorSendEvaluate: "Fehler beim Absenden der Fragebogenauswertung",
      successAssignedMessage:
        "Sie haben den ausgewählten Fragebogen erfolgreich den ausgewählten Patienten zugewiesen.",
      successAssignedTitle: "Erfolgreiche Fragebogenverteilung",
      successCancel: "ErfolgreicheRücknahme des Fragebogens",
      successReassigned: "Erfolgreiche Neuausgabe des Fragebogens",
      successSave: "Sie haben den Fragebogen erfolgreich gespeichert.",
      successSaveEvaluate:
        "Sie haben die Fragebogenauswertung erfolgreich gespeichert.",
      successSend: "Sie haben den Fragebogen erfolgreich abgeschickt!",
      successSendEvaluate:
        "Sie haben die Auswertung des Fragebogens erfolgreich abgeschickt.",
    },
    task: {
      errorTaskComplete: "Fehler beim Abschluss der Aufgabe ",
      errorTaskUncomplete: "Fehler beim Abbruch der Aufgabe",
      successTaskComplete: "Sie haben die Aufgabe erfolgreich abgeschlossen.",
      successTaskUncomplete: "Sie haben diese Aufgabe erfolgreich abgebrochen.",
    },
    template: {
      errorAddToProject:
        "Beim Zuweisen der Vorlage zum Projekt ist ein Fehler aufgetreten!",
      errorRemoveFromProject:
        "Beim Entfernen der Vorlage aus dem Projekt ist ein Fehler aufgetreten!",
      successAddToProject:
        "Sie haben dem Projekt erfolgreich eine Vorlage zugewiesen!",
      successRemoveFromProject:
        "Sie haben die Vorlage erfolgreich aus dem Projekt gelöscht!",
    },
    userSetting: {
      errorProjectRemark: "Fehler beim Abrufen des Projektkommentars ",
      errorProjectRemarkModify: "Fehler beim Ändern des Projektkommentars ",
      errorRelationshipTypeRemark:
        "Fehler beim Abrufen von Verbindungstyp und Kommentar ",
      errorRelationshipTypeRemarkModify:
        "Fehler bei der Änderung von Verbindungstyp und Kommentar",
    },
    workflow: {
      errorAbortMessage: "Fehler beim Abbruch des Vorgangs ",
      errorCreateInsatnce: "Fehler beim Erstellen des Prozessbeispiels",
      errorStart: "Fehler beim Starten des Prozesses ",
      successAbortMessage: "Erfolgreicher Prozessabbruch",
      successAbortTitle: "Prozessalarm",
      successStart: "Sie haben den Prozess erfolgreich gestartet!",
      successStartTitle: "Starten eines Prozesses",
    },
  },
};
