<template>
  <fp-form-modal
    v-model="visible"
    @hidden="clearData"
    title="Elfelejtett jelszó"
  >
    <template #content>
      <div v-if="!successMailSending">
        Adja meg a felhasználónevét:
        <fp-input label="Felhasználónév" v-model="username" />
        <div>
          A rendszer a profiljában megadott email címére kap egy jelszó
          visszaállító kulcsot, ami az új jelszó megadásához szükséges.
        </div>
        <div style="text-align:center">
          <b-button class="eh-action-button" @click="isLoading ? '' : getNewPasswordToken()">
            <div v-if="!isLoading">Kulcs igénylése</div>
            <b-spinner small v-else />
          </b-button>
          <b-button class="eh-action-button" @click="successMailSending = true">
            Már rendelkezem érvényess kulccsal
          </b-button>
        </div>
      </div>
      <div v-if="successMailSending">
        <b-button class="eh-action-button" @click="clearData">Vissza</b-button>
        <fp-input
          label="Jelszó visszaállító kulcs"
          v-model="token"
          :state="!validation.hasError('token')"
          :error="validation.firstError('token')"
        />
        <fp-input
          label="Új jelszó"
          v-model="password"
          type="password"
          :state="!validation.hasError('password')"
          :error="validation.firstError('password')"
        />
        <fp-input
          label="Jelszó ismét"
          v-model="passwordAgain"
          type="password"
          :state="!validation.hasError('passwordAgain')"
          :error="validation.firstError('passwordAgain')"
        />
        <b-button class="eh-action-button" @click="changePasswordToken">
          <div v-if="!isLoading">Mentés</div>
          <b-spinner small v-else />
        </b-button>
      </div>
    </template>
  </fp-form-modal>
</template>
<script>
import { UserLogic } from "../../Logic/Backend/user";
export default {
  name: "ForgottenPasswordRequestModal",
  model: {
    prop: "showModal",
    event: "change",
  },
  props: {
    showModal: Boolean,
  },
  data() {
    return {
      visible: this.showModal,
      username: null,
      successMailSending: null,
      token: null,
      password: null,
      passwordAgain: null,
      isLoading: false,
    };
  },
  validators: {
    token: function (value) {
      return this.$validator.value(value).required();
    },
    password: function (value) {
      return this.$validator.value(value).required();
    },
    "passwordAgain,password": function (passAgain, newPass) {
      if (
        this.validation.isTouched("password") ||
        this.validation.isTouched("passwordAgain")
      ) {
        return this.$validator
          .value(passAgain)
          .required()
          .match(newPass, this.$t("validate.notMatchPassword"));
      }
    },
  },
  watch: {
    showModal(input) {
      this.visible = input;
    },
    visible(input) {
      this.$emit("change", input);
    },
  },
  methods: {
    clearData() {
      this.username = null;
      this.successMailSending = null;
      this.token = null;
      this.password = null;
      this.passwordAgain = null;
      this.isLoading = false;
    },
    async getNewPasswordToken() {
      this.isLoading = true;
      const getTokenResponse = await UserLogic.requestPasswordChange(
        this.username
      );
      console.log(getTokenResponse);
      if (!getTokenResponse.Code || getTokenResponse.Code == 0) {
        this.successMailSending = true;
        this.$bvToast.toast(
          "Jelszó visszaállító kulcs igénylése megtörtént. Ellenőrizd az email címed. Ha nem találod a mailt, nézd meg a levélszemét mappában is. A klucs érvényessége 30 perc. Ha 30 percnél régebbi a kulcs a rendszer nem fogja elfogadni.",
          {
            title: "Sikeres igénylés",
            variant: "success",
            solid: true,
            noAutoHide: true,
          }
        );
      } else {
        this.$bvToast.toast(getTokenResponse.Message, {
          title: "Sikertelen jelszó visszaállító kulcs igénylés",
          variant: "danger",
          solid: true,
        });
      }
      this.isLoading = false;
    },
    async changePasswordToken() {
      const valid = await this.$validate();
      if (valid) {
        this.isLoading = true;
        const body = {
          TokenValue: this.token,
          NewPassword: this.password,
        };
        const changePasswordResponse = await UserLogic.changePasswordToken(
          body
        );
        if (!changePasswordResponse.Code) {
          this.visible = false;
          this.$bvToast.toast(
            "Sikeresen beállításra került az újonnan megadott jelszó!",
            {
              title: "Sikeres jelszó megadás",
              variant: "success",
              solid: true,
            }
          );
        } else {
          //TODO: hiba
          this.$bvToast.toast(changePasswordResponse.Message, {
            title: "Sikertelen jelszó megadás",
            variant: "danger",
            solid: true,
          });
        }
        this.isLoading = false;
      }
    },
  },
};
</script>
