<template>
  <div class="eh-listbox-list">
    <div class="eh-listbox-list-header" style="cursor: default">
      {{ $t("Patient.patientList") }}
    </div>
    <div
      class="eh-listbox-list-item"
      v-if="
        !participantList || (participantList && participantList.length == 0)
      "
    >
      {{ $t("healthProfessional.patientList.patientList.notFoundPatient") }}
    </div>
    <div
      class="eh-listbox-list-item"
      v-for="participant in participantList"
      :key="participant.UserId"
      href="#"
      @click="selectParticipant(participant)"
      :active="participant.Active"
      :class="participant.Active ? 'eh-listbox-list-item-active' : ''"
    >
      <template>
        <div v-if="$productSettings.specialist.hasParticipantTag && tagList">
          <span
            v-for="(tag, index) in participant.ProjectTagIds"
            :key="participant.Username + '-tag-' + index"
          >
            <span v-if="tagList[tag]">
              <b-icon
                v-if="index < 5"
                class="h3 eh-participant-tag"
                v-b-tooltip.hover="$getLanguagedText(tagList[tag].Name)"
                icon="bookmark-fill"
                :style="'color:' + tagList[tag].ColorCode"
              ></b-icon>
              <b-icon
                v-else-if="index == 6"
                class="h3 eh-participant-tag eh-participant-end-tag"
                icon="three-dots"
              ></b-icon>
            </span>
          </span>
        </div>
        <div>
          <!-- TODO: path alapján is ki tudja keresni -->
          <span v-for="(dataKey, index) in shownData" :key="index">
            {{ participant[dataKey] }}
            <br />
          </span>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import { TagLogic } from "../../Logic/Backend/tag";
export default {
  name: "ParticipantList",
  props: {
    participantList: Array,
    shownData: Array,
  },
  data() {
    return {
      selectedParticipant: null,
      tagList: null,
    };
  },
  watch: {
    async participantList() {
      await this.getTagList();
    },
  },
  methods: {
    //beteg kiválasztás
    selectParticipant(participant) {
      //volt előzőleg kiválasztott beteg?
      if (this.selectedParticipant) {
        //előzőleg kiválasztott beteg Active jelzőjét false-ra állítjuk
        this.$set(this.selectedParticipant, "Active", false);
      }
      //az újonnan kiválasztott beteg tárolása
      this.selectedParticipant = participant;
      //újonnan kiválasztott beteg Active jelzőjét true-ra állítjuk
      this.$set(this.selectedParticipant, "Active", true);
      this.$emit("selectedParticipant", participant);
    },
    async getTagList() {
      const tagIds = this.participantList
        .filter((p) => p.ProjectTagIds && p.ProjectTagIds.length)
        .map((p) => p.ProjectTagIds);
      await this.getTag(this.$checkDuplicates(tagIds.flat()));
    },
    async getTag(tagIds) {
      const getResponse = await TagLogic.getTags({ Ids: tagIds });
      if (!getResponse.Code) {
        const temp = {};
        getResponse.forEach((t) => {
          temp[t.TagId] = t;
        });
        this.tagList = temp;
        console.log(temp);
      } else {
        //TODO: hiba
      }
    },
  },
  async mounted() {
    await this.getTagList();
  },
};
</script>
