import ChartEnums from "./chart";
import SystemParameters from "./system-parameters";
import Prescription from "./prescription";
import Tag from "./tag";
import AllEnums from "./all-enums";

export default {
  ...ChartEnums,
  ...SystemParameters,
  ...Prescription,
  ...Tag,
  ...AllEnums,
};
